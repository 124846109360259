import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import styled from "styled-components";

// SVG Icons for zoom controls
const ZoomInIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5V19M5 12H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ZoomOutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ResetIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2V6M12 18V22M4.929 4.929L7.757 7.757M16.243 16.243L19.071 19.071M2 12H6M18 12H22M4.929 19.071L7.757 16.243M16.243 7.757L19.071 4.929"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Styled component for the modal content and controls
const InvoiceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: ${({ scale }) => `scale(${scale})`}; // Apply scale to container
  transition: transform 0.3s ease; // Smooth transition when scaling

  img {
    max-width: 100%;
    max-height: 500px;
    transition: transform 0.3s ease; // Smooth transition for image resizing
  }

  .controls {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    button {
      padding: 10px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 24px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      svg {
        width: 24px;
        height: 24px;
        color: #007bff;
        transition: color 0.2s ease;

        &:hover {
          color: #0056b3;
        }
      }
    }
  }
`;

const ViewSubmittedRequest = () => {
  const [scale, setScale] = useState(1); // Default scale

  // Handlers for zooming in and out
  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.2, 3)); // Max scale of 3
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.5)); // Min scale of 0.5
  };

  const handleResetZoom = () => {
    setScale(1); // Reset scale to 1
  };

  return (
    <CustomModal
      id="view_submitted_request"
      content={
        <InvoiceContainer scale={scale}>
          <img
            src="/assets/images/MarketPlace/request_sample.svg"
            alt="Invoice"
          />
          <div className="controls">
            <button onClick={handleZoomOut} disabled={scale <= 0.5}>
              <ZoomOutIcon />
            </button>
            <button onClick={handleResetZoom} disabled={scale === 1}>
              <ResetIcon />
            </button>
            <button onClick={handleZoomIn} disabled={scale >= 3}>
              <ZoomInIcon />
            </button>
          </div>
        </InvoiceContainer>
      }
    />
  );
};

export default ViewSubmittedRequest;
