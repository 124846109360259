import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingPrescriptions: false,
  cancellingPrescription: false,
  uploadingPrescription: false,
  prescriptions: null,

  // Pagination
  prescriptionPage: 1,
  prescriptionpageSize: 20,

  // Prescription details
  prescriptionToView: null,
};

export const prescriptionSlice = createSlice({
  name: "prescription",
  initialState,
  reducers: {
    getPrescriptionStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingPrescriptions
    setGettingPrescriptions: (state, action) => {
      state.gettingPrescriptions = action.payload;
    },
    setPrescriptions: (state, action) => {
      state.prescriptions = action.payload;
    },

    // Reducer to update cancellingPrescription
    setCancellingPrescription: (state, action) => {
      state.cancellingPrescription = action.payload;
    },

    // Reducer to update uploadingPrescription
    setUploadingPrescription: (state, action) => {
      state.uploadingPrescription = action.payload;
    },

    // Reducer to update prescriptionToView
    setPrescriptionToView: (state, action) => {
      state.prescriptionToView = action.payload;
    },

    // Reducer to update prescriptionPage
    setPrescriptionPage: (state, action) => {
      state.prescriptionPage = action.payload;
    },

    // Reducer to update prescriptionpageSize
    setPrescriptionpageSize: (state, action) => {
      state.prescriptionpageSize = action.payload;
    },
  },
});

export const {
  getPrescriptionStateUpdate,
  setGettingPrescriptions,
  setCancellingPrescription,
  setUploadingPrescription,
  setPrescriptionToView,
  setPrescriptionPage,
  setPrescriptionpageSize,
  setPrescriptions,
} = prescriptionSlice.actions;

export const selectPrescription = (state) => state.prescription;

export default prescriptionSlice.reducer;
