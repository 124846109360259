import React from "react";
import { SummaryCardsContainer } from "./style";
import ColoredBgCard from "../../../components/Cards/ColoredBgCard";
import { useTheme } from "styled-components";
import {  PulseLoader } from "react-spinners"; // Import the loader

const SummaryCards = ({ data, loading }) => {
  const theme = useTheme();

  return (
    <SummaryCardsContainer>
      <ColoredBgCard
        prop={"Today's Sales"}
        cardBgColor={theme.cardGrey}
        link={"/"}
        value={
          loading ? (
            <PulseLoader color="#00BFFF" size={8} />
          ) : (
            `₦ ${data.todaysSales || 0}`
          )
        }
        iconBgColor={theme.bgGreen}
        icon={<img src="/assets/images/Dashboard/naira.svg" alt="naira" />}
        textColor={theme.grey5}
      />
      <ColoredBgCard
        prop={"Expiring Soon"}
        cardBgColor={theme.cardRed}
        link={"/"}
        value={
          loading ? (
            <PulseLoader color="#FF6347" size={8} />
          ) : (
            `₦ ${data.expiringSoon || 0}`
          )
        }
        iconBgColor={theme.bgRed}
        icon={<img src="/assets/images/Dashboard/expire.svg" alt="expire" />}
        textColor={theme.grey5}
      />
      <ColoredBgCard
        prop={"Today's Invoice"}
        cardBgColor={theme.cardYellow}
        link={"/"}
        value={
          loading ? (
            <PulseLoader color="#FFD700" size={8} />
          ) : (
            `₦ ${data.todaysInvoices || 0}`
          )
        }
        iconBgColor={theme.bgYellow}
        icon={
          <img src="/assets/images/Dashboard/view_invoice.svg" alt="invoice" />
        }
        textColor={theme.grey5}
      />
      <ColoredBgCard
        prop={"View Products"}
        cardBgColor={theme.cardBlue}
        link={"/"}
        value={
          loading ? (
            <PulseLoader color="#1E90FF" size={8} />
          ) : (
            `₦ ${data.todaysProducts || 0}`
          )
        }
        iconBgColor={theme.bgBlue}
        icon={
          <img src="/assets/images/Dashboard/view_invoice.svg" alt="products" />
        }
        textColor={theme.grey5}
      />
    </SummaryCardsContainer>
  );
};

export default SummaryCards;
