import React, { useEffect } from "react";
import SupplierManagementTable from "./SupplierManagementTable";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import AddNewSupplierModal from "./modals/AddNewSupplierModal";
import EditSupplierModal from "./modals/EditSupplierModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useSelector } from "react-redux";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import { deleteSupplier, getSuppliers } from "../../../Apis/Suppliers";
import { selectSupplier } from "../../../Redux/Slices/SupplierSlice";

const SupplierManagement = () => {
  const {
    suppliersPage,
    supplierspageSize,
    deletingSuppliers,
    editingSuppliers,
    supplierId,
  } = useSelector(selectSupplier);

  useEffect(() => {
    getSuppliers(suppliersPage, supplierspageSize);
  }, [suppliersPage, supplierspageSize, deletingSuppliers, editingSuppliers]);

  return (
    <>
      <SupplierManagementTable />
      <AddNewSupplierModal />
      <EditSupplierModal />
      <SuccessMessageModal id="add_new_supplier_success" message={"Done"} />
      <SuccessMessageModal id="edit_supplier_success" message={"Done"} />
      <ConfirmModal
        message="By deleting this Supplier all content will be deleted confirm you want to delete this data."
        confirmOkText={"Delete"}
        id={"delete_supplier"}
        handleOkClick={() => deleteSupplier(supplierId)}
        deleting={deletingSuppliers}
      />
      <CanceledMessageModal
        id={"delete_supplier_success"}
        message={"Deleted Successfully"}
      />
    </>
  );
};

export default SupplierManagement;
