import { toast } from "react-toastify";
import {
  setAddingBranches,
  setBranchToEdit,
  setBranches,
  setDeletingBranches,
  setEditingBranches,
  setGettingBranches,
} from "../Redux/Slices/BranchSlice";
import api from "./baseUri";
import { store } from "../Redux/app/store";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const getBranches = async (page, pageSize) => {
  store.dispatch(setGettingBranches(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/branches?page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      store.dispatch(setBranches(res.data));
      store.dispatch(setGettingBranches(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingBranches(false));
    });
};

export const addBranch = async (branchDetails) => {
  store.dispatch(setAddingBranches(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  const { branchPage, branchPageSize } = store?.getState()?.branch;
  await api
    .post(`v1/pharmacies/${pharmacy?.id}/branches`, branchDetails)
    .then(async (res) => {
      toast.success(res.data.message);
      store.dispatch(setAddingBranches(false));
      store.dispatch(setCurrentModal("add_new_branch_success"));
      await getBranches(branchPage, branchPageSize); // Refresh branches after adding
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingBranches(false));
    });
};

export const deleteBranch = async (branchId) => {
  store.dispatch(setDeletingBranches(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .delete(`v1/pharmacies/${pharmacy?.id}/branches/${branchId}`)
    .then(async (res) => {
      toast.success(res.data.message);
      store.dispatch(setDeletingBranches(false));
      await getBranches(1, 20); // Refresh branches after deletion
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingBranches(false));
    });
};

export const editBranch = async (branchId, branchDetails) => {
  store.dispatch(setEditingBranches(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .put(`v1/pharmacies/${pharmacy?.id}/branches/${branchId}`, branchDetails)
    .then(async (res) => {
      toast.success(res.data.message);
      store.dispatch(setBranchToEdit(null)); // Reset edit state
      store.dispatch(setEditingBranches(false));
      await getBranches(1, 20); // Refresh branches after editing
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setEditingBranches(false));
    });
};
