export const waitingListItems = [
  {
    id: "#1",
    indexNumber: "#1234",
    customerName: "David Ogbonnaya",
    itemsCount: 3,
  },
  {
    id: "#2",
    indexNumber: "#1234",
    customerName: "James Uzoegwu",
    itemsCount: 3,
  },
  {
    id: "#3",
    indexNumber: "#1234",
    customerName: "Olamide Jude",
    itemsCount: 3,
  },
];

export const medicineTabList = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Tablet",
  },
  {
    id: 3,
    name: "Capsule",
  },
  {
    id: 4,
    name: "Suppository",
  },
  {
    id: 5,
    name: "Eye Drop",
  },
  {
    id: 6,
    name: "Injectable",
  },
  {
    id: 7,
    name: "Inhaler",
  },
  {
    id: 8,
    name: "Bottle",
  },
];

export const medicinesList = [
  {
    id: 1,
    price: 1000,
    netWeight: "500mg",
    stock: 100,
    count: 2,
    image: "/assets/images/Sales/blood_caapsule.svg",
    drugName: "Blood Capsule",
    drugDesc:
      "Blood Capsules and Tonic. Chemiron Blood Tonic is a highly effective formula to prevent and treat iron deficiency anaemia",
  },
  {
    id: 2,
    price: 1000,
    netWeight: "300mg",
    stock: 100,
    count: 2,
    image: "/assets/images/Sales/syringe.svg",
    drugName: "Syringe",
    drugDesc:
      "A small hollow tube used for injecting or withdrawing liquids. It may be attached to a needle in order to withdraw fluid from the body or inject drugs into the body.",
  },
  {
    id: 3,
    price: 1000,
    netWeight: "500mg",
    stock: 100,
    count: 2,
    image: "/assets/images/Sales/panadol.svg",
    drugName: "Panadol",
    drugDesc:
      "As one of the world's leading paracetamol-based pain relievers,Panadol's clinically-proven products can treat your pain and get you feeling yourself again.",
  },
  {
    id: 4,
    price: 1000,
    netWeight: "500mg",
    stock: 100,
    count: 2,
    image: "/assets/images/Sales/mushroom_sub.svg",
    drugName: "Mushroom Sup",
    drugDesc:
      "Organic Mushroom Supplement, 10 in 1 Defense Formula – Turkey Tail, Lions Mane, Cordyceps, Chaga",
  },
];
