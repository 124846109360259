import { toast } from "react-toastify";
import {
  clearSupplier,
  setAddingSuppliers,
  setDeletingSuppliers,
  setEditingSuppliers,
  setGettingSuppliers,
  setSupplierToEdit,
  setSuppliers,
} from "../Redux/Slices/SupplierSlice";
import api from "./baseUri";
import { store } from "../Redux/app/store";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const addSupplier = async (supplierDetails) => {
  store.dispatch(setAddingSuppliers(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .post(`v1/pharmacies/${pharmacy.id}/suppliers`, supplierDetails)
    .then((res) => {
      const { supplierspageSize, suppliersPage } = store.getState().supplier;
      toast.success(res.data.message);
      store.dispatch(setAddingSuppliers(false));
      store.dispatch(setCurrentModal("add_new_supplier_success"));
      getSuppliers(suppliersPage, supplierspageSize);
      store.dispatch(clearSupplier());
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingSuppliers(false));
    });
};

export const getSuppliers = async (page, pageSize) => {
  store.dispatch(setGettingSuppliers(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/suppliers?page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      store.dispatch(setSuppliers(res.data));
      store.dispatch(setGettingSuppliers(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingSuppliers(false));
    });
};

export const editSupplier = async (supplierId, updatedDetails) => {
  store.dispatch(setEditingSuppliers(true));
  await api
    .patch(`v1/suppliers/${supplierId}`, updatedDetails)
    .then((res) => {
      const { supplierspageSize, suppliersPage } = store.getState().supplier;
      toast.success(res.data.message);
      store.dispatch(setEditingSuppliers(false));
      store.dispatch(setCurrentModal("edit_supplier_success"));
      getSuppliers(suppliersPage, supplierspageSize);
      store.dispatch(clearSupplier());
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setEditingSuppliers(false));
    });
};

export const deleteSupplier = async (id) => {
  store.dispatch(setDeletingSuppliers(true));
  // const params = { id };
  await api
    .delete(`v1/suppliers/${id}`)
    .then((res) => {
      const { supplierspageSize, suppliersPage } = store.getState().supplier;
      toast.success(res.data.message);
      store.dispatch(setDeletingSuppliers(false));
      store.dispatch(setCurrentModal("delete_supplier_success"));
      getSuppliers(suppliersPage, supplierspageSize);
      store.dispatch(clearSupplier());
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingSuppliers(false));
    });
};

export const setSupplierToEditDetails = async (id) => {
  await api
    .get(`v1/suppliers/${id}`)
    .then((res) => {
      store.dispatch(setSupplierToEdit(res?.data));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
    });
};
