import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingAnalytics: false,
  storeSalesAnalytics: null,
  salesPerBranchAnalytics: null,
  salesPerUserAnalytics: null,
  duration: "",
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    getAnalyticsStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingAnalytics
    setGettingAnalytics: (state, action) => {
      state.gettingAnalytics = action.payload;
    },

    // Reducer to update storeSalesAnalytics
    setStoreSalesAnalytics: (state, action) => {
      state.storeSalesAnalytics = action.payload;
    },

    // Reducer to update salesPerBranchAnalytics
    setSalesPerBranchAnalytics: (state, action) => {
      state.salesPerBranchAnalytics = action.payload;
    },

    // Reducer to update salesPerUserAnalytics
    setSalesPerUserAnalytics: (state, action) => {
      state.salesPerUserAnalytics = action.payload;
    },

    // Reducer to update duration
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
});

export const {
  getAnalyticsStateUpdate,
  setGettingAnalytics,
  setStoreSalesAnalytics,
  setSalesPerBranchAnalytics,
  setSalesPerUserAnalytics,
  setDuration,
} = analyticsSlice.actions;

export const selectAnalytics = (state) => state.analytics;

export default analyticsSlice.reducer;
