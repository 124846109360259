import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingInvoices: false,
  addingInvoice: false,
  deletingInvoice: false,
  invoicepageSize: 20,
  invoicePage: 1,

  // invoice states
  invoiceNumber: "",
  supplier: "",
  purchaseDate: null,
  batchNumber: "",
  expiryDate: "",
  amountPaid: 0,
  balance: 0,
  totalAmount: 0,

  // Selected invoice to view
  selectedInvoiceToView: null,
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    getInventoryStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingInvoices
    setGettingInvoices: (state, action) => {
      state.gettingInvoices = action.payload;
    },

    // Reducer to update addingInvoice
    setAddingInvoice: (state, action) => {
      state.addingInvoice = action.payload;
    },

    // Reducer to update deletingInvoice
    setDeletingInvoice: (state, action) => {
      state.deletingInvoice = action.payload;
    },

    // Reducer to update selectedInvoiceToView
    setSelectedInvoiceToView: (state, action) => {
      state.selectedInvoiceToView = action.payload;
    },

    // Reducer to update invoicepageSize
    setInvoicepageSize: (state, action) => {
      state.invoicepageSize = action.payload;
    },

    // Reducer to update invoicePage
    setInvoicePage: (state, action) => {
      state.invoicePage = action.payload;
    },
  },
});

export const {
  getInventoryStateUpdate,
  setGettingInvoices,
  setAddingInvoice,
  setDeletingInvoice,
  setSelectedInvoiceToView,
  setInvoicePage,
  setInvoicepageSize,
} = inventorySlice.actions;

export const selectInventory = (state) => state.inventory;

export default inventorySlice.reducer;
