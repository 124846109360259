import React, { useState } from "react";
import styled from "styled-components";
import { media } from "../../Screens";

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-height: 38px;
  padding: 6.72px 7.28px 9.89px;
  border-radius: 4px;
  border: 1.65px solid #d9d9d9;
  background-color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.67px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
`;

const SelectedItems = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: 4px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  ${media.phone} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const SelectedItem = styled.span`
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
`;

const RemoveItem = styled.span`
  margin-left: 4px;
  cursor: pointer;
  font-size: 12px;
  color: red;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1.65px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 15px;
  height: 15px;
`;

const MultiSelect = ({ placeholder, options, handleSelection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((val) => val !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    handleSelection(updatedOptions);
  };

  const removeSelectedItem = (option) => {
    setSelectedOptions(selectedOptions.filter((val) => val !== option));
    handleSelection(selectedOptions.filter((val) => val !== option));
  };

  return (
    <DropdownWrapper>
      <DropdownButton>
        <SelectedItems>
          {selectedOptions?.length > 0
            ? selectedOptions?.map((option) => (
                <SelectedItem key={option.value}>
                  {option.label}
                  <RemoveItem onClick={() => removeSelectedItem(option)}>
                    ×
                  </RemoveItem>
                </SelectedItem>
              ))
            : placeholder}
        </SelectedItems>
        <span onClick={toggleDropdown}>{isOpen ? "▲" : "▼"}</span>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options?.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              <span>{option?.label}</span>
              <Checkbox checked={selectedOptions?.includes(option)} readOnly />
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default MultiSelect;
