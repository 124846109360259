import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingCustomers: false,
  editingCustomers: false,
  deletingCustomers: false,
  addingCustomers: false,
  customers: null,
  customerspageSize: 20,
  customersPage: 1,

  // adding customers details
  customerName: "",
  customerEmail: "",
  customerPhone: "",
  customerAddress: "",

  // editing customers details
  customerToEdit: null,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // Reducer to update gettingCustomers
    setGettingCustomers: (state, action) => {
      state.gettingCustomers = action.payload;
    },

    // Reducer to update editingCustomers
    setEditingCustomers: (state, action) => {
      state.editingCustomers = action.payload;
    },

    // Reducer to update deletingCustomers
    setDeletingCustomers: (state, action) => {
      state.deletingCustomers = action.payload;
    },

    // Reducer to update addingCustomers
    setAddingCustomers: (state, action) => {
      state.addingCustomers = action.payload;
    },

    // Reducer to update customers
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },

    // Reducer to update customerspageSize
    setCustomerspageSize: (state, action) => {
      state.customerspageSize = action.payload;
    },

    // Reducer to update customersPage
    setCustomersPage: (state, action) => {
      state.customersPage = action.payload;
    },

    // Reducer to update customerToEdit
    setCustomerToEdit: (state, action) => {
      state.customerToEdit = action.payload;
      state.customerName = action.payload.name;
      state.customerEmail = action.payload.email;
      state.customerPhone = action.payload.phoneNumber;
      state.customerAddress = action.payload.address;
    },

    clearCustomerState: (state, action) => {
      state.customerToEdit = action.null;
      state.customerName = "";
      state.customerEmail = "";
      state.customerPhone = "";
      state.customerAddress = "";
    },

    getCustomerStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  getCustomerStateUpdate,
  setGettingCustomers,
  setEditingCustomers,
  setDeletingCustomers,
  setAddingCustomers,
  setCustomers,
  setCustomerspageSize,
  setCustomersPage,
  setCustomerToEdit,
  clearCustomerState,
} = customerSlice.actions;

export const selectCustomer = (state) => state.customer;

export default customerSlice.reducer;
