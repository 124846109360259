import styled from "styled-components";
import { media } from "../../Screens";
import { Link } from "react-router-dom";
import { Pagination } from "rsuite";

// custom table styles
export const TableContainer2 = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid var(--clr-white-200);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px 2px 4.7px 0px #00000012;
`;
export const TableTitle = styled.div`
  color: ${(prop) => prop.titleColor};
  /* font-weight: 700; */
  text-transform: uppercase;
  font-size: 16.6px;
  font-weight: 300;
  line-height: 32.4px;
  text-align: left;
  margin-bottom: 30px;

  ${media.phone} {
    font-size: 22.6px;
  }
`;
export const Table2 = styled.table`
  width: 100%;
  background: none;
  overflow-x: auto;

  tr,
  thead,
  tbody {
    border: none;
  }
`;
export const TableHeader = styled.thead`
  background: none;

  tr {
    background: none;
    box-shadow: none;
  }
`;
export const TableHead = styled.th`
  // display: flex;
  padding: 12px 30px;
  text-align: left;
  color: var(--clr-black);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: var(--fw-bold);
  background: none;

  ${media.tablet} {
    font-size: 14px;
  }
`;
export const TableRow = styled.tr`
  padding: 12px 30px;
  text-align: left;
  gap: 40px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  width: 100%;

  &:hover {
    // background: transparent !important;
    opacity: 0.8;
  }
`;
export const TableBody = styled.tbody`
  //   border: none;
`;
export const TableData = styled.td`
  color: var(--clr-black);
  font-size: 11px;
  font-weight: var(--fw-very-bold);
  padding: 20px 10px;
  color: ${({ theme }) => theme.grey};
  svg {
    color: var(--clr-grey-200);
    margin-right: 10px;
  }

  ${media.tablet} {
    padding: 20px 30px;
  }
`;

export const PaginationContainer = styled(Pagination)`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  color: var(--MWB-Black, var(--Black, #000));
  font-size: 12px;
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
  width: 100%;
  padding: 0;
  gap: 10px;

  ${media.tablet} {
    flex-direction: row;
  }

  .rs-pagination-btn.rs-pagination-btn-active {
    background: var(--clr-primary-900);
    display: flex;
    width: 23px;
    height: 23px;
    flex-direction: column;
    justify-content: center;
    border: none;
    border-radius: 2px;
    color: var(--MWB-White, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: normal;

    &:hover {
      border: none;
      border-color: var(--clr-primary-900);
    }
  }
`;

export const NavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--clr-black);
  text-transform: capitalize;
  text-decoration: none;
`;
