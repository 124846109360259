import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { TableActions } from "../Online Order/styled";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { getProducts } from "../../../Apis/product";
import {
  selectProduct,
  setProductsPage,
  setProductspageSize,
  setSelectedProductToEdit,
} from "../../../Redux/Slices/ProductSlice";
import ViewProductImage from "./modals/ViewProductImage";

export const columns = [
  { label: "Batch", field: "batch_code" },
  { label: "Name", field: "name" },
  { label: "Category", field: "category" },
  { label: "Man. Date", field: "manufacture_date" },
  { label: "Exp Date", field: "expiry_date" },
  { label: "Cost Price", field: "cost_price" },
  { label: "Selling Price", field: "selling_price" },
  { label: "In Stock", field: "in_stock" },
  // { label: "Store", field: "store" },
  { label: "Status", field: "status" },
  { label: "Image", field: "image" },
  // { label: "Min. Qty", field: "min_qty" },
  { label: "Action", field: "action" },
];

const ProductTable = () => {
  const { productsPage, productsPageSize, products, gettingProducts } =
    useSelector(selectProduct);
  const dispatch = useDispatch();
  const [productImageToView, setProductImageToView] = useState();
  const theme = useTheme();

  const rows = products.items?.map((product) => {
    return {
      batch_code: product?.batch,
      name: product?.name,
      expiry_date: moment(product?.dateOfExpiration).format("Do MMM YYYY"),
      manufacture_date: moment(product?.dateOfManufacture).format(
        "Do MMM YYYY"
      ),
      category: "capsule",
      selling_price: "₦ " + product?.sellingPrice,
      cost_price: "₦ " + product?.costPrice,
      in_stock: product?.quantity,
      // store: "store one",
      status: product?.quantity > 1 && "in store",
      min_qty: "5",
      image: (
        <img
          src={product?.imageUrl}
          alt={product?.name}
          width={30}
          height={30}
          onClick={() => {
            setProductImageToView(product?.imageUrl);
            dispatch(setCurrentModal("view_product_image"));
          }}
        />
      ),
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/edit.svg"
            alt="edit"
            onClick={() => {
              dispatch(setCurrentModal("edit_product"));
              dispatch(setSelectedProductToEdit(product));
            }}
          />
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete"
            onClick={() => dispatch(setCurrentModal("delete_product"))}
          />
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getProducts(productsPage, productsPageSize);
  }, [productsPage, productsPageSize]);

  return (
    <>
      <ViewProductImage imageUrl={productImageToView} />
      <CustomTable
        data={data}
        noRecord={false}
        title={"Product List"}
        showHeader={true}
        showPagination={true}
        loading={gettingProducts}
        filter={
          <FilledButton
            bgColor={theme.purple}
            btnText={"Add Product +"}
            textColor={theme.white}
            handleClick={() => dispatch(setCurrentModal("add_new_product"))}
          />
        }
        // headerButton={
        //   <FilledButton
        //     bgColor={theme.purple}
        //     btnText={"View Categories"}
        //     textColor={theme.white}
        //     handleClick={() =>
        //       navigate("/product-management/categories-management")
        //     }
        //   />
        // }
        page={productsPage}
        limit={productsPageSize}
        handlePageChange={(value) => {
          dispatch(setProductsPage(value));
        }}
        handlePageLimitChange={(value) => {
          dispatch(setProductspageSize(value));
        }}
        totalPages={products?.totalPages}
      />
    </>
  );
};

export default ProductTable;
