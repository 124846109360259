// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivatePages from "./PrivateRoutes";
import AuthPages from "./AuthRoutes";
import { useDispatch, useSelector } from "react-redux";
import { restoreUser, selectAuth } from "../../Redux/Slices/AuthSlice";

const Router = () => {
  const { loggedInUser } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const stableDispatch = useCallback(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    dispatch(restoreUser(currentUser));
  }, [dispatch]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);

  return (
    <BrowserRouter>
      <Routes>
        {loggedInUser && <Route path="/*" element={<PrivatePages />} />}
        {!loggedInUser && <Route path="/*" element={<AuthPages />} />}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
