import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { setLoadingProgress } from "../../Redux/Slices/navSlice";
import { useDispatch } from "react-redux";

// Splash Screen component
const SplashScreen = () => {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    // Simulate loading effect
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return oldProgress + 2; // Moderate increment
      });
    }, 200); // Moderate interval time (400ms)
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(setLoadingProgress(progress));
  }, [progress, dispatch]);

  return (
    <SplashContainer
      bgColor={
        progress < 40 ? "#0DFFE44A" : progress < 75 ? theme.bgBlue : theme.blue
      }
    >
      {progress < 40 ? (
        <img src="/assets/images/Auth/splash_img_1.svg" alt="" />
      ) : progress < 75 ? (
        <img src="/assets/images/Auth/splash_img_2.svg" alt="" />
      ) : (
        <img src="/assets/images/Auth/splash_img_3.svg" alt="" />
      )}
      <LoadingBarWrapper>
        <LoadingBar progress={progress} />
      </LoadingBarWrapper>
      {/* <LoadingText>Loading... {progress}%</LoadingText> */}
    </SplashContainer>
  );
};

// Styled-components for the SplashScreen
const SplashContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) =>
    props.bgColor}; /* Use your preferred background color */
  gap: 30px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
`;

// const LogoText = styled.h1`
//   color: #fff;
//   font-size: 2rem;
//   margin-bottom: 2rem;
// `;

const LoadingBarWrapper = styled.div`
  width: 60%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const LoadingBar = styled.div`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: #00ccff; /* Color for the progress bar */
  transition: width 0.4s ease;
`;

// const LoadingText = styled.p`
//   color: #fff;
//   font-size: 1.2rem;
// `;

export default SplashScreen;
