import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { TableActions } from "../Online Order/styled";
import moment from "moment";
import { useDispatch } from "react-redux";

export const columns = [
  { label: "Invoice Id", field: "invoice_id" },
  { label: "Supplier", field: "supplier" },
  { label: "Purchase Date", field: "purchase_date" },
  { label: "Batch Number", field: "batch_number" },
  { label: "Expiry Date", field: "expiry_date" },
  { label: "Amount Paid", field: "amount_paid" },
  { label: "Balance", field: "balance" },
  { label: "Action", field: "action" },
];

const InvoiceTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const rows = [
    {
      invoice_id: "122345",
      supplier: "Hillary",
      purchase_date: moment().format("Do MMM YYYY"),
      batch_number: "1435465",
      expiry_date: moment().format("Do MMM YYYY"),
      amount_paid: 585,
      balance: 123,
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/view.svg"
            alt="edit_icon"
            onClick={() => dispatch(setCurrentModal("view_invoice"))}
          />
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete_icon"
            onClick={() => dispatch(setCurrentModal("delete_invoice"))}
          />
        </TableActions>
      ),
    },
    {
      invoice_id: "122345",
      supplier: "Hillary",
      purchase_date: moment().format("Do MMM YYYY"),
      batch_number: "1435465",
      expiry_date: moment().format("Do MMM YYYY"),
      amount_paid: 585,
      balance: 123,
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/view.svg"
            alt="edit_icon"
            onClick={() => dispatch(setCurrentModal("view_invoice"))}
          />
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete_icon"
            onClick={() => dispatch(setCurrentModal("delete_invoice"))}
          />
        </TableActions>
      ),
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Invoice"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      headerButton={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Add New Invoice +"}
          textColor={theme.white}
          handleClick={() => dispatch(setCurrentModal("add_invoice"))}
        />
      }
      filter={<p>.</p>}
    />
  );
};

export default InvoiceTable;
