import React from "react";
import styled from "styled-components";
import CustomModal from "./CustomModal";

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  flex: 1;
  gap: 30px;
`;

const Text = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.44px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.blue};
`;
const CanceledMessageModal = ({ message, id }) => {
  return (
    <CustomModal
      id={id}
      content={
        <ContentContainer>
          <Text>{message}</Text>
          <img
            src="/assets/images/Generals/red_check.svg"
            width={100}
            alt="red_check"
          />
        </ContentContainer>
      }
    />
  );
};

export default CanceledMessageModal;
