import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import {
  setRoles,
  setGettingRoles,
  setGettingAuthorities,
  setAuthorities,
  setAddingRoles,
} from "../Redux/Slices/roleSlice";
import api from "./baseUri";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const addRoles = async (credentials) => {
  const { pharmacy } = store?.getState()?.user?.userProfile;

  store.dispatch(setAddingRoles(true));
  await api
    .post(`v1/pharmacies/${pharmacy?.id}/roles`, credentials)
    .then((res) => {
      store.dispatch(setRoles(res.message));
      store.dispatch(setAddingRoles(false));
      store.dispatch(setCurrentModal("create_new_role_success"));
      getRoles();
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingRoles(false));
    });
};

export const getRoles = async () => {
  const { pharmacy } = store?.getState()?.user?.userProfile;

  store.dispatch(setGettingRoles(true));
  await api
    .get(`v1/pharmacies/${pharmacy?.id}/roles`)
    .then((res) => {
      store.dispatch(setRoles(res?.data)); // Adjust according to your response structure
      store.dispatch(setGettingRoles(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingRoles(false));
    });
};

export const getAuthorities = async () => {
  store.dispatch(setGettingAuthorities(true));
  await api
    .get(`v1/authorities`)
    .then((res) => {
      store.dispatch(setAuthorities(res.data)); // Adjust according to your response structure
      store.dispatch(setGettingAuthorities(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingAuthorities(false));
    });
};
