import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 70px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  color: #0000008f;
  border-radius: 10.55px;
  padding: 0 20px;
  height: 21px;
  cursor: pointer;
  width: auto;

  &.active {
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }
`;

const MedicinesTab = ({ tabList }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Container>
      {tabList?.map((tab, index) => (
        <Tab
          key={index + tab?.id}
          className={tab?.id === currentTab && "active"}
          onClick={() => setCurrentTab(tab?.id)}
        >
          {tab?.name}
        </Tab>
      ))}
    </Container>
  );
};

export default MedicinesTab;
