import React, { useCallback, useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { GlobalWorkerOptions } from "pdfjs-dist";

// Set the worker URL to use the PDF.js worker from the CDN
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.3.122/pdf.worker.min.js`;

const FilePreview = ({ selectedFile }) => {
  const [preview, setPreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState();

  const handleFileChange = useCallback(() => {
    const file = selectedFile?.target?.files[0]; // Ensure `selectedFile` exists

    if (file) {
      setFileType(file.type); // Store the file type to decide how to preview it
      setFile(file);
      const reader = new FileReader();

      reader.onload = () => {
        setPreview(reader.result);
      };

      // Read different file types
      if (file.type.startsWith("image/")) {
        reader.readAsDataURL(file); // Image files
      } else if (file.type.startsWith("video/")) {
        reader.readAsDataURL(file); // Video files
      } else if (file.type.startsWith("text/")) {
        reader.readAsText(file); // Text files
      } else if (file.type === "application/pdf") {
        setPreview(file); // Set PDF file to be used later with PDF viewer
      } else {
        alert("Unsupported file type");
      }
    } else {
      setPreview(null); // Clear preview if no file is selected
    }
  }, [selectedFile]); // `selectedFile` is a dependency

  const renderPreview = () => {
    if (!preview) return null;

    if (fileType.startsWith("image/")) {
      return (
        <img
          src={preview}
          alt="Preview"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      );
    } else if (fileType.startsWith("video/")) {
      return (
        <video
          src={preview}
          controls
          style={{ maxWidth: "100%", height: "auto" }}
        />
      );
    } else if (fileType.startsWith("text/")) {
      return (
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {preview}
        </pre>
      );
    } else if (fileType === "application/pdf") {
      return (
        <div style={{ height: "600px", overflow: "auto" }}>
          <Worker
            workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.3.122/pdf.worker.min.js`}
          >
            <Viewer fileUrl={URL.createObjectURL(file)} />
          </Worker>
        </div>
      );
    } else {
      return <p>Unsupported file format</p>;
    }
  };

useEffect(() => {
  if (selectedFile) {
    handleFileChange(); // Call the file change handler when the file is selected
  }
}, [selectedFile, handleFileChange]);
  
  return <div style={{ marginTop: "20px" }}>{renderPreview()}</div>;
};

export default FilePreview;
