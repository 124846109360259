import React from "react";
import styled, { useTheme } from "styled-components";
import CustomModal from "../../../../components/Modals/CustomModal";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import { useDispatch } from "react-redux";

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 15px;
  flex: 1;
`;

const SuccessText = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 23.48px;
  text-align: left;
`;

const ContactSeller = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 23.48px;
  text-align: left;
  color: ${({ theme }) => theme.green2};
`;

const SellerName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 11.74px;
  text-align: left;
`;

const Ratings = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 7.04px;
  text-align: left;
`;
const Telephone = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  text-align: left;
`;

const BuyOrderPlacedModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <CustomModal
      id={"buy_order_placed"}
      content={
        <ContentContainer>
          <SuccessText>Order successfully placed</SuccessText>
          <ContactSeller>Contact Seller</ContactSeller>
          <SellerName>XYZ PHARMACY</SellerName>
          <Ratings>Trades (104)</Ratings>
          <Ratings>Response Time: 10mins</Ratings>
          <Ratings>Completed trades; 94%</Ratings>
          <Telephone>Tel: 070234 456 7687</Telephone>
          <FilledButton
            bgColor={theme.green2}
            btnText={"Confirm Receipt"}
            textColor={theme.white}
            handleClick={() =>
              dispatch(setCurrentModal("confirm_receipt_success"))
            }
          />
        </ContentContainer>
      }
    />
  );
};

export default BuyOrderPlacedModal;
