import styled from "styled-components";
import { media } from "../../../Screens";

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 30px;
  background: ${({ theme }) => theme.white};
  position: relative;
`;

export const NotificationTitle = styled.div`
  font-size: 27.6px;
  font-weight: 300;
  line-height: 32.4px;
  text-align: left;
  color: ${({ theme }) => theme.purple};
`;

export const NotificationsContent = styled.div`
  display: flex;
`;

export const LeftContent = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    height: 90%;
  }

  ${media.tablet} {
    flex: 1;
  }

  &.new_orders,
  &.support {
    flex: 1;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;
  flex: 1;

  ${media.tablet} {
    padding: 0 50px;
  }

  &.new_orders,
  &.support {
    display: none;

    ${media.tablet} {
      display: flex;
    }
  }
`;

export const NotificationList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  width: 100%;
`;

// new orders
export const NewOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 30px;
  background: ${({ theme }) => theme.white};
`;

export const NewOrdersTitle = styled.div`
  font-size: 17.6px;
  font-weight: 300;
  line-height: 32.4px;
  text-align: left;
  color: ${({ theme }) => theme.purple};

  ${media.phone} {
    font-size: 27.6px;
  }
`;

export const NewOrdersContent = styled.div`
  display: flex;
`;

export const NewOrdersRightContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    height: 90%;
  }
`;

export const NewOrdersLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  flex: 1;
  padding: 0 50px;
`;

export const NewOrdersList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  width: 100%;
`;

export const SummaryCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 15px;

  ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
