import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  text-align: left;
  text-transform: capitalize;

  .required {
    color: ${({ theme }) => theme.red};
    font-size: 12px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* height: 38px; */
  padding: 6.72px 7.28px 9.89px;
  border-radius: 4px;
  border: 1.65px solid #d9d9d9;
  outline: none;
  background-color: white;

  textarea {
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    background-color: transparent;
    resize: none;

    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear,
    input[type="password"]::-webkit-input-password-toggle {
      display: none;
    }
  }
`;

const TextArea = ({
  label,
  placeholder,
  value,
  defaultValue,
  handleChange,
  customStyle,
  required,
  name,
}) => {
  return (
    <Container style={customStyle}>
      <Label>
        {label} <span className="required">{required && "*"}</span>
      </Label>
      <InputContainer>
        <textarea
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          required={required}
          name={name}
        />
      </InputContainer>
    </Container>
  );
};

export default TextArea;
