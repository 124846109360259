import React, { useEffect, useState } from "react";
import {
  LeftContainer,
  MedicinesContainer,
  RightContainer,
  SalesContainer,
  SectionContainer,
  SectionHeader,
  SectionTitle,
  WaitingListCards,
} from "./styles";
import { Link } from "react-router-dom";
import WaitingListCard from "../../../components/Cards/WaitingListCard";
import MedicinesTab from "../../../components/Tabs/MedicinesTab";
import MedicineCard from "../../../components/Cards/MedicineCard";
import SalesInvoice from "./SalesInvoice";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import { useDispatch, useSelector } from "react-redux";
import { selectSales, setSalesCart } from "../../../Redux/Slices/SalesSlice";
import { searchProducts } from "../../../Apis/product";
import {
  getProductStateUpdate,
  selectProduct,
} from "../../../Redux/Slices/ProductSlice";
import { selectCategory } from "../../../Redux/Slices/CategorySlice";
import CustomPagination from "../../../components/Table/CustomPagination";

const Sales = () => {
  const { salesCart, waitingLists } = useSelector(selectSales);
  const { searchedProducts, searchProductPage } = useSelector(selectProduct);
  const { categories } = useSelector(selectCategory);
  const [medCategories, setMedCategories] = useState();
  const dispatch = useDispatch();

  const handlePage = (page) => {
    dispatch(getProductStateUpdate({ name: "searchProductPage", value: page }));
  };

  useEffect(() => {
    searchProducts(searchProductPage);
  }, [searchProductPage]);

  useEffect(() => {
    const filterCategories = categories?.items?.map((x) => {
      return {
        id: x?.id,
        name: x?.name,
      };
    });
    setMedCategories([{ id: 0, name: "All" }].concat(filterCategories));
  }, [categories]);

  return (
    <SalesContainer>
      <LeftContainer flex={salesCart?.cartItems?.length === 0 ? 1 : 0.7}>
        <SectionContainer>
          <SectionTitle>Waiting List</SectionTitle>
          <WaitingListCards>
            {waitingLists?.map((item, index) => (
              <WaitingListCard
                customerName={item?.patientName}
                id={index + 1}
                indexNumber={item?.patientId}
                itemsCount={item?.cartItems?.length}
                key={index + 1}
                handleClick={() => dispatch(setSalesCart(item))}
              />
            ))}
          </WaitingListCards>
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>
            <SectionTitle>Medicines</SectionTitle>
            <Link>See all</Link>
          </SectionHeader>
          <MedicinesTab tabList={medCategories} />
          <MedicinesContainer
            grid={
              salesCart?.length === 0 ? "repeat (3, 1fr)" : "repeat (2, 1fr)"
            }
          >
            {searchedProducts?.items?.map((item, index) => (
              <MedicineCard key={index + 1} medicine={item} />
            ))}
          </MedicinesContainer>
        </SectionContainer>

        <CustomPagination
          currentPage={searchedProducts?.currentPage}
          pageSize={searchedProducts?.pageSize}
          totalItems={searchedProducts?.totalItems}
          totalPages={searchedProducts?.totalPages}
          updatePage={handlePage}
        />
      </LeftContainer>
      <RightContainer
        flex={salesCart?.cartItems?.length > 0 ? 0.3 : 0}
        display={salesCart?.cartItems?.length > 0 ? "flex" : "none"}
      >
        <SalesInvoice />
      </RightContainer>
      <SuccessMessageModal
        id="printed_invoice_modal"
        message="Take Your Sales Invoice"
      />
      <CanceledMessageModal
        id="canceled_invoice_modal"
        message="Invoice Canceled"
      />
    </SalesContainer>
  );
};

export default Sales;
