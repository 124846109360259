import React from "react";
import { useDispatch } from "react-redux";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { TableActions } from "../Online Order/styled";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";

export const columns = [
  { label: "Customer ID", field: "customer_id" },
  { label: "Store Points", field: "store_points" },
  { label: "Action", field: "action" },
];

const StoreTable = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const rows = [
    {
      customer_id: "ZM 1090",
      store_points: "Zone c Lagos road",
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/edit.svg"
            alt="edit"
            onClick={() => dispatch(setCurrentModal("edit_store"))}
          />
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete"
            onClick={() => dispatch(setCurrentModal("delete_store_success"))}
          />
        </TableActions>
      ),
      status: "Completed",
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Stores"}
      showHeader={true}
      filter={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Add Store +"}
          textColor={theme.white}
          handleClick={() => dispatch(setCurrentModal("add_new_store"))}
        />
      }
    />
  );
};

export default StoreTable;
