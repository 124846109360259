import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingBusinessSummary: false,
  businessSummary: {
    todaysSales: 0,
    expiringSoon: 0,
    todaysInvoices: 0,
    totalInvoices: 0,
    todaysProducts: 0,
    totalProducts: 0,
    totalSuppliers: 0,
    currentMonthSales: 0,
    lastThreeMonthsSales: 0,
    outOfStocks: 0,
    currentYearRevenue: 0,
    stores: 0,
  },
  gettingTodaysTransactions: false,
  todaysTransaction: null,
  transactionspageSize: 20,
  transactionsPage: 1,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    getHomeStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingBusinessSummary
    setGettingBusinessSummary: (state, action) => {
      state.gettingBusinessSummary = action.payload;
    },

    // Reducer to update businessSummary details
    setBusinessSummary: (state, action) => {
      state.businessSummary = {
        ...state.businessSummary,
        ...action.payload,
      };
    },

    // Reducer to update gettingTodaysTransactions
    setGettingTodaysTransactions: (state, action) => {
      state.gettingTodaysTransactions = action.payload;
    },

    setTodaysTransactions: (state, action) => {
      state.todaysTransaction = action.payload;
    },

    // Reducer to update transactionspageSize
    setTransactionspageSize: (state, action) => {
      state.transactionspageSize = action.payload;
    },

    // Reducer to update transactionsPage
    setTransactionsPage: (state, action) => {
      state.transactionsPage = action.payload;
    },
  },
});

export const {
  getHomeStateUpdate,
  setGettingBusinessSummary,
  setBusinessSummary,
  setGettingTodaysTransactions,
  setTransactionspageSize,
  setTransactionsPage,
  setTodaysTransactions,
} = homeSlice.actions;

export const selectHome = (state) => state.home;

export default homeSlice.reducer;
