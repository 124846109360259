import React, { useState } from "react";
import styled from "styled-components";
import CreditCardPayment from "./CreditCardPayment";
import PayPalPayment from "./PaypalPayment";
import GooglePayPayment from "./GooglePayPayment";
import ApplePayPayment from "./ApplePayPayment";
import BankTransferPayment from "./BankTransfer";
import PaystackPayment from "./PaystackPayment";

// Payment methods array
const paymentMethods = [
  { id: "credit-card", name: "Credit/Debit Card", icon: "💳" },
  { id: "paypal", name: "PayPal", icon: "🅿️" },
  { id: "google-pay", name: "Google Pay", icon: "🅖" },
  { id: "apple-pay", name: "Apple Pay", icon: "🍎" },
  { id: "bank-transfer", name: "Bank Transfer", icon: "🏦" },
  { id: "paystack", name: "Paystack", icon: "💵" }, // Adding Paystack option
];

// Styled components
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.p`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.purple};
`;

const PaymentOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PaymentOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.selected ? "#e0f7fa" : "#fff")};
  border: 1px solid ${(props) => (props.selected ? "#00bcd4" : "#ddd")};
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, border 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }

  span {
    font-size: 1.5em;
    margin-right: 15px;
  }

  p {
    margin: 0;
  }
`;

// const SelectedPayment = styled.div`
//   margin-top: 20px;
//   text-align: center;
//   font-weight: bold;
// `;

const PaymentContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PaymentMethodSelector = () => {
  const [selectedMethod, setSelectedMethod] = useState(null);

  const handlePaymentSelect = (method) => {
    setSelectedMethod(method);
  };

  const renderPaymentComponent = () => {
    switch (selectedMethod?.id) {
      case "credit-card":
        return <CreditCardPayment />;
      case "paypal":
        return <PayPalPayment />;
      case "google-pay":
        return <GooglePayPayment />;
      case "apple-pay":
        return <ApplePayPayment />;
      case "bank-transfer":
        return <BankTransferPayment />;
      case "paystack":
        return <PaystackPayment />;
      default:
        return <p>Please select a payment method.</p>;
    }
  };

  return (
    <Container>
      <Header>Select a Payment Method</Header>
      <PaymentOptions>
        {paymentMethods.map((method) => (
          <PaymentOption
            key={method.id}
            selected={selectedMethod && selectedMethod.id === method.id}
            onClick={() => handlePaymentSelect(method)}
          >
            <span>{method.icon}</span>
            <p>{method.name}</p>
          </PaymentOption>
        ))}
      </PaymentOptions>
      {selectedMethod && (
        <>
          <PaymentContainer>{renderPaymentComponent()}</PaymentContainer>
        </>
      )}
    </Container>
  );
};

export default PaymentMethodSelector;
