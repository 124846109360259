import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { HomeContainer } from "./style";
import SummaryCards from "./SummaryCards";
import RecordsSummaryCards from "./RecordsSummaryCards";
import TodaysTransactionTable from "./TodaysTransactionTable";
import { selectHome } from "../../../Redux/Slices/HomeSlice";
import { getBusinessSummary, getTodaysTransactions } from "../../../Apis/Home";

const Home = () => {
  const {
    gettingBusinessSummary,
    businessSummary,
    gettingTodaysTransactions,
    todaysTransaction,
  } = useSelector(selectHome);

  useEffect(() => {
    getBusinessSummary();
    getTodaysTransactions(1, 20);
  }, []);

  return (
    <HomeContainer>
      <SummaryCards data={businessSummary} loading={gettingBusinessSummary} />
      <RecordsSummaryCards
        data={businessSummary}
        loading={gettingBusinessSummary}
      />
      <TodaysTransactionTable
        data={todaysTransaction}
        loading={gettingTodaysTransactions}
      />
    </HomeContainer>
  );
};

export default Home;
