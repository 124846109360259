import React from "react";
import styled from "styled-components";

const MinimizeButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  align-self: flex-end;

  &:hover {
    background-color: #45a049;
    transform: scale(1.1);
  }

  &:active {
    background-color: #388e3c;
  }

  &:focus {
    outline: none;
  }
`;

const MinimizeIcon = styled.div`
  width: 20px;
  height: 2px;
  background-color: white;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: white;
  }
`;

const MinimizeButtonComponent = ({ handleClick }) => {
  return (
    <MinimizeButton onClick={handleClick}>
      <MinimizeIcon />
    </MinimizeButton>
  );
};

export default MinimizeButtonComponent;
