import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const healthSlice = createSlice({
  name: "health",
  initialState,
  reducers: {
    getHealthStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {getHealthStateUpdate} = healthSlice.actions;

export const selectHealth = (state) => state.health;

export default healthSlice.reducer;
