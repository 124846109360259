import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingRoles: false,
  roles: null,
  gettingAuthorities: false,
  authorities: [],
  addingRoles: false,

  // create states
  name: "",
  permissions: [],
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setGettingRoles: (state, action) => {
      state.gettingRoles = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setGettingAuthorities: (state, action) => {
      state.gettingAuthorities = action.payload;
    },
    setAuthorities: (state, action) => {
      state.authorities = action.payload;
    },
    setAddingRoles: (state, action) => {
      state.addingRoles = action.payload;
    },
    getRoleStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingRoles,
  setRoles,
  setAuthorities,
  setGettingAuthorities,
  setAddingRoles,
  getRoleStateUpdate,
} = roleSlice.actions;

export const selectRole = (state) => state.role;

export default roleSlice.reducer;
