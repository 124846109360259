import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  getBranchStateUpdate,
  selectBranch,
} from "../../../../Redux/Slices/BranchSlice";
import SelectOptions from "../../../../components/Inputs/SelectOptions";
import {
  mapToLabelValueArray,
  validateEmail,
  validatePass,
  validatePhoneNumber,
} from "../../../../helpers";
import { addBranch } from "../../../../Apis/BranchManagement";
import { selectRole } from "../../../../Redux/Slices/roleSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const AddNewBranchModal = () => {
  const {
    branchName,
    branchManagerEmail,
    branchManagerPassword,
    branchManagerFirstName,
    branchManagerLastName,
    branchPhone,
    branchLocation,
    branchManagerRole,
    addingBranches,
  } = useSelector(selectBranch);
  const { roles } = useSelector(selectRole);
  const [roleItems, setRoleItems] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCreate = () => {
    if (
      validatePhoneNumber(branchPhone) &&
      validateEmail(branchManagerEmail) &&
      validatePass(branchManagerPassword)
    ) {
      addBranch({
        name: branchName,
        roleId: branchManagerRole,
        managerEmail: branchManagerEmail,
        password: branchManagerPassword,
        firstName: branchManagerFirstName,
        lastName: branchManagerLastName,
        phoneNumber: branchPhone,
        location: branchLocation,
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getBranchStateUpdate({ name, value }));
  };

  useEffect(() => {
    setRoleItems(mapToLabelValueArray(roles?.items, "name", "id"));
  }, [roles]);

  return (
    <CustomModal
      id="add_new_branch"
      content={
        <Container>
          <ModalTitle>Edit Branch</ModalTitle>
          <FlexContainer>
            <TextInput
              placeholder={"Branch name here"}
              label={"Name"}
              name="branchName"
              value={branchName}
              type={"text"}
              required
              handleChange={handleChange}
            />
            <TextInput
              placeholder={"Branch Phone number here"}
              label={"Phone Number"}
              name={"branchPhone"}
              value={branchPhone}
              type={"tel"}
              required
              handleChange={handleChange}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              placeholder={"Location name here"}
              label={"Location"}
              name={"branchLocation"}
              value={branchLocation}
              required
              handleChange={handleChange}
            />
            <TextInput
              placeholder={"Enter the managers email"}
              label={"Manger Email"}
              name={"branchManagerEmail"}
              value={branchManagerEmail}
              type={"email"}
              required
              handleChange={handleChange}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              placeholder={"Manager first name here"}
              label={"Manager First Name"}
              name={"branchManagerFirstName"}
              value={branchManagerFirstName}
              type={"text"}
              required
              handleChange={handleChange}
            />
            <TextInput
              placeholder={"Manager last name here"}
              label={"Manager Last Name"}
              name={"branchManagerLastName"}
              value={branchManagerLastName}
              type={"text"}
              required
              handleChange={handleChange}
            />
          </FlexContainer>
          <FlexContainer>
            <TextInput
              placeholder={"Manager password"}
              label={"Manager Password"}
              name={"branchManagerPassword"}
              value={branchManagerPassword}
              type={"password"}
              required
              handleChange={handleChange}
            />
            <SelectOptions
              placeholder={"Manager role"}
              label={"Manager Role"}
              name={"branchManagerRole"}
              value={branchManagerRole}
              required
              handleSelect={handleChange}
              options={roleItems}
            />
          </FlexContainer>

          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              loading={addingBranches}
              disabled={
                !branchName &&
                !branchManagerEmail &&
                !branchManagerPassword &&
                !branchManagerFirstName &&
                !branchManagerLastName &&
                !branchPhone &&
                !branchLocation &&
                !branchManagerRole
              }
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default AddNewBranchModal;
