import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  text-align: left;
  text-transform: capitalize;
`;

const Input = styled.label`
  width: 100%;
  height: 38px;
  /* padding: 6.72px 7.28px 9.89px; */
  border-radius: 4px;
  border: 1.65px solid #d9d9d9;
  outline: none;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;

  .select {
    width: 40%;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    border: 1.65px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #d9d9d9;
  }

  .display_name {
    width: 80%;
    height: 100%;
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: #666;
    font-size: 12px;
  }
`;

const FIleInput = ({
  label,
  placeholder,
  value,
  defaultValue,
  handleChange,
  type,
  customStyle,
  name
}) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file type is an image
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        setFileName(file.name); // Set the file name if it's an image
      } else {
        alert("Please select a valid image file (jpg, png, gif).");
        setFileName(""); // Reset if the file is not an image
      }
    }
  };

  return (
    <Container style={customStyle}>
      <Label>{label}</Label>
      <Input htmlFor="file">
        <span className="select">Choose File</span>
        <span className="display_name">{fileName || "No file chosen"}</span>
      </Input>
      <input
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          handleFileChange(e);
          handleChange && handleChange(e); // Propagate if handleChange is passed
        }}
        type="file"
        accept="image/*" // Only allow image files
        style={{ display: "none" }}
        id="file"
        name={name}
      />
    </Container>
  );
};

export default FIleInput;
