import React from "react";
import CustomTable from "../../../components/Table/CustomTable";

export const columns = [
  { label: "Order ID", field: "order_id" },
  { label: "Customer", field: "customer" },
  { label: "Payment", field: "payment" },
  { label: "Amount (₦)", field: "amount" },
  { label: "Attendant", field: "attendant" },
  { label: "Status", field: "status" },
];

const TodaysTransactionTable = ({ dataFilter, loading }) => {
  const rows = dataFilter?.map((transaction) => {
    return {
      order_id: transaction.order_id,
      customer: transaction.customer.name,
      payment: transaction.payment_status,
      amount: `₦ ${transaction.amount}`,
      attendant: transaction.attendant.name,
      status: transaction.status,
    };
  });

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Todays Transaction"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      loading={loading}
    />
  );
};

export default TodaysTransactionTable;
