import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingBranches: false,
  editingBranches: false,
  deletingBranches: false,
  addingBranches: false,
  branches: null,
  branchesPageSize: 20,
  branchesPage: 1,

  // adding branch details
  branchName: "",
  branchManagerEmail: "",
  branchManagerPassword: "",
  branchManagerFirstName: "",
  branchManagerLastName: "",
  branchPhone: "",
  branchLocation: "",
  branchManagerRole: "",
  // editing branch details
  branchToEdit: null,
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    getBranchStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingBranches
    setGettingBranches: (state, action) => {
      state.gettingBranches = action.payload;
    },

    // Reducer to update editingBranches
    setEditingBranches: (state, action) => {
      state.editingBranches = action.payload;
    },

    // Reducer to update deletingBranches
    setDeletingBranches: (state, action) => {
      state.deletingBranches = action.payload;
    },

    // Reducer to update addingBranches
    setAddingBranches: (state, action) => {
      state.addingBranches = action.payload;
    },

    // Reducer to update branches
    setBranches: (state, action) => {
      state.branches = action.payload;
    },

    // Reducer to update branchespageSize
    setBranchespageSize: (state, action) => {
      state.branchesPageSize = action.payload;
    },

    // Reducer to update branchesPage
    setBranchesPage: (state, action) => {
      state.branchesPage = action.payload;
    },

    // Reducer to update branchToEdit
    setBranchToEdit: (state, action) => {
      state.branchToEdit = action.payload;
    },
  },
});

export const {
  getBranchStateUpdate,
  setGettingBranches,
  setEditingBranches,
  setDeletingBranches,
  setAddingBranches,
  setBranches,
  setBranchespageSize,
  setBranchesPage,
  setBranchToEdit,
} = branchSlice.actions;

export const selectBranch = (state) => state.branch;

export default branchSlice.reducer;
