import React from "react";
import StoreTable from "./StoresTable";
import AddStoreModal from "./modal/AddStore";
import EditStoreModal from "./modal/EditStores";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";

const Stores = () => {
  return (
    <>
      <StoreTable />
      <AddStoreModal />
      <EditStoreModal />
      <SuccessMessageModal id={"add_new_store_success"} message={"Done"} />
      <SuccessMessageModal id={"edit_store_success"} message={"Done"} />
      <CanceledMessageModal
        id={"delete_store_success"}
        message="Deleted Successfully"
      />
    </>
  );
};

export default Stores;
