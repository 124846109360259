import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  text-align: left;
  text-transform: capitalize;

  .required {
    color: ${({ theme }) => theme.red};
    font-size: 12px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 38px;
  padding: 6.72px 7.28px 9.89px;
  border-radius: 4px;
  border: 1.65px solid #d9d9d9;
  outline: none;
  background-color: white;

  input {
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    background-color: transparent;

    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear,
    input[type="password"]::-webkit-input-password-toggle {
      display: none;
    }
  }
`;

const EyeIcon = styled.span`
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
`;

const TextInput = ({
  label,
  placeholder,
  value,
  defaultValue,
  handleChange,
  type,
  customStyle,
  required,
  name,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Container style={customStyle}>
      <Label>
        {label} <span className="required">{required && "*"}</span>
      </Label>
      <InputContainer>
        <input
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          type={
            type === "password" && passwordVisible
              ? "text"
              : type === "password" && !passwordVisible
              ? "password"
              : type
          }
          required={required}
          name={name}
        />
        {type === "password" && (
          <EyeIcon onClick={togglePasswordVisibility}>
            {passwordVisible ? "🙈" : "👁️"}
          </EyeIcon>
        )}
      </InputContainer>
    </Container>
  );
};

export default TextInput;
