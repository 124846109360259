import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingOnlineOrdersSummary: false,
  onlineOrdersSummary: null,
  gettingOrders: false,
  newOrders: null,
  orderInProgress: null,
  shippedOrders: null,
  completedOrders: null,
  canceledOrders: null,
  approvingOrders: false,
  cancellingOrders: false,
  orderspageSize: 20,
  ordersPage: 1,
};

export const onlineOrderSlice = createSlice({
  name: "onlineOrder",
  initialState,
  reducers: {
    getOnlineOrderStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingOnlineOrdersSummary
    setGettingOnlineOrdersSummary: (state, action) => {
      state.gettingOnlineOrdersSummary = action.payload;
    },

    // Reducer to update onlineOrdersSummary
    setOnlineOrdersSummary: (state, action) => {
      state.onlineOrdersSummary = action.payload;
    },

    // Reducer to update gettingOrders
    setGettingOrders: (state, action) => {
      state.gettingOrders = action.payload;
    },

    // Reducer to update newOrders
    setNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },

    // Reducer to update orderInProgress
    setOrderInProgress: (state, action) => {
      state.orderInProgress = action.payload;
    },

    // Reducer to update shippedOrders
    setShippedOrders: (state, action) => {
      state.shippedOrders = action.payload;
    },

    // Reducer to update completedOrders
    setCompletedOrders: (state, action) => {
      state.completedOrders = action.payload;
    },

    // Reducer to update canceledOrders
    setCanceledOrders: (state, action) => {
      state.canceledOrders = action.payload;
    },

    // Reducer to update approvingOrders
    setApprovingOrders: (state, action) => {
      state.approvingOrders = action.payload;
    },

    // Reducer to update cancellingOrders
    setCancellingOrders: (state, action) => {
      state.cancellingOrders = action.payload;
    },

    // Reducer to update orderspageSize
    setOrderspageSize: (state, action) => {
      state.orderspageSize = action.payload;
    },

    // Reducer to update ordersPage
    setOrdersPage: (state, action) => {
      state.ordersPage = action.payload;
    },
  },
});

export const {
  getOnlineOrderStateUpdate,
  setGettingOnlineOrdersSummary,
  setOnlineOrdersSummary,
  setGettingOrders,
  setNewOrders,
  setOrderInProgress,
  setShippedOrders,
  setCompletedOrders,
  setCanceledOrders,
  setApprovingOrders,
  setCancellingOrders,
  setOrdersPage,
  setOrderspageSize,
} = onlineOrderSlice.actions;

export const selectOnlineOrder = (state) => state.onlineOrder;

export default onlineOrderSlice.reducer;
