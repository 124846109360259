import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingCurrencies: false,
  currencies: null,
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setGettingCurrencies: (state, action) => {
      state.gettingCurrencies = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
  },
});

export const { setGettingCurrencies, setCurrencies } = currencySlice.actions;

export const selectCurrency = (state) => state.currency;

export default currencySlice.reducer;
