import React from "react";
import {
  LeftContent,
  NewOrdersContainer,
  NewOrdersList,
  NewOrdersTitle,
  NewOrdersContent,
  RightContent,
} from "./styles";
import NewOrdersCard from "../../../components/Cards/NewOrdersCard";

const NewOrders = () => {
  return (
    <NewOrdersContainer>
      <NewOrdersTitle>New Orders</NewOrdersTitle>
      <NewOrdersContent>
        <LeftContent className="new_orders">
          <NewOrdersList>
            <NewOrdersCard
              leftContent={
                <img
                  src="/assets/images/Notification/image 3.svg"
                  alt="sample"
                />
              }
              name={"Jerome Bell"}
              message={"You have new order from james...."}
            />
            <NewOrdersCard
              leftContent={
                <img
                  src="/assets/images/Notification/image 3.svg"
                  alt="sample"
                />
              }
              name={"Jerome Bell"}
              message={"You have new order from james...."}
            />
            <NewOrdersCard
              leftContent={
                <img
                  src="/assets/images/Notification/image 3.svg"
                  alt="sample"
                />
              }
              name={"Jerome Bell"}
              message={"You have new order from james...."}
            />
            <NewOrdersCard
              leftContent={
                <img
                  src="/assets/images/Notification/image 3.svg"
                  alt="sample"
                />
              }
              name={"Jerome Bell"}
              message={"You have new order from james...."}
            />
          </NewOrdersList>
        </LeftContent>
        <RightContent className="new_orders">
          <img
            src="/assets/images/Notification/image2.svg"
            alt="NewOrders_banner"
          />
        </RightContent>
      </NewOrdersContent>
    </NewOrdersContainer>
  );
};

export default NewOrders;
