import React from "react";
import {
  DetailsContainer,
  LeftBottomDetails,
  LeftDetails,
  LeftTopDetails,
  ProfileFormContainer,
  ProfileTitle,
  RightDetails,
  UserProfileContainer,
} from "./styles";
import moment from "moment";
import TextInput from "../../../components/Inputs/TextInput";
import { selectUser } from "../../../Redux/Slices/userSlice";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const { userProfile } = useSelector(selectUser);

  console.log(userProfile);

  return (
    <UserProfileContainer>
      <ProfileTitle>My Profile</ProfileTitle>
      <DetailsContainer>
        <LeftDetails>
          <LeftTopDetails>
            <div className="top">
              <img
                src="/assets/images/avatar.png"
                alt="avatar"
                className="avatar"
              />
              <p className="name">
                {userProfile?.lastName} {userProfile?.firstName}
              </p>
              <p>Store Name: {userProfile?.pharmacy?.name}</p>
              <p>Administrator</p>
            </div>
            <div className="bottom">
              <p>Phone Number: {userProfile?.user?.phoneNumber}</p>
              <p>Email: {userProfile?.user?.email}</p>
              <p>Status: Administrator</p>
            </div>
          </LeftTopDetails>
          <LeftBottomDetails>
            <div className="auth_details">Authentication Details:</div>
            <p>
              User Name:{" "}
              <span style={{ marginLeft: "15px" }}>
                {userProfile?.lastName} {userProfile?.firstName}
              </span>
            </p>
            <p>
              last Login:{" "}
              <span style={{ marginLeft: "15px" }}>
                {moment().format("LLLL")}
              </span>
            </p>
            <p>
              Registered:{" "}
              <span style={{ marginLeft: "15px" }}>
                {moment(userProfile?.createdAt).format("Do MMM YYYY")}
              </span>
            </p>
          </LeftBottomDetails>
        </LeftDetails>
        <RightDetails>
          <ProfileFormContainer>
            <p className="title">Company Profile Settings</p>
            <TextInput
              label={"Company Name"}
              placeholder={"XYZ Pharmacy"}
              value={userProfile?.pharmacy?.name}
            />
            <TextInput
              label={"Email"}
              placeholder={"example@gmail.com"}
              value={userProfile?.email}
            />
            <TextInput
              label={"Phone Number"}
              placeholder={"070xxxxxxxx"}
              value={userProfile?.phoneNumber}
            />
            <TextInput
              label={"Address"}
              placeholder={"No 20, Xyz Pharmacy, Lagos"}
              value={userProfile?.address}
            />
          </ProfileFormContainer>
        </RightDetails>
      </DetailsContainer>
    </UserProfileContainer>
  );
};

export default UserProfile;
