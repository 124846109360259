import styled from "styled-components";

export const AnalyticsTitle = styled.div`
  font-size: 27.6px;
  font-weight: 300;
  line-height: 32.4px;
  text-align: left;
  color: ${({ theme }) => theme.black};
  width: 100%;
  background: ${({ theme }) => theme.white};
  padding: 10px;
  margin: 10px;
`;
