import styled from "styled-components";

export const AuthBannerTitle = styled.div`
  font-size: 36px;
  font-weight: 300;
  line-height: 42.26px;
  text-align: left;
  color: ${({ theme }, props) => theme.yellow};
`;

export const FormFooterTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .remember {
    display: flex;
    gap: 10px;
  }

  .forgot_password {
    font-size: 11.54px;
    font-weight: 500;
    line-height: 15.67px;
    color: #ff0000;
    text-decoration: none;
  }
`;
