import React, { useEffect, useState } from "react";
import AuthRouteContainer from "../../AuthRouteContainer";
import { AuthBannerTitle, FormFooterTextContainer } from "../style";
import FormCard from "../../../components/Cards/FormCard";
import TextInput from "../../../components/Inputs/TextInput";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../Redux/Slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validateEmail, validatePass } from "../../../helpers";
import { resetPassword, sendOTP } from "../../../Apis/Auth";

const ForgotPassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    resetPasswordEmail,
    newPassword,
    confirmNewPassword,
    otp,
    resettingPassword,
  } = useSelector(selectAuth);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState();

  const handleSubmit = () => {
    if (!resetPasswordEmail) {
      toast.error("Please enter your email");
    } else if (!newPassword) {
      toast.error("Please enter new password");
    } else if (newPassword !== confirmNewPassword) {
      toast.error("password doesn't match the confirm password");
    } else {
      if (validateEmail(resetPasswordEmail) && validatePass(newPassword)) {
        resetPassword(otp, newPassword);
      }
    }
  };

  const handleSendOtp = () => {
    if (validateEmail(resetPasswordEmail)) {
      sendOTP(resetPasswordEmail);
      setIsResendDisabled(true); // Disable the button
      setCountdown(30); // Start countdown from 30 seconds
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  useEffect(() => {
    let timer;
    if (isResendDisabled && countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => (prev ? prev - 1 : 0));
      }, 1000);
    }

    if (countdown === 0) {
      setIsResendDisabled(false);
      setCountdown(null);
    }

    return () => clearInterval(timer);
  }, [isResendDisabled, countdown]);

  return (
    <AuthRouteContainer
      leftContent={
        <>
          <img src="/assets/images/Auth/zamda_logo.svg" alt="logo" />
          <AuthBannerTitle style={{ color: theme.white }}>
            POS (Point of Sales)
          </AuthBannerTitle>
          <img
            src="/assets/images/Auth/auth_banner_2.svg"
            alt="logo"
            width="80%"
          />
        </>
      }
      rightContent={
        <FormCard title={"Reset Password"}>
          <TextInput
            label={"Email"}
            placeholder={"example@email.com"}
            handleChange={handleChange}
            name="resetPasswordEmail"
            type={"email"}
            value={resetPasswordEmail}
            required={true}
          />
          <FormFooterTextContainer>
            <div className="remember">.</div>
            <Link
              style={{
                color: theme.purple,
                cursor: isResendDisabled ? "not-allowed" : "pointer",
                opacity: isResendDisabled ? 0.6 : 1,
              }}
              onClick={handleSendOtp}
            >
              {isResendDisabled ? `Resend OTP (${countdown}s)` : "Send OTP"}
            </Link>
          </FormFooterTextContainer>
          <TextInput
            label={"New Password"}
            type={"password"}
            handleChange={handleChange}
            name={"newPassword"}
            value={newPassword}
            required={true}
          />
          <TextInput
            label={"Confirm New Password"}
            type={"password"}
            handleChange={handleChange}
            name={"confirmNewPassword"}
            value={confirmNewPassword}
            required={true}
          />
          <TextInput
            label={"OTP"}
            placeholder={"Enter 5 digit otp"}
            handleChange={handleChange}
            name={"otp"}
            type={"text"}
            value={otp}
            required={true}
          />
          <FilledButton
            bgColor={theme.purple}
            btnText={"Submit"}
            handleClick={handleSubmit}
            textColor={theme.white}
            customStyle={{ width: "100%" }}
            type={"submit"}
            loading={resettingPassword}
          />
        </FormCard>
      }
      bgColor={theme.blue}
    />
  );
};

export default ForgotPassword;
