import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingNotifications: false,
  newOrdersNotifications: null,
  supportNotifications: null,
  expiringNotifications: null,
  expiredNotifications: null,
  outOfStockNotifications: null,
  notificationsPage: 1,
  notificationspageSize: 20,

  // Notification settings
  updatingNotificationsSettings: false,
  notificationSettings: {
    showNewOrders: true,
    showExpired: true,
    showExpiring: true,
    showSupport: true,
    showOutOfStock: true,
  },

  // Expiration product details (untouched)
  productName: "",
  productStockQty: 0,
  productExpiryDate: null,
  productSupplier: "",
  productStatus: "",
  costPrice: 0,
  sellingPrice: 0,
  qtyPerCarton: 0,
  productBatch: "",
  invoiceNumber: "",
  mftDate: null,
  productCategory: "",

  // Table list
  productToView: null,
  deletingProduct: false,
  editingProduct: false,
  expiredProducts: [],
  productsExpiringInAMonth: [],
  productsExpiringInSixMonths: [],
  productsExpiringInThreeMonths: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotificationStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingNotifications
    setGettingNotifications: (state, action) => {
      state.gettingNotifications = action.payload;
    },

    // Reducer to update newOrdersNotifications
    setNewOrdersNotifications: (state, action) => {
      state.newOrdersNotifications = action.payload;
    },

    // Reducer to update supportNotifications
    setSupportNotifications: (state, action) => {
      state.supportNotifications = action.payload;
    },

    // Reducer to update expiringNotifications
    setExpiringNotifications: (state, action) => {
      state.expiringNotifications = action.payload;
    },

    // Reducer to update expiredNotifications
    setExpiredNotifications: (state, action) => {
      state.expiredNotifications = action.payload;
    },

    // Reducer to update outOfStockNotifications
    setOutOfStockNotifications: (state, action) => {
      state.outOfStockNotifications = action.payload;
    },

    // Reducer to update notificationsPage
    setNotificationsPage: (state, action) => {
      state.notificationsPage = action.payload;
    },

    // Reducer to update notificationspageSize
    setNotificationspageSize: (state, action) => {
      state.notificationspageSize = action.payload;
    },

    // Reducer to update notification settings
    updateNotificationSettings: (state, action) => {
      const { settingName, value } = action.payload;
      state.notificationSettings[settingName] = value;
    },
    setUpdatingNotificationSettings: (state, action) => {
      state.updatingNotificationsSettings = action.payload;
    },

    // Reducer to update productToView
    setProductToView: (state, action) => {
      state.productToView = action.payload;
    },

    // Reducer to update deletingProduct
    setDeletingProduct: (state, action) => {
      state.deletingProduct = action.payload;
    },

    // Reducer to update editingProduct
    setEditingProduct: (state, action) => {
      state.editingProduct = action.payload;
    },

    // Reducer to set expiredProducts
    setExpiredProducts: (state, action) => {
      state.expiredProducts = action.payload;
    },

    // Reducer to set productsExpiringInAMonth
    setProductsExpiringInAMonth: (state, action) => {
      state.productsExpiringInAMonth = action.payload;
    },

    // Reducer to set productsExpiringInSixMonths
    setProductsExpiringInSixMonths: (state, action) => {
      state.productsExpiringInSixMonths = action.payload;
    },

    // Reducer to set productsExpiringInThreeMonths
    setProductsExpiringInThreeMonths: (state, action) => {
      state.productsExpiringInThreeMonths = action.payload;
    },
  },
});

export const {
  getNotificationStateUpdate,
  setGettingNotifications,
  setNewOrdersNotifications,
  setSupportNotifications,
  setExpiringNotifications,
  setExpiredNotifications,
  setOutOfStockNotifications,
  setNotificationsPage,
  setNotificationspageSize,
  updateNotificationSettings,
  setProductToView,
  setDeletingProduct,
  setEditingProduct,
  setExpiredProducts,
  setProductsExpiringInAMonth,
  setProductsExpiringInSixMonths,
  setProductsExpiringInThreeMonths,
  setUpdatingNotificationSettings,
} = notificationSlice.actions;

export const selectNotification = (state) => state.notification;

export default notificationSlice.reducer;
