import React from "react";
import styled from "styled-components";
import CustomLineChart from "../../../components/Charts/LineChart";
import CustomDonutChart from "../../../components/Charts/DonutChart";
import { AnalyticsTitle } from "./styles";
import { media } from "../../../Screens";

// Styling for the Analytics
const AnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px;
  border-radius: 10px;
  gap: 10px;

  ${media.desktop} {
    flex-direction: row;
    padding: 20;
  }
`;

const SalesSection = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  max-height: 90vh;

  ${media.desktop} {
    width: 70%;
    padding: 20px;
  }
`;

const DonutSection = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  gap: 10px;

  ${media.tablet} {
    flex-direction: row;
  }

  ${media.desktop} {
    flex-direction: column;
    padding: 20;
    width: 30%;
  }
`;

const DonutWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
  width: 100%;
`;

const SectionHeader = styled.h2`
  font-size: 18px;
  color: #4a4a4a;
`;

const TotalAmount = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #4a90e2;
  text-align: center;
  margin-top: 10px;
`;

const Analytics = () => {
  const lineData = [
    { name: "Oct 12", value: 567980 },
    { name: "Oct 13", value: 623450 },
    { name: "Oct 14", value: 678123 },
    { name: "Oct 15", value: 345768 },
    { name: "Oct 16", value: 478569 },
    { name: "Oct 17", value: 523789 },
    { name: "Oct 18", value: 567980 },
  ];

  const donutDataBranch = [
    { name: "Weekly", value: 567980 },
    { name: "Monthly", value: 1345123 },
    { name: "Yearly", value: 1345123 },
  ];

  const donutDataUser = [
    { name: "Daily", value: 567980 },
    { name: "Weekly", value: 567980 },
    { name: "Monthly", value: 1345123 },
    { name: "Yearly", value: 1345123 },
  ];

  return (
    <>
      <AnalyticsTitle>Analytics</AnalyticsTitle>
      <AnalyticsContainer>
        <SalesSection>
          <SectionHeader>Store Sales</SectionHeader>
          <CustomLineChart data={lineData} />
          <TotalAmount>N 567,980</TotalAmount>
        </SalesSection>

        <DonutSection>
          <DonutWrapper>
            <SectionHeader>Sales Per Branch</SectionHeader>
            <CustomDonutChart data={donutDataBranch} />
            <TotalAmount>N 1,345,123</TotalAmount>
          </DonutWrapper>

          <DonutWrapper>
            <SectionHeader>User Sales</SectionHeader>
            <CustomDonutChart data={donutDataUser} />
            <TotalAmount>N 80,098,346</TotalAmount>
          </DonutWrapper>
        </DonutSection>
      </AnalyticsContainer>
    </>
  );
};

export default Analytics;
