import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  overflow-x: scroll;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0000008f;
  padding: 0 5px;
  height: 21px;
  cursor: pointer;
  font-size: 16px;

  &.active {
    border-bottom: 1px solid ${({ theme }) => theme.blue};
  }
`;

const OrdersTab = ({ tabList }) => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <Container>
      {tabList?.map((tab, index) => (
        <Tab
          key={index + tab?.id}
          className={tab?.id === currentTab && "active"}
          onClick={() => setCurrentTab(tab?.id)}
        >
          {tab.name}
        </Tab>
      ))}
    </Container>
  );
};

export default OrdersTab;
