import React from "react";
import ColoredBgCard from "../../../components/Cards/ColoredBgCard";
import { OrderSummaryCardsContainer } from "./styled";
import { useTheme } from "styled-components";

const SummaryCards = ({ setCurrentTable }) => {
  const theme = useTheme();

  return (
    <OrderSummaryCardsContainer>
      <ColoredBgCard
        prop={"New Order"}
        cardBgColor={theme.bgGreen}
        value={"0"}
        iconBgColor={theme.white}
        icon={
          <img
            src="/assets/images/Online_pickup/new_order.svg"
            alt="new_order"
          />
        }
        textColor={theme.white}
        handleViewClick={() => setCurrentTable(1)}
        newItemsSignal={true}
      />
      <ColoredBgCard
        prop={"In Progress"}
        cardBgColor={theme.bgPurple}
        value={"0"}
        iconBgColor={theme.white}
        icon={
          <img
            src="/assets/images/Online_pickup/in_progress.svg"
            alt="in_progress"
          />
        }
        textColor={theme.white}
        handleViewClick={() => setCurrentTable(2)}
        newItemsSignal={true}
      />
      <ColoredBgCard
        prop={"Shipped"}
        cardBgColor={theme.cardYellow}
        value={"0"}
        iconBgColor={theme.bgYellow}
        icon={
          <img src="/assets/images/Online_pickup/shipped.svg" alt="shipped" />
        }
        textColor={theme.grey5}
        handleViewClick={() => setCurrentTable(3)}
      />
      <ColoredBgCard
        prop={"Completed"}
        cardBgColor={theme.bgThickBlue}
        value={"0"}
        iconBgColor={theme.white}
        icon={
          <img
            src="/assets/images/Online_pickup/completed.svg"
            alt="completed"
          />
        }
        textColor={theme.white}
        handleViewClick={() => setCurrentTable(4)}
      />
      <ColoredBgCard
        prop={"Canceled"}
        cardBgColor={theme.bgThickRed}
        value={"0"}
        iconBgColor={theme.white}
        icon={
          <img src="/assets/images/Online_pickup/canceled.svg" alt="canceled" />
        }
        textColor={theme.white}
        handleViewClick={() => setCurrentTable(5)}
      />
    </OrderSummaryCardsContainer>
  );
};

export default SummaryCards;
