import React from "react";
import AuthRouteContainer from "../../AuthRouteContainer";
import { AuthBannerTitle, FormFooterTextContainer } from "../style";
import FormCard from "../../../components/Cards/FormCard";
import TextInput from "../../../components/Inputs/TextInput";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../Redux/Slices/AuthSlice";
import { toast } from "react-toastify";
import { loginUser } from "../../../Apis/Auth";

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loggingInUser, loginEmail, loginPassword } = useSelector(selectAuth);

  const handleSubmit = (e) => {
    if (!loginEmail) {
      toast.error("Please enter your email");
    } else if (!loginPassword) {
      toast.error("Please enter your password");
    } else {
      loginUser(loginEmail, loginPassword);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  return (
    <AuthRouteContainer
      leftContent={
        <>
          <img src="/assets/images/Auth/zamda_logo.svg" alt="logo" />
          <AuthBannerTitle style={{ color: theme.white }}>
            POS (Point of Sales)
          </AuthBannerTitle>
          <img
            src="/assets/images/Auth/auth_banner_2.svg"
            alt="logo"
            width="80%"
          />
        </>
      }
      rightContent={
        <FormCard title={"Login"}>
          <TextInput
            label={"Email"}
            placeholder={"example@email.com"}
            value={loginEmail}
            required={true}
            type={"email"}
            handleChange={handleChange}
            name="loginEmail"
          />
          <TextInput
            label={"Password"}
            placeholder={"Enter password"}
            type={"password"}
            value={loginPassword}
            required={true}
            handleChange={handleChange}
            name="loginPassword"
          />
          <FormFooterTextContainer>
            <div className="remember">
              <input type="checkbox" width={"20px"} />
              <span>Remember me</span>
            </div>
            <Link to="/forgot-password" className="forgot_password">
              forgot password?
            </Link>
          </FormFooterTextContainer>
          {/* <Link to="/sign-up" className="forgot_password">
            Have no account?{" "}
            <span style={{ textDecoration: "underline" }}>Signup</span>
          </Link> */}
          <FilledButton
            bgColor={theme.purple}
            btnText={"Submit"}
            handleClick={handleSubmit}
            textColor={theme.white}
            customStyle={{ width: "100%" }}
            loading={loggingInUser}
            type={"submit"}
          />
        </FormCard>
      }
      bgColor={theme.blue}
    />
  );
};

export default Login;
