import React from "react";
import {
  Counter,
  ItemContainer,
  ItemsLeftContainer,
  ItemsRightContainer,
} from "./styles";

const CartItem = ({
  itemImage,
  itemName,
  itemPrice,
  itemCount,
  handleIncrement,
  handleDecrement,
  handleRemoveItem,
}) => {
  return (
    <ItemContainer>
      <ItemsLeftContainer>
        <img src={itemImage} alt="drug" />
        <div className="drug_details">
          <p className="drug_name">{itemName}</p>
          <p style={{ display: "flex", gap: "5px", alignItems: " center" }}>
            <span className="naira">₦</span>
            <span className="price">{itemPrice}</span>
            <span className="per_card">/ card</span>
          </p>
        </div>
      </ItemsLeftContainer>
      <ItemsRightContainer>
        <img
          src="/assets/images/Generals/modal_close.svg"
          alt="close"
          className="cancel_button"
          onClick={handleRemoveItem}
        />
        <Counter>
          <span className="minus" onClick={handleDecrement}>
            -
          </span>
          <span className="count">{itemCount}</span>
          <span className="plus" onClick={handleIncrement}>
            +
          </span>
        </Counter>
      </ItemsRightContainer>
    </ItemContainer>
  );
};

export default CartItem;
