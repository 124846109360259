import React from "react";
import ColoredBgCard from "../../../components/Cards/ColoredBgCard";
import { useTheme } from "styled-components";
import { SummaryCardsContainer } from "./../Notification/styles";

const ExpirationSummaryCards = ({ setCurrentTable }) => {
  const theme = useTheme();

  return (
    <SummaryCardsContainer>
      <ColoredBgCard
        prop={"Expired"}
        cardBgColor={"#FF18184D"}
        value={"0"}
        iconBgColor={theme.bgThickRed}
        icon={<img src="/assets/images/Expiration/expired.svg" alt="expired" />}
        textColor={theme.grey5}
        handleViewClick={() => setCurrentTable(1)}
      />
      <ColoredBgCard
        prop={"Expiring in 1 Month"}
        cardBgColor={theme.cardRed}
        value={"0"}
        iconBgColor={theme.bgRed}
        icon={
          <img src="/assets/images/Expiration/expiring.svg" alt="expired" />
        }
        textColor={theme.grey5}
        handleViewClick={() => setCurrentTable(2)}
      />
      <ColoredBgCard
        prop={"Expiring in 3 Month"}
        cardBgColor={theme.cardYellow}
        value={"0"}
        iconBgColor={theme.bgYellow}
        icon={
          <img src="/assets/images/Expiration/expiring.svg" alt="expired" />
        }
        textColor={theme.grey5}
        handleViewClick={() => setCurrentTable(3)}
      />
      <ColoredBgCard
        prop={"Expiring in 6 Month"}
        cardBgColor={theme.cardBlue}
        value={"0.00"}
        iconBgColor={theme.bgBlue}
        icon={
          <img src="/assets/images/Expiration/expiring.svg" alt="expired" />
        }
        textColor={theme.grey5}
        handleViewClick={() => setCurrentTable(4)}
      />
    </SummaryCardsContainer>
  );
};

export default ExpirationSummaryCards;
