import React from "react";
import BranchManagementTable from "./BranchManagementTable";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import AddNewBranchModal from "./modals/AddNewBranchModal";
import EditBranchModal from "./modals/EditBranchModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";

const BranchManagement = () => {
  const dispatch = useDispatch();
  return (
    <>
      <BranchManagementTable />
      <AddNewBranchModal />
      <EditBranchModal />
      <SuccessMessageModal id="add_new_branch_success" message={"Done"} />
      <SuccessMessageModal id="edit_branch_success" message={"Done"} />
      <ConfirmModal
        message="By deleting this branch all content and data on this branch will be deleted confirm you want to delete this branch."
        confirmOkText={"Delete"}
        id={"delete_branch"}
        handleOkClick={() => dispatch(setCurrentModal("delete_branch_success"))}
      />
      <CanceledMessageModal
        id={"delete_branch_success"}
        message={"Deleted Successfully"}
      />
    </>
  );
};

export default BranchManagement;
