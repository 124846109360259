import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const LeftContent = styled.div`
  width: 45px;
  height: 45px;
  border: 1px solid ${({ theme }) => theme.purple};

  img {
    width: 100%;
    height: 100%;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: flex-start;
`;

const Name = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 14.09px;
  letter-spacing: 0.25px;
  color: #211f7e;
`;

const Message = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.09px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #211f7e;
`;

const NewOrdersCard = ({ leftContent, name, message }) => {
  return (
    <Container>
      <LeftContent>{leftContent}</LeftContent>
      <RightContent>
        <Name>{name}</Name>
        <Message>{message}</Message>
      </RightContent>
    </Container>
  );
};

export default NewOrdersCard;
