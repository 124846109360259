import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import { TableActions } from "./styled";

export const columns = [
  { label: "ID", field: "order_id" },
  { label: "Amount (₦)", field: "amount" },
  { label: "Name", field: "name" },
  { label: "Address", field: "address" },
  { label: "Status", field: "status" },
  { label: "Action", field: "action" },
];

const CompletedOrdersTable = () => {
  const rows = [
    {
      order_id: "453298652",
      name: "Ekwealor hillary",
      address: "New Heaven Enugu",
      amount: "10000",
      action: (
        <TableActions>
          <img
            src="/assets/images/Online_pickup/cancel_order.svg"
            alt="cancel_order"
          />
          <img
            src="/assets/images/Online_pickup/approve_order.svg"
            alt="approve_order"
          />
        </TableActions>
      ),
      status: "Completed",
    },
    {
      order_id: "453298652",
      name: "Ekwealor hillary",
      address: "New Heaven Enugu",
      amount: "10000",
      action: (
        <TableActions>
          <img
            src="/assets/images/Online_pickup/cancel_order.svg"
            alt="cancel_order"
          />
          <img
            src="/assets/images/Online_pickup/approve_order.svg"
            alt="approve_order"
          />
        </TableActions>
      ),
      status: "Completed",
    },
    {
      order_id: "453298652",
      name: "Ekwealor hillary",
      address: "New Heaven Enugu",
      amount: "10000",
      action: (
        <TableActions>
          <img
            src="/assets/images/Online_pickup/cancel_order.svg"
            alt="cancel_order"
          />
          <img
            src="/assets/images/Online_pickup/approve_order.svg"
            alt="approve_order"
          />
        </TableActions>
      ),
      status: "Completed",
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Completed"}
      titleColor={"#2B47FC"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
    />
  );
};

export default CompletedOrdersTable;
