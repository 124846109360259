import React from "react";
import CustomerManagementTable from "./CustomerManagementTable";
import AddNewCustomerModal from "./modals/AddNewCustomerModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import EditCustomerModal from "./modals/EditCustomerModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import { useSelector } from "react-redux";
import { selectCustomer } from "../../../Redux/Slices/CustomerSlice";
import { deleteCustomer } from "../../../Apis/CustomerManagement";

const CustomerManagement = () => {
  const { customerToEdit, deletingCustomers } = useSelector(selectCustomer);

  return (
    <>
      <CustomerManagementTable />
      <AddNewCustomerModal />
      <EditCustomerModal />
      <SuccessMessageModal
        id={"add_new_customer_success"}
        message={"Customer added successfully"}
      />
      <SuccessMessageModal id="edit_customer_success" message={"Done"} />
      <ConfirmModal
        message="By deleting this customer all content and data on this customer will be deleted confirm you want to delete this customer."
        confirmOkText={"Delete"}
        id={"delete_customer"}
        handleOkClick={() => {
          deleteCustomer(customerToEdit?.id);
        }}
        deleting={deletingCustomers}
      />
      <CanceledMessageModal
        id={"delete_customer_success"}
        message={"Deleted Successfully"}
      />
    </>
  );
};

export default CustomerManagement;
