import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { setPrintingInvoice } from "../Redux/Slices/SalesSlice";

export const printInvoice = async (invoiceDetails) => {
  store.dispatch(setPrintingInvoice(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .post(`v1/pharmacies/${pharmacy?.id}/sales`, invoiceDetails)
    .then((res) => {
      toast.success("Successful");
      store.dispatch(setPrintingInvoice(false));
      store.dispatch("printed_invoice_modal");
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setPrintingInvoice(false));
    });
};
