import styled from "styled-components";
import { media } from "../../../Screens";

export const MarketPlaceHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: ${({ theme }) => theme.white};
  padding: 15px;
  width: 100%;
`;

export const MarketPlaceTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 23.48px;
  text-align: left;
  width: 100%;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 23px;
  padding: 10px 5px 10px 5px;
  background-color: ${({ theme }) => theme.palePurple};
`;

export const MarketPlaceMedicinesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  height: 100%;
  overflow-y: auto;

  ${media.phone} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const BuyPageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;

  ${media.tablet} {
    flex-direction: row;
  }

  .left_content {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 23.48px;
      text-align: left;
    }

    .sub_title {
      font-size: 20px;
      font-weight: 300;
      line-height: 23.48px;
      text-align: left;
      color: ${({ theme }) => theme.green2};
    }
  }

  .right_content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;

    .payment_section {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    ${media.tablet} {
      flex-direction: row;
    }
  }
`;

export const BuyDetails = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: column;
`;

// submit to admin styles
export const SubmitToAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding-top: 50px;
`;

export const SubmitToAdminTopContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SubmitToAdminLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SubmitToAdminProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .description {
    font-size: 20px;
    font-weight: 300;
    line-height: 23.48px;
    text-align: left;
  }
`;

export const ProgressBar = styled.div`
  width: 386px;
  height: 9px;
  gap: 10.55px;
  border-radius: 10.55px;
  border: 1.06px solid ${({ theme }) => theme.green};
  position: relative;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.green2};
    width: 268px;
    height: 9px;
    border-radius: 10.55px;
  }
`;

export const CancelSubmitSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .desc {
    font-size: 20px;
    font-weight: 300;
    line-height: 23.48px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
