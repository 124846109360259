import React, { useState } from "react";
import { OnlineOrderContainer, PageTitle } from "./styled";
import SummaryCards from "./SummaryCards";
import NewOrdersTable from "./NewOrderTable";
import InProgressTable from "./InProgressTable";
import ShippedOrdersTable from "./ShippedTable";
import CompletedOrdersTable from "./CompletedTable";
import CanceledOrdersTable from "./CanceledTable";

const OnlineOrder = () => {
  const [currentTable, setCurrentTable] = useState(5);

  return (
    <OnlineOrderContainer>
      <PageTitle>ONLINE ORDER / PICKUP</PageTitle>
      <SummaryCards setCurrentTable={setCurrentTable} />
      {currentTable === 1 && (
        <NewOrdersTable setCurrentTable={setCurrentTable} />
      )}
      {currentTable === 2 && (
        <InProgressTable setCurrentTable={setCurrentTable} />
      )}
      {currentTable === 3 && (
        <ShippedOrdersTable setCurrentTable={setCurrentTable} />
      )}
      {currentTable === 4 && (
        <CompletedOrdersTable setCurrentTable={setCurrentTable} />
      )}
      {currentTable === 5 && (
        <CanceledOrdersTable setCurrentTable={setCurrentTable} />
      )}
    </OnlineOrderContainer>
  );
};

export default OnlineOrder;
