import styled from "styled-components";

export const OrdersSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background: ${({ theme }) => theme.white};
  width: 100%;
  border-radius: 18px;
  box-shadow: 0px 2px 4px 0px #00000012;
  height: 95px;
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-right: 0.5px solid #00000030;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.78px;
  padding: 10px;
`;

export const SingleSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-right: 0.5px solid #00000030;
  padding: 10px;
  justify-content: center;

  .prop {
    font-size: 11px;
    font-weight: 300;
    line-height: 12.91px;
  }
  .value {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.78px;
  }
`;

// search section
export const OrdersSearchSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 108px;
  height: 32px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  background: ${({ theme }) => theme.white};

  .divider {
    width: 1px;
    height: 16px;
    border: 0.5px solid #70707080;
  }

  img {
    cursor: pointer;
  }
`;
