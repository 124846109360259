import React from "react";
import { NewOrdersContainer, NewOrdersTitle } from "./styles";
import OutOfStockTable from "./OutofStockTable";

const OutofStock = () => {
  return (
    <NewOrdersContainer style={{ background: "none" }}>
      <NewOrdersTitle>Out of Stock Notification</NewOrdersTitle>
      <OutOfStockTable />
    </NewOrdersContainer>
  );
};

export default OutofStock;
