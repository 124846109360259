import React from "react";
import RoundedButton from "../../../components/Buttons/RoundedButton";
import { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import PurchaseHistoryTable from "./PurchaseHistoryTable";
import {
  CancelSubmitSection,
  ProgressBar,
  SubmitToAdminContainer,
  SubmitToAdminLeftContent,
  SubmitToAdminProgressContainer,
  SubmitToAdminTopContent,
} from "./style";
import ColoredBgCard from "../../../components/Cards/ColoredBgCard";
import { useNavigate } from "react-router-dom";
import SubmitRequestModal from "./modals/SubmitRequestModal";
import ViewSubmittedRequest from "./modals/ViewSubmitedRequestModal";

const SubimtToAdmin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancel = () => {
    dispatch(setCurrentModal("submit_request_cancelled"));
    navigate("/marketplace");
  };

  const handleSubmit = () => {
    dispatch(setCurrentModal("submit_request"));
  };

  const handleViewRequest = () => {
    dispatch(setCurrentModal("view_submitted_request"));
  };

  return (
    <>
      <SubmitToAdminContainer>
        <SubmitToAdminTopContent>
          <SubmitToAdminLeftContent>
            <SubmitToAdminProgressContainer>
              <p className="description">
                Order in progress, Admin will respond soon
              </p>
              <ProgressBar>
                <span className="progress"></span>
              </ProgressBar>
            </SubmitToAdminProgressContainer>
            <CancelSubmitSection>
              <p className="desc" onClick={handleViewRequest}>
                View Submitted Request
              </p>
              <RoundedButton
                btnText={"Cancel"}
                bgColor={theme.bgThickRed}
                textColor={theme.white}
                handleClick={handleCancel}
              />
            </CancelSubmitSection>
          </SubmitToAdminLeftContent>
          <div style={{ width: "300px" }}>
            <ColoredBgCard
              cardBgColor={theme.green2}
              iconBgColor={theme.white}
              icon={
                <img src="/assets/images/Generals/upload.svg" alt="upload" />
              }
              textColor={theme.white}
              otherText="Submit Request"
              handleOtherClicks={handleSubmit}
            />
          </div>
        </SubmitToAdminTopContent>
        <PurchaseHistoryTable />
      </SubmitToAdminContainer>
      <SubmitRequestModal />
      <ViewSubmittedRequest />
    </>
  );
};

export default SubimtToAdmin;
