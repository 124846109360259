import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CloseSidebarOnSelect,
  selectNav,
} from "../../../Redux/Slices/navSlice";
import { NavContainer, NavItem, SidebarContainer } from "./style";
import { Sidenav } from "rsuite";
import { sidebarNavs } from "./navUtils";
import { TbLogout } from "react-icons/tb";
import { logout } from "../../../Apis/Auth";

const Sidebar = () => {
  // const [expanded, setExpanded] = React.useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const { showSidebar } = useSelector(selectNav);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    pathname.includes(`/`) && setActiveKey("1");
    pathname.includes(`/sales`) && setActiveKey("2");
    pathname.includes(`/customer-management`) && setActiveKey("3");
    pathname.includes(`/prescription-management`) && setActiveKey("4");
    pathname.includes(`/online-order`) && setActiveKey("5");
    pathname.includes(`/marketplace`) && setActiveKey("6");
    pathname.includes(`/inventory`) && setActiveKey("7");
    pathname.includes(`/analytics`) && setActiveKey("8");
    pathname.includes(`/employee-management`) && setActiveKey("9");
    pathname.includes(`/branch-management`) && setActiveKey("10");
    pathname.includes(`/health-management`) && setActiveKey("11");
    pathname.includes(`/customer-support`) && setActiveKey("12");
    pathname.includes(`/notification`) && setActiveKey("13");
    pathname.includes(`/product-management`) && setActiveKey("14");
    pathname.includes(`/supplier-management`) && setActiveKey("15");
  }, [pathname]);

  return (
    <SidebarContainer showSidebar={showSidebar}>
      <Sidenav
        // defaultOpenKeys={["1", "2"]}
        style={{ height: `100%`, overflowY: `auto`, background: `none` }}
      >
        <Sidenav.Body>
          <NavContainer
            activeKey={activeKey}
            onSelect={setActiveKey}
            appearance="tabs"
          >
            {sidebarNavs?.map((nav) => (
              <NavItem
                eventKey={`${nav.id}`}
                icon={nav?.icon}
                onClick={() => {
                  navigate(nav?.link);
                  dispatch(CloseSidebarOnSelect());
                }}
              >
                {nav?.name}
              </NavItem>
            ))}
            <NavItem
              icon={<TbLogout />}
              onClick={() => {
                dispatch(CloseSidebarOnSelect());
                logout();
              }}
            >
              Logout
            </NavItem>
          </NavContainer>
        </Sidenav.Body>
        {/* <Sidenav.Toggle expanded={expanded} onToggle={(expanded) => setExpanded(expanded)} /> */}
      </Sidenav>
    </SidebarContainer>
  );
};

export default Sidebar;
