import React from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  getSalesStateUpdate,
  selectSales,
} from "../../../../Redux/Slices/SalesSlice";
import { printInvoice } from "../../../../Apis/Sales";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const PatientNameModal = ({ handlePrint }) => {
  const { patientName, salesCart, discount, printingInvoice } =
    useSelector(selectSales);
  const theme = useTheme();
  const dispatch = useDispatch();
  const handlePrintInvoice = async (e) => {
    e.preventDefault();
    const invoice = {
      customerName: patientName,
      currencyId: 1,
      discountAmount: discount,
      items: salesCart.cartItems.map((item) => ({
        quantity: item.count,
        productId: item.id,
        discountAmount: 0, // Assuming no discount is applied here
      })),
    };
    try {
      await printInvoice(invoice); // Assuming this is an async API call
      handlePrint(); // Trigger the print operation
    } catch (error) {
      console.error("Failed to print invoice:", error);
      toast.error("Failed to prepare the invoice for printing.");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSalesStateUpdate({ name, value }));
  };

  return (
    <CustomModal
      id="patient_name"
      content={
        <Container>
          <ModalTitle>Patient Details</ModalTitle>
          <TextInput
            placeholder={"Enter patient name"}
            label={"Patient Name"}
            value={patientName}
            name={"patientName"}
            handleChange={handleChange}
          />
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Proceed"}
              handleClick={handlePrintInvoice}
              textColor={theme.white}
              loading={printingInvoice}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default PatientNameModal;
