import React from "react";
import styled from "styled-components";
import { media } from "../../Screens";

const Container = styled.form`
  width: 90%;
  height: auto;
  border-radius: 10px;
  box-shadow: 6px 6px 11.9px 3px #00000005;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;

  ${media.tablet} {
    width: 494px;
    padding: 50px;
  }
`;

const FormTitle = styled.div`
  font-size: 26px;
  font-weight: 300;
  line-height: 42.26px;
  text-align: left;
  color: ${({ theme }) => theme.purple};

  ${media.tablet} {
    font-size: 36px;
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormCard = ({ children, title }) => {
  return (
    <Container>
      <FormTitle>{title} </FormTitle>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

export default FormCard;
