import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingEmployees: false,
  editingEmployees: false,
  deletingEmployees: false,
  addingEmployees: false,
  settingRoles: false,
  employees: null,
  employeesPageSize: 20,
  employeesPage: 1,

  // Employee details
  employeeFirstName: "",
  employeeLastName: "",
  employeeEmail: "",
  employeeBranch: "",
  employeeRole: "",
  employeePassword: "",

  // Role settings
  roleTitle: "",
  rolePermissions: [], // This will be an array

  // Editing employee details
  employeeToEdit: null,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    getEmployeeStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingEmployees
    setGettingEmployees: (state, action) => {
      state.gettingEmployees = action.payload;
    },

    // Reducer to update editingEmployees
    setEditingEmployees: (state, action) => {
      state.editingEmployees = action.payload;
    },

    // Reducer to update deletingEmployees
    setDeletingEmployees: (state, action) => {
      state.deletingEmployees = action.payload;
    },

    // Reducer to update addingEmployees
    setAddingEmployees: (state, action) => {
      state.addingEmployees = action.payload;
    },

    // Reducer to update settingRoles
    setSettingRoles: (state, action) => {
      state.settingRoles = action.payload;
    },

    // Reducer to update employees
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },

    // Reducer to update employeespageSize
    setEmployeespageSize: (state, action) => {
      state.employeespageSize = action.payload;
    },

    // Reducer to update employeesPage
    setEmployeesPage: (state, action) => {
      state.employeesPage = action.payload;
    },

    // Reducer to add an item to rolePermissions, ensuring no duplicates
    addRolePermission: (state, action) => {
      if (!state.rolePermissions.includes(action.payload)) {
        state.rolePermissions.push(action.payload);
      }
    },

    // Reducer to remove an item from rolePermissions
    removeRolePermission: (state, action) => {
      state.rolePermissions = state.rolePermissions.filter(
        (permission) => permission !== action.payload
      );
    },

    // Reducer to update employeeToEdit
    setEmployeeToEdit: (state, action) => {
      state.employeeToEdit = action.payload;
    },
  },
});

export const {
  getEmployeeStateUpdate,
  setGettingEmployees,
  setEditingEmployees,
  setDeletingEmployees,
  setAddingEmployees,
  setEmployees,
  setEmployeespageSize,
  setEmployeesPage,
  addRolePermission,
  removeRolePermission,
  setEmployeeToEdit,
  setSettingRoles,
} = employeeSlice.actions;

export const selectEmployee = (state) => state.employee;

export default employeeSlice.reducer;
