import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import styled, { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import ColoredBgCard from "../../../../components/Cards/ColoredBgCard";
import FilePreview from "../../../../components/Reusables/FilePreview";
import { media } from "../../../../Screens";

const Container = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const LeftContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 90vw;
  padding: 10px;

  ${media.phone} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.tablet} {
    display: flex;
    width: 800px;
    flex-direction: row;
  }
`;
const RightContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  img {
    max-height: 500px;
    max-width: 400px;
  }
`;

const UploadPrescriptionModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState();

  const handleUpload = (e) => {
    setUploadedFile(e);
  };

  const handleDelete = () => {
    dispatch(setCurrentModal("delete_uploaded_prescription_success"));
    setUploadedFile();
  };
  const handleSave = () => {
    dispatch(setCurrentModal("save_uploaded_prescription_success"));
    setUploadedFile();
  };

  return (
    <CustomModal
      id="upload_prescription"
      content={
        <Container>
          <LeftContent>
            <ColoredBgCard
              cardBgColor={theme.green2}
              iconBgColor={theme.white}
              icon={
                <img
                  src="/assets/images/Prescription/upload.svg"
                  alt="new_order"
                />
              }
              textColor={theme.white}
              //   handleViewClick={() => setCurrentTable(1)}
              otherText={
                <>
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    Upload
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                </>
              }
              handleOtherClicks={() => {}}
            />
            <ColoredBgCard
              cardBgColor={theme.blue}
              iconBgColor={theme.white}
              icon={
                <img
                  src="/assets/images/Prescription/view.svg"
                  alt="new_order"
                />
              }
              textColor={theme.white}
              otherText={"Save"}
              handleOtherClicks={handleSave}
            />
            <ColoredBgCard
              cardBgColor={theme.red}
              iconBgColor={theme.white}
              icon={
                <img
                  src="/assets/images/Prescription/delete.svg"
                  alt="in_progress"
                />
              }
              textColor={theme.white}
              otherText={"Delete"}
              handleOtherClicks={handleDelete}
            />
          </LeftContent>
          <RightContent>
            {uploadedFile && <FilePreview selectedFile={uploadedFile} />}
          </RightContent>
        </Container>
      }
    />
  );
};

export default UploadPrescriptionModal;
