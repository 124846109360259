import React from "react";
import { BuyPageContainer } from "./style";
import DrugDetailsCard from "../../../components/Cards/DrugDetailCard";
import { Link, useNavigate } from "react-router-dom";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import PaymentMethodSelector from "../../../components/PaymentGateways/PaymentMethodSelector";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";

const BuyConfirmation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <BuyPageContainer>
      <div className="left_content">
        <p className="title">Marketplace</p>
        <p className="sub_title">Seller is verified</p>
      </div>
      <div className="right_content">
        <Link to="/marketplace">
          <img
            src="/assets/images/Generals/gree_caret_left.svg"
            alt=""
            style={{ cursor: "pointer" }}
          />
        </Link>
        <div className="payment_section">
          <DrugDetailsCard
            drugDesc={
              "Blood Capsules and Tonic. Chemiron Blood Tonic is a highly effective formula to prevent and treat iron deficiency anaemia"
            }
            drugName={"Blood Capsule"}
            image={
              <img src="/assets/images/Sales/blood_caapsule.svg" alt="drug" />
            }
            netWeight={"500mg"}
            price={1000}
            stock={100}
          />
          <PaymentMethodSelector />
          <FilledButton
            bgColor={theme.green2}
            textColor={theme.white}
            btnText={"Confirm Buy"}
            handleClick={() => {
              dispatch(setCurrentModal("buy_order_placed"));
              navigate("/marketPlace");
            }}
          />
        </div>
      </div>
    </BuyPageContainer>
  );
};

export default BuyConfirmation;
