import React from "react";
import AuthRouteContainer from "../../AuthRouteContainer";
import { AuthBannerTitle } from "../style";
import FormCard from "../../../components/Cards/FormCard";
import TextInput from "../../../components/Inputs/TextInput";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../Redux/Slices/AuthSlice";
import { toast } from "react-toastify";
import {
  validateEmail,
  validatePass,
  validatePhoneNumber,
} from "../../../helpers";

const AdminAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    pharmacistFullName,
    pharmacistPhoneNumber,
    pharmacistEmail,
    pharmacistUserName,
    pharmacistPassword,
    pharmacistConfirmPassword,
  } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!pharmacistFullName) {
      toast.error("Full name is required");
    } else if (!pharmacistPhoneNumber) {
      toast.error("Phone number is required");
    } else if (!pharmacistEmail) {
      toast.error("Email is required");
    } else if (!pharmacistUserName) {
      toast.error("Username is required");
    } else if (!pharmacistPassword) {
      toast.error("password is required");
    } else if (pharmacistPassword !== pharmacistConfirmPassword) {
      toast.error("passwords does not match");
    } else {
      if (
        validateEmail(pharmacistEmail) &&
        validatePhoneNumber(pharmacistPhoneNumber) &&
        validatePass(pharmacistPassword)
      ) {
        navigate("/sign-up/activate-product");
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  return (
    <AuthRouteContainer
      leftContent={
        <>
          <img src="/assets/images/Auth/zamda_logo.svg" alt="logo" />
          <AuthBannerTitle>POS (Point of Sales)</AuthBannerTitle>
          <img
            src="/assets/images/Auth/auth_banner.svg"
            alt="logo"
            width="80%"
          />
        </>
      }
      rightContent={
        <FormCard title={"Admin Account"}>
          <TextInput
            label={"Full Name"}
            placeholder={"Enter full name"}
            type={"text"}
            value={pharmacistFullName}
            name={"pharmacistFullName"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Phone Number"}
            placeholder={"Enter phone number"}
            type={"tel"}
            value={pharmacistPhoneNumber}
            name={"pharmacistPhoneNumber"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Email"}
            placeholder={"example@email.com "}
            type={"email"}
            value={pharmacistEmail}
            name={"pharmacistEmail"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Username"}
            placeholder={"@example"}
            type={"text"}
            value={pharmacistUserName}
            name={"pharmacistUserName"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Password"}
            type={"password"}
            value={pharmacistPassword}
            name={"pharmacistPassword"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Confirm Password"}
            type={"password"}
            value={pharmacistConfirmPassword}
            name={"pharmacistConfirmPassword"}
            required={true}
            handleChange={handleChange}
          />
          <FilledButton
            bgColor={theme.purple}
            btnText={"Submit & Continue"}
            handleClick={handleSubmit}
            textColor={theme.white}
            customStyle={{ width: "100%" }}
            type={"submit"}
          />
        </FormCard>
      }
    />
  );
};

export default AdminAccount;
