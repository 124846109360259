export const lightTheme = {
  textGrey: "#343030",
  white: "#ffffff",
  black: "#000",
  black2: "#121212",
  black3: "#051114",
  black4: "#1A1A1A",
  black5: "#39343470",
  grey: "#707070",
  grey2: "#5b5b5b",
  grey3: "#C3CAD9",
  grey4: "#3A3A3A",
  grey5: "#6f6f6f",
  green: "#04D3BC",
  green2: "#20C906",
  blue: "#211F7E",
  purple: "#4D4B9D",
  palePurple: "#9747FF1A",
  purpleLight: "#e9e9f3",
  brown: "#A26F3D",
  darkPurple: "#0D0D35",
  white2: "#FBF8F8",
  red: "#E01212",
  translucentBlack: "#0000000D",
  translucentCyan: "#04D3BC33",
  yellow: "#F2CD08",
  translucentYellow: "#F2CD0833",
  translucentWhite: "#F6F6FF",
  boxShadow1: "10px 0px 20px 2px #1414140D",
  boxShadow2: "0px 6px 30px 5px #12121240",
  boxShadow3: "0px 8px 10px 0px #20202040",
  boxShadow4: "4px 4px 4px 0px #0000001a",
  boxShadow5: "0px 6px 50px 5px #060e1a0d",

  cardGrey: "#08481930",
  cardRed: "#C5211230",
  cardYellow: "#C99E1130",
  cardBlue: "#1192C930",

  bgGreen: "#084819",
  bgRed: "#C52112",
  bgYellow: "#C99E11",
  bgBlue: "#1192C9",
  bgPurple: "#9747FF",
  bgThickBlue: "#2B47FC",
  bgThickRed: "#BF4130",

  pink: "#F1EBEB",
};

export const darkTheme = {};
