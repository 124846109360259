import React from "react";
import styled from "styled-components";
import { media } from "../Screens";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.pink};
  overflow: hidden;
`;

const LeftContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.5;
  gap: 15px;
  background-color: ${(props) => props.bgColor};
  border-top-right-radius: 56px;
  border-bottom-right-radius: 56px;

  ${media.tablet} {
    display: flex;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  overflow-y: auto;

  ${media.tablet} {
    flex: 0.5;
  }
`;

const AuthRouteContainer = ({ leftContent, rightContent, bgColor }) => {
  return (
    <Container>
      <LeftContainer bgColor={bgColor}>{leftContent}</LeftContainer>
      <RightContainer>{rightContent}</RightContainer>
    </Container>
  );
};

export default AuthRouteContainer;
