import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  text-align: left;
  text-transform: capitalize;
`;

const Upload = styled.label`
  width: 160px;
  height: 30px;
  padding: 6px 73px 6px 69px;
  border: 1.65px solid #70707080;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileUpload = ({ label, handleChange }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Upload htmlFor="file" onChange={handleChange}>
        <img src="/assets/images/Generals/upload.svg" alt="upload"/>
      </Upload>
      <input
        type="file"
        name="file"
        id="file"
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </Container>
  );
};

export default FileUpload;
