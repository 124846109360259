import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  userProfile: {},
  gettingUserProfile: false,
  adminRoles: {},
  gettingAdminRoles: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setAdminRoles: (state, action) => {
      state.adminRoles = action.payload;
    },
    setGettingAdminRoles: (state, action) => {
      state.gettingAdminRoles = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setGettingUserProfile: (state, action) => {
      state.gettingUserProfile = action.payload;
    },
  },
});

export const { setUser, setUserProfile, setGettingUserProfile, setAdminRoles, setGettingAdminRoles } =
  userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
