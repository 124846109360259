import React from "react";
import Router from "./pages/Routers";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import { lightTheme } from "./theme";

function App() {
  // const [theme, setTheme] = useState("light");

  //  const themeToggler = () => {
  //    theme === "light" ? setTheme("dark") : setTheme("light");
  //    localStorage.setItem("currentTheme", theme === "light" ? "dark" : "light");
  //  };

  // useEffect(() => {
  //   const currentTheme = localStorage.getItem("currentTheme");
  //   setTheme(currentTheme || "light");
  // }, [theme]);

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  );
}

export default App;
