import { toast } from "react-toastify";
import api from "./baseUri";
import { store } from "../Redux/app/store";
import {
  setLoggedInUser,
  setLoggingInUser,
  setResettingPassword,
  setSendingOtp,
  setSigningUp,
} from "../Redux/Slices/AuthSlice";
import {
  setAdminRoles,
  setGettingAdminRoles,
  setGettingUserProfile,
  setUserProfile,
} from "../Redux/Slices/userSlice";

// login user handler
export const loginUser = async (email, password) => {
  store.dispatch(setLoggingInUser(true));
  const body = {
    email,
    password,
  };
  await api
    .post("v1/pharmacy-administrators/auth", body)
    .then((res) => {
      const refreshToken = res.headers["x-refresh-auth"];
      toast.success("login successful");
      store.dispatch(setLoggedInUser({ accessToken: res?.data, refreshToken }));
      store.dispatch(setLoggingInUser(false));
      window.replace("/");
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLoggingInUser(false));
    });
};

// login user profile
export const getUserProfile = async () => {
  store.dispatch(setGettingUserProfile(true));
  await api
    .get("v1/pharmacy-administrators/profile")
    .then((res) => {
      store.dispatch(setUserProfile(res?.data));
      store.dispatch(setGettingUserProfile(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingUserProfile(false));
    });
};

// login admin Roles
export const getAdminRoles = async () => {
  store.dispatch(setGettingAdminRoles(true));
  await api
    .get("v1/admin-roles")
    .then((res) => {
      store.dispatch(setAdminRoles(res?.data));
      store.dispatch(setGettingAdminRoles(false));
    })
    .catch(() => {
      store.dispatch(setGettingAdminRoles(false));
    });
};

// send otp handler
export const sendOTP = async (email) => {
  store.dispatch(setSendingOtp(true));
  const body = {
    email,
  };
  await api
    .post("v1/pharmacy-administrators/start-password-recovery", body)
    .then((res) => {
      toast.success("OTP sent to your email account");
      store.dispatch(setSendingOtp(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSendingOtp(false));
    });
};

export const resetPassword = async (code, password) => {
  store.dispatch(setResettingPassword(true));
  const body = {
    code,
    password,
  };
  await api
    .post("v1/pharmacy-administrators/complete-password-recovery", body)
    .then((res) => {
      toast.success(res.data.message);
      store.dispatch(setResettingPassword(false));
      window.location.replace("/login");
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setResettingPassword(false));
    });
};

export const signUpUser = async (
  name,
  email,
  phoneNumber,
  firstName,
  lastName,
  password,
  logoUrl,
  activationKey
) => {
  store.dispatch(setSigningUp(true));
  const body = {
    name,
    email,
    phoneNumber,
    firstName,
    lastName,
    password,
    logoUrl,
    activationKey,
  };
  await api
    .post("v1/pharmacies", body)
    .then((res) => {
      toast.success(res.data.message);
      store.dispatch(setSigningUp(false));
      window.location.replace("/");
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSigningUp(false));
    });
};

export const logout = () => {
  localStorage.clear();
  window.location.replace("/");
};
