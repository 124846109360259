import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import {
  setAddingEmployees,
  setDeletingEmployees,
  setEditingEmployees,
  setEmployees,
  setGettingEmployees,
} from "../Redux/Slices/EmployeeSlice";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const getEmployees = async (page, pageSize) => {
  store.dispatch(setGettingEmployees(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/members?page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      store.dispatch(setEmployees(res.data)); // Adjust according to your response structure
      store.dispatch(setGettingEmployees(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingEmployees(false));
    });
};

export const addEmployee = async (employeeData) => {
  store.dispatch(setAddingEmployees(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .post(`v1/pharmacies/${pharmacy?.id}/members`, employeeData, {
      params: { id: pharmacy.id },
    })
    .then(async (res) => {
      store.dispatch(setAddingEmployees(false));
      // Optionally, refetch employees or update state to include the new employee
      toast.success("Employee added successfully!");
      await getEmployees(); // Refetch employees
      store.dispatch(setCurrentModal("create_user_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingEmployees(false));
    });
};

export const editEmployee = async (employeeId, employeeData) => {
  store.dispatch(setEditingEmployees(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .put(`v1/pharmacies/${pharmacy?.id}/members/${employeeId}`, employeeData)
    .then(async (res) => {
      store.dispatch(setEditingEmployees(false));
      toast.success("Employee updated successfully!");
      await getEmployees(); // Refetch employees or update state
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setEditingEmployees(false));
    });
};

export const deleteEmployee = async (employeeId) => {
  store.dispatch(setDeletingEmployees(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;
  await api
    .delete(`v1/pharmacies/${pharmacy?.id}/members/${employeeId}`)
    .then(async (res) => {
      store.dispatch(setDeletingEmployees(false));
      toast.success("Employee deleted successfully!");
      await getEmployees(); // Refetch employees or update state
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingEmployees(false));
    });
};
