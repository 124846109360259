import React, { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";

const ApplePayPayment = () => {
  const stripe = useStripe();

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: 5000, // Example amount in cents ($50.00)
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          paymentRequest.show();
        }
      });

      paymentRequest.on("paymentmethod", (ev) => {
        stripe
          .confirmCardPayment("{CLIENT_SECRET}", {
            payment_method: ev.paymentMethod.id,
          })
          .then((confirmResult) => {
            if (confirmResult.error) {
              ev.complete("fail");
            } else {
              ev.complete("success");
            }
          });
      });
    }
  }, [stripe]);

  return <div>Apple Pay Payment</div>;
};

export default ApplePayPayment;
