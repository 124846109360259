import { toast } from "react-toastify";

export const validatePhoneNumber = (phoneNumber) => {
  // Define the regex for phone number validation
  const phoneRegex = /^0\d{10}$/;

  // Check if the input matches the regex
  if (!phoneRegex.test(phoneNumber)) {
    toast.error(
      "Invalid phone number. Please enter a valid phone number with up to 15 digits"
    );
    return false;
  } else {
    return true;
  }
};

// Email Validation
export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(email)) {
    toast.error(
      "Invalid email address. Please enter a valid email in the format 'example@example.com'."
    );
    return false;
  } else {
    return true;
  }
};

// Password Validation (at least 8 characters, 1 uppercase, 1 lowercase, 1 digit, 1 special character)
export const validatePass = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(password)) {
    toast.error(
      "Invalid password. Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character."
    );
    return false;
  } else {
    return true;
  }
};

export function mapToLabelValueArray(data, labelId, valueId) {
  return data?.map((item) => ({
    label: item[labelId],
    value: item[valueId],
  }));
}

export function formatDate(dateString) {
  const date = new Date(dateString); // Convert ISO string to Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, "0"); // Ensure day is two digits

  return `${year}-${month}-${day}`;
}
