import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const EntriesContainer = styled.div`
  font-size: 13.8px;
  font-weight: 300;
  line-height: 16.2px;
  text-align: left;
`;

const PaginationButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .button {
    width: 87.71px;
    height: 44.53px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-size: 12.14px;
    font-weight: 600;
    line-height: 14.84px;
    text-align: left;
    border: 1px solid #d9e1e7;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }

    .img {
      width: 12.14px;
    }
  }
`;

const CustomPagination = ({
  updatePage,
  currentPage,
  totalPages,
  pageSize,
  totalItems,
}) => {
  const handleBackClick = () => {
    updatePage(currentPage - 1);
  };
  const handleFowardClick = () => {
    updatePage(currentPage + 1);
  };

  return (
    <Container>
      <EntriesContainer>
        Showing {currentPage} of {totalPages} entries
      </EntriesContainer>
      <PaginationButtonsContainer>
        <button
          className="button"
          onClick={handleBackClick}
          disabled={currentPage === 1}
        >
          <img src="/assets/images/Generals/caret_left.svg" alt="left_icon" />
          <span>Back</span>
        </button>
        <button
          className="button"
          onClick={handleFowardClick}
          disabled={currentPage === totalPages}
        >
          <span>Next</span>
          <img src="/assets/images/Generals/caret_right.svg" alt="right_icon" />
        </button>
      </PaginationButtonsContainer>
    </Container>
  );
};

export default CustomPagination;
