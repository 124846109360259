import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../Slices/userSlice";
import navReducer from "../Slices/navSlice";
import analyticsReducer from "../Slices/AnalyticsSlice";
import authReducer from "../Slices/AuthSlice";
import branchReducer from "../Slices/BranchSlice";
import customerReducer from "../Slices/CustomerSlice";
import employeeReducer from "../Slices/EmployeeSlice";
import healthReducer from "../Slices/HealthSlice";
import homeReducer from "../Slices/HomeSlice";
import inventoryReducer from "../Slices/InventorySlice";
import marketplaceReducer from "../Slices/MarketplaceSlice";
import notificationReducer from "../Slices/NotificationSlice";
import onlineOrderReducer from "../Slices/OnlineOrderSlice";
import prescriptionReducer from "../Slices/PrescriptionSlice";
import salesReducer from "../Slices/SalesSlice";
import storesReducer from "../Slices/StoresSlice";
import supplierReducer from "../Slices/SupplierSlice";
import productsReducer from "../Slices/ProductSlice";
import currencyReducer from "../Slices/currencySlice";
import categoriesReducer from "../Slices/CategorySlice";
import roleReducer from "../Slices/roleSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import thunk from "redux-thunk"; // default localStorage
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["sales"], // Only persist sales slice
};
const rootReducer = combineReducers({
  user: userReducer,
  nav: navReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  branch: branchReducer,
  customer: customerReducer,
  employee: employeeReducer,
  health: healthReducer,
  home: homeReducer,
  inventory: inventoryReducer,
  marketplace: marketplaceReducer,
  notification: notificationReducer,
  onlineOrder: onlineOrderReducer,
  prescription: prescriptionReducer,
  sales: salesReducer,
  stores: storesReducer,
  supplier: supplierReducer,
  products: productsReducer,
  categories: categoriesReducer,
  currency: currencyReducer,
  role: roleReducer,
});

// Wrap the rootReducer with persistReducer to persist specific slices
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store); // For persistor object
