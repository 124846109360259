import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  printingInvoice: false,

  waitingLists: [],
  salesCart: {
    patientId: null,
    patientName: "",
    cartItems: [],
  },
  summary: 0,
  vat: 0,
  discount: 0,
  total: 0,
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    getSalesStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    calculateTotals: (state) => {
      state.summary = state.salesCart.cartItems.reduce(
        (acc, item) => acc + item.sellingPrice * item.count,
        0
      );
      state.vat = state.summary * 0.07; // 7% VAT
      state.discount = state.summary * 0.04; // 4% discount
      state.total = state.summary + state.vat - state.discount;
    },
    addToSalesCart: (state, action) => {
      const existingItem = state.salesCart.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        // Increase the count if the item is already in the cart
        existingItem.count += 1;
      } else {
        // Add new item with count 1
        state.salesCart.cartItems.push({ ...action.payload, count: 1 });
      }

      // Recalculate totals
      salesSlice.caseReducers.calculateTotals(state);

      // Update the waiting list if patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        const existingWaitlistItem = waitlistEntry.cartItems.find(
          (item) => item.id === action.payload.id
        );
        if (existingWaitlistItem) {
          existingWaitlistItem.count += 1;
        } else {
          waitlistEntry.cartItems.push({ ...action.payload, count: 1 });
        }
      }
    },

    removeFromSalesCart: (state, action) => {
      const existingItem = state.salesCart.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        if (existingItem.count > 1) {
          existingItem.count -= 1; // Decrease the count
        } else {
          state.salesCart.cartItems = state.salesCart.cartItems.filter(
            (item) => item.id !== action.payload.id
          ); // Remove the item if count is 1
        }
      }

      // Recalculate totals
      salesSlice.caseReducers.calculateTotals(state);

      // Update the waiting list if patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        const existingWaitlistItem = waitlistEntry.cartItems.find(
          (item) => item.id === action.payload.id
        );
        if (existingWaitlistItem) {
          if (existingWaitlistItem.count > 1) {
            existingWaitlistItem.count -= 1; // Decrease the count
          } else {
            waitlistEntry.cartItems = waitlistEntry.cartItems.filter(
              (item) => item.id !== action.payload.id
            ); // Remove the item if count is 1
          }
        }
      }
    },
    removeItemFromSalesCart: (state, action) => {
      // Remove item completely from salesCart
      state.salesCart.cartItems = state.salesCart.cartItems.filter(
        (item) => item.id !== action.payload.id
      );

      // Recalculate totals
      salesSlice.caseReducers.calculateTotals(state);

      // Update the waiting list if patient exists
      const waitlistEntry = state.waitingLists.find(
        (entry) => entry.patientName === state.salesCart.patientName
      );
      if (waitlistEntry) {
        // Remove the item from the waitlist's cartItems
        waitlistEntry.cartItems = waitlistEntry.cartItems.filter(
          (item) => item.id !== action.payload.id
        );

        // If the waitlist's cartItems is empty, remove the waitlist entry
        if (waitlistEntry.cartItems.length === 0) {
          state.waitingLists = state.waitingLists.filter(
            (entry) => entry.patientName !== state.salesCart.patientName
          );
        }
      }
    },
    removeItemFromWaitlist: (state, action) => {
      const itemId = action.payload.id; // Get the item ID from the action payload

      // Loop through the waitingLists to find and remove the item
      state.waitingLists = state.waitingLists
        .map((waitlistEntry) => {
          // Filter the cartItems of each waitlist entry to remove the item
          waitlistEntry.cartItems = waitlistEntry.cartItems.filter(
            (item) => item.id !== itemId
          );

          // If the cartItems is empty, remove the waitlist entry by returning null
          return waitlistEntry.cartItems.length === 0 ? null : waitlistEntry;
        })
        .filter(Boolean); // Remove any null values (empty waitlist entries)

      // Optional: Recalculate totals if needed, or any other state update
      salesSlice.caseReducers.calculateTotals(state);
    },
    addToWaitlist: (state, action) => {
      // Create a new object with patientId, patientName, and cartItems (salesCart)
      const newWaitlistEntry = {
        patientId: action.payload.patientId,
        patientName: action.payload.patientName,
        cartItems: [...state.salesCart.cartItems], // Add a copy of the cartItems
      };

      // Add the entry to the waiting list
      state.waitingLists.push(newWaitlistEntry);

      // Clear the salesCart after adding to waitlist
      state.salesCart = {
        patientId: null,
        patientName: "",
        cartItems: [],
      };

      // Reset totals after clearing the cart
      state.summary = 0;
      state.vat = 0;
      state.discount = 0;
      state.total = 0;
    },
    setSalesCart: (state, action) => {
      // Set the salesCart to the clicked waitlist
      state.salesCart = action.payload;

      // Recalculate totals after setting salesCart
      salesSlice.caseReducers.calculateTotals(state);
    },
    clearSalesCart: (state) => {
      state.salesCart = {
        patientId: null,
        patientName: "",
        cartItems: [],
      };
    },
    setPrintingInvoice: (state, action) => {
      state.printingInvoice = action.payload;
    },
  },
});

export const {
  getSalesStateUpdate,
  addToSalesCart,
  removeFromSalesCart,
  removeItemFromSalesCart,
  addToWaitlist,
  clearSalesCart,
  setSalesCart,
  removeItemFromWaitlist,
  setPrintingInvoice,
} = salesSlice.actions;

export const selectSales = (state) => state.sales;

export default salesSlice.reducer;
