import React from "react";
import moment from "moment";
import { TableActions } from "../../Online Order/styled";
import CustomTable from "../../../../components/Table/CustomTable";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";

export const columns = [
  { label: "Product", field: "product" },
  { label: "Stock Qty", field: "stock_qty" },
  { label: "Expiry Date", field: "expiry_date" },
  { label: "Supplier", field: "supplier" },
  { label: "Status", field: "status" },
  { label: "Action", field: "action" },
];

const ExpiringIn6MonthsTable = () => {
  const dispatch = useDispatch();

  const rows = [
    {
      product: "Capsule",
      stock_qty: "6",
      expiry_date: moment().format("Do MMM YYYY"),
      supplier: "Hillz",
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/edit.svg"
            alt="edit"
            onClick={() => dispatch(setCurrentModal("edit_expiring_product"))}
          />
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete"
            onClick={() => dispatch(setCurrentModal("delete_expiring_product"))}
          />
        </TableActions>
      ),
      status: "Completed",
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Expiring in 6 Months"}
      titleColor={"#2B47FC"}
      showHeader={true}
    />
  );
};

export default ExpiringIn6MonthsTable;
