import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { TableActions } from "../Online Order/styled";
import moment from "moment";
import { useDispatch } from "react-redux";

export const columns = [
  { label: "Patients Name", field: "patient_name" },
  { label: "Upload Date", field: "upload_date" },
  { label: "Pharmacist", field: "pharmacist" },
  { label: "ID", field: "id" },
  { label: "Action", field: "action" },
];

const PrescriptionManagementTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const rows = [
    {
      patient_name: "Hilary",
      upload_date: moment().format("Do MMM YYYY"),
      pharmacist: "Hillz",
      id: "1435465",
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/eye.svg"
            alt="edit_icon"
            onClick={() =>
              dispatch(setCurrentModal("view_uploaded_prescription"))
            }
          />
          <img
            src="/assets/images/Generals/cancel.svg"
            alt="delete_icon"
            onClick={() =>
              dispatch(setCurrentModal("confirm_delete_uploaded_prescription"))
            }
          />
        </TableActions>
      ),
    },
    {
      patient_name: "Hilary",
      upload_date: moment().format("Do MMM YYYY"),
      pharmacist: "Hillz",
      id: "1435465",
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/eye.svg"
            alt="edit_icon"
            // onClick={() => dispatch(setCurrentModal("edit_Prescription"))}
          />
          <img
            src="/assets/images/Generals/cancel.svg"
            alt="delete_icon"
            // onClick={() => dispatch(setCurrentModal("delete_Prescription"))}
          />
        </TableActions>
      ),
    },
    {
      patient_name: "Hilary",
      upload_date: moment().format("Do MMM YYYY"),
      pharmacist: "Hillz",
      id: "1435465",
      action: (
        <TableActions>
          <img
            src="/assets/images/Generals/eye.svg"
            alt="edit_icon"
            // onClick={() => dispatch(setCurrentModal("edit_Prescription"))}
          />
          <img
            src="/assets/images/Generals/cancel.svg"
            alt="delete_icon"
            // onClick={() => dispatch(setCurrentModal("delete_Prescription"))}
          />
        </TableActions>
      ),
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Prescription Management"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      headerButton={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Upload Prescription +"}
          textColor={theme.white}
          handleClick={() => dispatch(setCurrentModal("upload_prescription"))}
        />
      }
      filter={<p>.</p>}
    />
  );
};

export default PrescriptionManagementTable;
