import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import moment from "moment";
import { TableActions } from "../Online Order/styled";

export const columns = [
  { label: "Product", field: "product" },
  { label: "Stock Qty", field: "stock_qty" },
  { label: "Expiry Date", field: "expiry_date" },
  { label: "Supplier", field: "supplier" },
  { label: "Status", field: "status" },
  { label: "Action", field: "action" },
];

const OutOfStockTable = () => {
  const rows = [
    {
      product: "Capsule",
      stock_qty: "6",
      expiry_date: moment().format("Do MMM YYYY"),
      supplier: "Hillz",
      action: (
        <TableActions>
          <img src="/assets/images/Generals/delete.svg" alt="delete" />
        </TableActions>
      ),
      status: "Completed",
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Out of Stock"}
          titleColor={"#C52112"}
          showHeader={true}
    />
  );
};

export default OutOfStockTable;
