import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingStores: false,
  stores: null,
  editingStore: false,
  deletingStore: false,
  addingStore: false,

  // store details
  customerID: "",
  storePoint: "",

  storeToEdit: null,

  // Pagination
  storespageSize: 20,
  storesPage: 1,
};

export const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    getStoreStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingStores
    setGettingStores: (state, action) => {
      state.gettingStores = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },

    // Reducer to update editingStore
    setEditingStore: (state, action) => {
      state.editingStore = action.payload;
    },

    // Reducer to update deletingStore
    setDeletingStore: (state, action) => {
      state.deletingStore = action.payload;
    },

    // Reducer to update addingStore
    setAddingStore: (state, action) => {
      state.addingStore = action.payload;
    },

    // Reducer to update customerID
    setCustomerID: (state, action) => {
      state.customerID = action.payload;
    },

    // Reducer to update storePoint
    setStorePoint: (state, action) => {
      state.storePoint = action.payload;
    },

    // Reducer to update storeToEdit
    setStoreToEdit: (state, action) => {
      state.storeToEdit = action.payload;
    },

    // Reducer to update storespageSize
    setStorespageSize: (state, action) => {
      state.storespageSize = action.payload;
    },

    // Reducer to update storesPage
    setStoresPage: (state, action) => {
      state.storesPage = action.payload;
    },
  },
});

export const {
  getStoreStateUpdate,
  setGettingStores,
  setEditingStore,
  setDeletingStore,
  setAddingStore,
  setCustomerID,
  setStorePoint,
  setStoreToEdit,
  setStorespageSize,
  setStoresPage,
  setStores,
} = storeSlice.actions;

export const selectStores = (state) => state.stores;

export default storeSlice.reducer;
