import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { media } from "../../Screens";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
  height: 78px;
  top: 327px;
  left: 667px;
  padding: 18px 13px 18px 13px;
  border-radius: 10px;
  border: 1px solid #0000000d;
  background-color: #f8f6f6;

  ${media.phone} {
    width: 228px;
  }
`;

const GridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

const NotificationIcon = styled.div`
  position: relative;
  width: 25px;
  height: 25px;

  .new {
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.bgThickRed};
  }

  img {
    width: 25px;
    height: 25px;
  }
`;

const NotificationText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.09px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.grey};
`;
const NotificationView = styled(Link)`
  font-size: 10px;
  font-weight: 400;
  line-height: 14.09px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.grey};
`;

const NotificationCard = ({ text, link }) => {
  return (
    <Container>
      <GridView>
        <NotificationIcon>
          <img
            src="/assets/images/Notification/notification_icon.svg"
            alt="notification icon"
          />
          <span className="new"></span>
        </NotificationIcon>
        <NotificationText>{text}</NotificationText>
        {link && <NotificationView to={link}>view</NotificationView>}
      </GridView>
    </Container>
  );
};

export default NotificationCard;
