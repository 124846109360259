import React, { useEffect } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { TableActions } from "../Online Order/styled";
import {
  selectEmployee,
  setEmployeesPage,
  setEmployeespageSize,
} from "../../../Redux/Slices/EmployeeSlice";
import { getEmployees } from "../../../Apis/EmployeeManagement";

export const columns = [
  { label: "Name", field: "name" },
  { label: "Email", field: "email" },
  // { label: "User Name", field: "user_name" },
  // { label: "Branch", field: "branch" },
  { label: "Role", field: "role" },
  { label: "Action", field: "action" },
];

const EmployeeManagementTable = () => {
  const { employees, gettingEmployees, employeesPage, employeesPageSize } =
    useSelector(selectEmployee);
  const theme = useTheme();
  const dispatch = useDispatch();

  const rows = employees?.items?.map((employee) => {
    return {
      name: employee?.lastName + " " + employee?.firstName,
      email: employee?.user?.email,
      user_name: "@boss.babe",
      branch: "Enugu",
      role: employee?.role?.name,
      action: (
        <TableActions>
          {/* <img
            src="/assets/images/Generals/edit.svg"
            alt="edit_icon"
            onClick={() => dispatch(setCurrentModal("edit_user"))}
          /> */}
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete_icon"
            onClick={() => dispatch(setCurrentModal("delete_user"))}
          />
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getEmployees(employeesPage, employeesPageSize);
  }, [employeesPage, employeesPageSize]);

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Employee Management"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      headerButton={
        <FilledButton
          bgColor={theme.green2}
          btnText={"Set Role +"}
          textColor={theme.white}
          handleClick={() => dispatch(setCurrentModal("create_new_role"))}
        />
      }
      filter={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Add User +"}
          textColor={theme.white}
          handleClick={() => dispatch(setCurrentModal("add_new_employee"))}
        />
      }
      loading={gettingEmployees}
      page={employeesPage}
      limit={employeesPageSize}
      handlePageChange={(value) => {
        dispatch(setEmployeesPage(value));
      }}
      handlePageLimitChange={(value) => {
        dispatch(setEmployeespageSize(value));
      }}
      totalPages={employees?.totalPages}
    />
  );
};

export default EmployeeManagementTable;
