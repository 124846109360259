import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  text-align: left;
  text-transform: capitalize;
`;

const Select = styled.select`
  width: 100%;
  height: 38px;
  padding: 6.72px 7.28px 9.89px;
  border-radius: 4px;
  border: 1.65px solid #d9d9d9;
  outline: none;
  background-color: white;
  font-size: 10px;
  font-weight: 500;
  line-height: 15.67px;
`;

const SelectOptions = ({
  placeholder,
  value,
  selected,
  handleSelect,
  options,
  label,
  name,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Select
        placeholder={placeholder}
        value={value}
        selected={selected}
        onChange={handleSelect}
        name={name}
      >
        <option>{placeholder}</option>
        {options?.map((option, index) => (
          <option value={option.value} key={index + 1}>
            {option.label}
          </option>
        ))}
      </Select>
    </Container>
  );
};

export default SelectOptions;
