import React from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  getSupplierStateUpdate,
  selectSupplier,
} from "../../../../Redux/Slices/SupplierSlice";
import { editSupplier } from "../../../../Apis/Suppliers";
import { validateEmail, validatePhoneNumber } from "../../../../helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const EditSupplierModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    supplierId,
    supplierToEdit,
    supplierName,
    supplierEmail,
    supplierPhone,
    supplierAddress,
    // supplierCompanyName,
    editingSuppliers,
  } = useSelector(selectSupplier);

  const handleCreate = () => {
    if (
      validateEmail(supplierEmail || supplierToEdit?.email) &&
      validatePhoneNumber(supplierPhone || supplierToEdit?.phoneNumber)
    ) {
      editSupplier(supplierId, {
        name: supplierName || supplierToEdit?.name,
        email: supplierEmail || supplierToEdit?.email,
        phoneNumber: supplierPhone || supplierToEdit?.phoneNumber,
        address: supplierAddress || supplierToEdit?.address,
        // company: supplierCompanyName || supplierToEdit?.company,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(getSupplierStateUpdate({ name, value }));
  };

  // useEffect(() => {
  //   setSupplierToEditDetails(supplierId);
  // }, [supplierId, supplierToEdit]);

  return (
    <CustomModal
      id="edit_supplier"
      content={
        <Container>
          <ModalTitle>Edit Supplier</ModalTitle>
          <TextInput
            placeholder={"Your name here"}
            label={"Name"}
            value={supplierName}
            handleChange={handleChange}
            name={"supplierName"}
          />
          <TextInput
            placeholder={"example@gmail.ccom"}
            label={"Email"}
            value={supplierEmail}
            handleChange={handleChange}
            name={"supplierEmail"}
          />
          <TextInput
            placeholder={"Phone number here"}
            label={"Phone Number"}
            value={supplierPhone}
            handleChange={handleChange}
            name={"supplierPhone"}
          />
          {/* <TextInput
            placeholder={"Company"}
            label={"Company"}
            value={supplierCompanyName}
            handleChange={handleChange}
            name={"supplierCompanyName"}
          /> */}
          <TextInput
            placeholder={"Address here"}
            label={"Address"}
            value={supplierAddress}
            handleChange={handleChange}
            name={"supplierAddress"}
          />
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              loading={editingSuppliers}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default EditSupplierModal;
