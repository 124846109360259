import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import {
  setCurrencies,
  setGettingCurrencies,
} from "../Redux/Slices/currencySlice";
import api from "./baseUri";

export const getCurrencies = async (page, pageSize) => {
  store.dispatch(setGettingCurrencies(true));
  await api
    .get(`v1/currencies?page=${page}&pageSize=${pageSize}`)
    .then((res) => {
      store.dispatch(setCurrencies(res.data)); // Adjust according to your response structure
      store.dispatch(setGettingCurrencies(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingCurrencies(false));
    });
};
