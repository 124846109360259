export const rolePermission = [
  { id: 1, label: "Dashboard", value: "1" },
  { id: 1, label: "Sales", value: "2" },
  { id: 1, label: "Customer", value: "3" },
  { id: 1, label: "Prescription", value: "4" },
  { id: 1, label: "Online Orders", value: "5" },
  { id: 1, label: "Market Place", value: "6" },
  { id: 1, label: "Inventory", value: "7" },
  { id: 1, label: "Analytics", value: "8" },
  { id: 1, label: "CustomerSupport", value: "9" },
  { id: 1, label: "Sales", value: "10" },
];
