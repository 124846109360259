import React, { useState } from "react";
import { NotificationContainer, NotificationTitle } from "./styles";
import ExpirationSummaryCards from "./ExpirationSummary";
import ExpiredTable from "./ExpirationTables.jsx/ExpiredTable";
import ExpiringIn1MonthTable from "./ExpirationTables.jsx/ExpiringIn1Month";
import ExpiringIn3MonthsTable from "./ExpirationTables.jsx/ExpiringIn3Months";
import ExpiringIn6MonthsTable from "./ExpirationTables.jsx/ExpiringIn6MonthsTable";
import EditExpiringProductModal from "./modal/EditExpiringProductModal";
import SuccessMessageModal from "./../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";

const Expired = () => {
  const [currentTable, setCurrentTable] = useState(1);

  return (
    <NotificationContainer>
      <NotificationTitle>Expiration Notification</NotificationTitle>
      <ExpirationSummaryCards setCurrentTable={setCurrentTable} />
      {currentTable === 1 && <ExpiredTable />}
      {currentTable === 2 && <ExpiringIn1MonthTable />}
      {currentTable === 3 && <ExpiringIn3MonthsTable />}
      {currentTable === 4 && <ExpiringIn6MonthsTable />}
      <EditExpiringProductModal />
      <SuccessMessageModal
        id={"edit_expiring_product_success"}
        message={"Done"}
      />
      <CanceledMessageModal
        id={"delete_expiring_product"}
        message={"Deleted Successfully"}
      />
    </NotificationContainer>
  );
};

export default Expired;
