import React, { useEffect } from "react";
import ProductTable from "./ProductTable";
import AddProductModal from "./modals/AddProductModal";
import EditProductModal from "./modals/EditProductModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { getCategories } from "../../../Apis/Category";
import { selectCategory } from "../../../Redux/Slices/CategorySlice";

const ProductManagement = () => {
  const dispatch = useDispatch();
  const { categoriesPage, categoriesPageSize } = useSelector(selectCategory);

  useEffect(() => {
    getCategories(categoriesPage, categoriesPageSize);
  }, [categoriesPage, categoriesPageSize]);

  return (
    <>
      <ProductTable />
      <AddProductModal />
      <EditProductModal />
      <SuccessMessageModal id={"add_new_product_success"} message={"Done"} />
      <SuccessMessageModal id={"edit_product_success"} message={"Done"} />
      <CanceledMessageModal
        id={"delete_product_success"}
        message="Deleted Successfully"
      />
      <ConfirmModal
        message="By deleting this product all content and data on this product will be deleted confirm you want to delete this branch."
        confirmOkText={"Delete"}
        id={"delete_product"}
        handleOkClick={() =>
          dispatch(setCurrentModal("delete_product_success"))
        }
      />
    </>
  );
};

export default ProductManagement;
