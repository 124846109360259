import React, { useEffect } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { TableActions } from "../Online Order/styled";
import { getBranches } from "./../../../Apis/BranchManagement";
import {
  selectBranch,
  setBranchesPage,
  setBranchespageSize,
} from "../../../Redux/Slices/BranchSlice";

export const columns = [
  { label: "Name", field: "name" },
  { label: "Manager", field: "manager" },
  { label: "Phone Number", field: "phone_number" },
  { label: "Location", field: "location" },
  { label: "Action", field: "action" },
];

const BranchManagementTable = () => {
  const { branchesPage, branchesPageSize, branches, gettingBranches } =
    useSelector(selectBranch);
  const theme = useTheme();
  const dispatch = useDispatch();

  const rows = branches?.items?.map((branch) => {
    return {
      name: branch.name,
      manager: branch.manager,
      phone_number: branch.phoneNumber,
      location: branch.location,
      action: (
        <TableActions>
          {/* <img
            src="/assets/images/Generals/edit.svg"
            alt="edit_icon"
            onClick={() => dispatch(setCurrentModal("edit_branch"))}
          /> */}
          <img
            src="/assets/images/Generals/delete.svg"
            alt="delete_icon"
            onClick={() => dispatch(setCurrentModal("delete_branch"))}
          />
        </TableActions>
      ),
    };
  });

  const data = {
    columns,
    rows,
  };

  useEffect(() => {
    getBranches(branchesPage, branchesPageSize);
  }, [branchesPage, branchesPageSize]);

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Branch Management"}
      showFilter={true}
      showHeader={true}
      showPagination={true}
      filter={
        <FilledButton
          bgColor={theme.purple}
          btnText={"Add Branch +"}
          textColor={theme.white}
          handleClick={() => dispatch(setCurrentModal("add_new_branch"))}
        />
      }
      page={branchesPage}
      limit={branchesPageSize}
      handlePageChange={(value) => {
        dispatch(setBranchesPage(value));
      }}
      handlePageLimitChange={(value) => {
        dispatch(setBranchespageSize(value));
      }}
      totalPages={branches?.totalPages}
      loading={gettingBranches}
    />
  );
};

export default BranchManagementTable;
