import React from "react";
import ProductCategoryTable from "./ProductCategoryTable";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import AddNewCategoryModal from "./modals/AddNewCategoryModal";
import EditCategoryModal from "./modals/EditCategoryModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";

const ViewCategories = () => {
  return (
    <>
      <ProductCategoryTable />
      <SuccessMessageModal id={"add_category_success"} message={"Done"} />
      <SuccessMessageModal id={"edit_category_success"} message={"Done"} />
      <CanceledMessageModal
        id={"delete_category_success"}
        message={"Deleted Successfully"}
      />
      <AddNewCategoryModal />
      <EditCategoryModal />
    </>
  );
};

export default ViewCategories;
