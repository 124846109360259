import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalPayment = () => {
  const [amount] = useState("10.00"); // Set the default amount for payment
  const [currency] = useState("USD"); // You can change this to another currency (e.g. 'EUR')

  // PayPal success handler
  const handleApprove = (orderId) => {
    console.log("Order approved:", orderId);
    alert("Payment successful!");
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": "YOUR_PAYPAL_CLIENT_ID", // Replace with your actual PayPal client ID
        currency: currency,
      }}
    >
      <div>
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount, // PayPal expects the amount as a string
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              handleApprove(details.id);
            });
          }}
          onError={(err) => {
            console.error("PayPal error:", err);
            alert("An error occurred with the PayPal payment.");
          }}
        />
      </div>
    </PayPalScriptProvider>
  );
};

export default PayPalPayment;
