import React from "react";
import styled, { useTheme } from "styled-components";
import CustomModal from "./CustomModal";
import FilledButton from "../Buttons/FilledButton";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../Redux/Slices/navSlice";

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 30px;
  flex: 1;
`;

const Text = styled.p`
  width: 342px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.44px;
  letter-spacing: 0.5px;
  text-align: left;
  border: 1px solid #0000000d;
  padding: 25px;
  color: ${({ theme }) => theme.grey};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #0000000d;
  padding: 25px;
  color: ${({ theme }) => theme.grey};
`;

const ConfirmModal = ({ message, id, confirmOkText, handleOkClick, deleting }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <CustomModal
      id={id}
      content={
        <ContentContainer>
          <Text>{message}</Text>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.green2}
              btnText={confirmOkText}
              handleClick={handleOkClick}
              textColor={theme.white}
              loading={deleting}
            />
          </ButtonContainer>
        </ContentContainer>
      }
    />
  );
};

export default ConfirmModal;
