import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 1px 2px 4.7px 0px #00000012;
`;

const PropValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: ${({ theme }) => theme.grey2};
`;

const PropValue = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 14.09px;
  text-align: left;
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const WhiteBgCard = ({ prop, value, icon }) => {
  return (
    <CardContainer>
      <PropValueContainer>
        <PropValue>{prop}</PropValue>
        <PropValue>{value}</PropValue>
      </PropValueContainer>
      <IconContainer>{icon}</IconContainer>
    </CardContainer>
  );
};

export default WhiteBgCard;
