import { toast } from "react-toastify";
import {
  setAddingCategory,
  setCategories,
  setGettingCategories,
} from "../Redux/Slices/CategorySlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";

export const getCategories = async (page, pageSize) => {
  store.dispatch(setGettingCategories(true));
  await api
    .get(`v1/product-categories?page=${page}&pageSize=${pageSize}`)
    .then((res) => {
      store.dispatch(setCategories(res.data)); // Adjust according to your response structure
      store.dispatch(setGettingCategories(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingCategories(false));
    });
};

export const addCategory = async (categoryData) => {
  store.dispatch(setAddingCategory(true));
  await api
    .post("v1/categories", { name: categoryData }) // Adjust payload as needed
    .then(async (res) => {
      store.dispatch(setAddingCategory(false));
      toast.success("Category added successfully!");
      await getCategories(); // Refetch categories
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingCategory(false));
    });
};

export const editCategory = async (categoryId, categoryData) => {
  store.dispatch(setAddingCategory(true)); // or use a separate `setEditingCategory` action if preferred
  await api
    .put(`v1/categories/${categoryId}`, { name: categoryData }) // Adjust payload as needed
    .then(async (res) => {
      store.dispatch(setAddingCategory(false));
      toast.success("Category updated successfully!");
      await getCategories(); // Refetch categories or update state accordingly
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingCategory(false));
    });
};
