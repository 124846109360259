import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 181.89px;
  box-shadow: 0px 4.22px 4.22px 0px #00000005;
  border-radius: 15.83px;
  background-color: ${({ theme }) => theme.white};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const MedicineImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 119.81px;
  height: 125.85px;
  border-radius: 15.83px;
  background: ${({ theme }) => theme.pink};
`;

const PriceTag = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;

  .naira {
    font-size: 7px;
    color: ${({ theme }) => theme.blue};
  }

  .price {
    font-size: 17px;
    font-weight: 500;
    line-height: 19.96px;
    color: ${({ theme }) => theme.black};
  }

  .per_card {
    font-size: 7px;
    font-weight: 400;
    line-height: 8.22px;
    color: #000000a3;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const DrugName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  text-align: left;
  color: ${({ theme }) => theme.black};
`;

const DrugDescription = styled.div`
  font-size: 10px;
  font-weight: 400;
  /* line-height: 8.22px; */
  text-align: left;
  color: ${({ theme }) => theme.black};
  width: 80%;
`;

const StockAvailable = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 50px;
  width: 100%;

  .Desc {
    display: block;
    color: ${({ theme }) => theme.black};
    text-align: left;

    .title {
      font-size: 8px;
      font-weight: 400;
      line-height: 7.04px;
    }

    .value {
      font-size: 10px;
      font-weight: 500;
      line-height: 9.39px;
    }
  }
`;

const DrugDetailsCard = ({
  price,
  netWeight,
  stock,
  image,
  drugName,
  drugDesc,
}) => {
  return (
    <Container>
      <LeftContent>
        <MedicineImageContainer>{image}</MedicineImageContainer>
      </LeftContent>
      <RightContent>
        <DrugName>{drugName}</DrugName>
        <DrugDescription>{drugDesc}</DrugDescription>
        <StockAvailable>
          <div className="Desc">
            <p className="title">Net Weight</p>
            <p className="value">{netWeight}</p>
          </div>
          <div className="Desc">
            <p className="title">Stock</p>
            <p className="value">{stock} Available</p>
          </div>
        </StockAvailable>
        <PriceTag>
          <span className="naira">₦</span>
          <span className="price">{price}</span>
          <span className="per_card">/ card</span>
        </PriceTag>
      </RightContent>
    </Container>
  );
};

export default DrugDetailsCard;
