import React from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  addToWaitlist,
  getSalesStateUpdate,
  selectSales,
} from "../../../../Redux/Slices/SalesSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const MinimizeInvoiceModal = () => {
  const { patientName } = useSelector(selectSales);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleMinimize = () => {
    dispatch(addToWaitlist({ patientId: "234256", patientName }));
    dispatch(setCurrentModal(""));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getSalesStateUpdate({ name, value }));
  };

  return (
    <CustomModal
      id="minimize_patient"
      content={
        <Container>
          <ModalTitle>Patient Details</ModalTitle>
          <TextInput
            placeholder={"Enter patient name"}
            label={"Patient Name"}
            value={patientName}
            name={"patientName"}
            handleChange={handleChange}
          />
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleMinimize}
              textColor={theme.white}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default MinimizeInvoiceModal;
