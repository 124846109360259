import React from "react";
import AuthRouteContainer from "../../AuthRouteContainer";
import { AuthBannerTitle } from "../style";
import FormCard from "../../../components/Cards/FormCard";
import TextInput from "../../../components/Inputs/TextInput";
import FIleInput from "../../../components/Inputs/FileInput";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../Redux/Slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  validateEmail,
  validatePass,
  validatePhoneNumber,
} from "../../../helpers";

const PharmacySetup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    companyName,
    companyPhoneNumber,
    companyEmail,
    companyLocation,
    companyLogo,
    pharmacistFirstName,
    pharmacistLastName,
    pharmacistPassword,
    pharmacistConfirmPassword,
  } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!companyName) {
      toast.error("Company's name is required");
    } else if (!companyPhoneNumber) {
      toast.error("Company's phone is required");
    } else if (!companyEmail) {
      toast.error("Company's email is required");
    } else if (!companyLocation) {
      toast.error("Company's location is required");
    } else if (!companyLogo) {
      toast.error("Company's logo is required");
    } else if (!pharmacistFirstName) {
      toast.error("first name is required");
    } else if (!pharmacistLastName) {
      toast.error("first last is required");
    } else if (!pharmacistPassword) {
      toast.error("password is required");
    } else if (pharmacistPassword !== pharmacistConfirmPassword) {
      toast.error("password does not match");
    } else {
      if (
        validateEmail(companyEmail) &&
        validatePhoneNumber(companyPhoneNumber) &&
        validatePass(pharmacistPassword)
      ) {
        navigate("/sign-up/activate-product");
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  const handleLogoSelection = (e) => {
    const file = e.target.files[0];
    dispatch(getAuthFormDetails({ name: e.target.name, value: file }));
  };

  return (
    <AuthRouteContainer
      leftContent={
        <>
          <img src="/assets/images/Auth/zamda_logo.svg" alt="logo" />
          <AuthBannerTitle>POS (Point of Sales)</AuthBannerTitle>
          <img
            src="/assets/images/Auth/auth_banner.svg"
            alt="logo"
            width="80%"
          />
        </>
      }
      rightContent={
        <FormCard title={"Pharmacy Setup"}>
          <TextInput
            label={"Company Name"}
            placeholder={"Enter company name"}
            type={"text"}
            value={companyName}
            name={"companyName"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Phone Number"}
            placeholder={"Enter phone number"}
            type={"tel"}
            value={companyPhoneNumber}
            name={"companyPhoneNumber"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Email"}
            placeholder={"example@email.com "}
            type={"email"}
            value={companyEmail}
            name={"companyEmail"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Location"}
            placeholder={"Enter company location"}
            type={"text"}
            value={companyLocation}
            name={"companyLocation"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"First Name"}
            placeholder={"Enter first name"}
            type={"text"}
            value={pharmacistFirstName}
            name={"pharmacistFirstName"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Last Name"}
            placeholder={"Enter last name"}
            type={"text"}
            value={pharmacistLastName}
            name={"pharmacistLastName"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Password"}
            type={"password"}
            value={pharmacistPassword}
            name={"pharmacistPassword"}
            required={true}
            handleChange={handleChange}
          />
          <TextInput
            label={"Password"}
            type={"password"}
            value={pharmacistConfirmPassword}
            name={"pharmacistConfirmPassword"}
            required={true}
            handleChange={handleChange}
          />
          <FIleInput
            label={"Logo"}
            type={"tel"}
            // value={companyLogo}
            name={"companyLogo"}
            handleChange={handleLogoSelection}
          />
          <FilledButton
            bgColor={theme.purple}
            btnText={"Submit & Continue"}
            handleClick={handleSubmit}
            textColor={theme.white}
            customStyle={{ width: "100%" }}
            type={"submit"}
          />
        </FormCard>
      }
    />
  );
};

export default PharmacySetup;
