import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import MultiSelect from "../../../../components/Inputs/MultiSelect";
import {
  getRoleStateUpdate,
  selectRole,
} from "../../../../Redux/Slices/roleSlice";
import { addRoles } from "../../../../Apis/role";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const CreateRoleModal = () => {
  const { authorities, name, permissions, addingRoles } =
    useSelector(selectRole);
  const [authorityList, setAuthorityList] = useState();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCreate = () => {
    addRoles({ name, authorities: permissions });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getRoleStateUpdate({ name, value }));
  };

  const handleSelection = (name, value) => {
    const filteredValue = value?.map((x) => {
      return x.value;
    });
    dispatch(getRoleStateUpdate({ name, value: filteredValue }));
  };

  useEffect(() => {
    const arrangedAuthorities = authorities?.map((item, index) => {
      return {
        id: index + 1,
        label: item,
        value: item,
      };
    });
    setAuthorityList(arrangedAuthorities);
  }, [authorities]);

  return (
    <CustomModal
      id="create_new_role"
      content={
        <Container>
          <ModalTitle>Create User Role</ModalTitle>
          <TextInput
            placeholder={"Enter User Position"}
            label={"Role Title"}
            name="name"
            value={name}
            handleChange={handleChange}
            required
          />
          <MultiSelect
            options={authorityList}
            placeholder={"select permissions"}
            handleSelection={(e) => handleSelection("permissions", e)}
          />
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Save"}
              handleClick={handleCreate}
              textColor={theme.white}
              disabled={!name || permissions.length === 0 || addingRoles}
              loading={addingRoles}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default CreateRoleModal;
