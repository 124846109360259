import React from "react";
import styled from "styled-components";
import Loader from "../Loaders/PulseLoader";

const Button = styled.button`
  min-width: 116px;
  height: 37px;
  padding: 10.72px;
  border-radius: 6px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 11.54px;

  &:disabled {
    opacity: 0.5;
  }
`;

const FilledButton = ({
  handleClick,
  btnText,
  bgColor,
  textColor,
  disabled,
  loading,
  customStyle,
  type,
}) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick(e);
      }}
      bgColor={bgColor}
      textColor={textColor}
      disabled={disabled || loading}
      style={customStyle}
      type={type}
    >
      {loading ? <Loader size={10} /> : btnText}
    </Button>
  );
};

export default FilledButton;
