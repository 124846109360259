import React, { useEffect } from "react";
import { MainBody, MainBodyContainer, RouteContainer } from "./styles";
import Header from "../../components/Nav/Header";
import Sidebar from "../../components/Nav/Sidebar";
import Home from "../Private/Home";
import Sales from "../Private/Sales";
import CustomerManagement from "../Private/Customer Management";
import PrescriptionManagement from "../Private/Prescription Management";
import OnlineOrder from "../Private/Online Order";
import MarketPlace from "../Private/Market Place";
import Inventory from "../Private/Inventory";
import Analytics from "../Private/Analytics";
import EmployeeManagement from "../Private/Employee Management";
import Notification from "../Private/Notification";
import { Route, Routes } from "react-router-dom";
import CustomerSupport from "../Private/Customer Support";
import HealthManagement from "../Private/Health Management";
import BranchManagement from "../Private/Branch Management";
import UserProfile from "../Private/UserProfile";
import NewOrders from "../Private/Notification/NewORders";
import NewSupportMessage from "../Private/Notification/Support";
import OutofStock from "../Private/Notification/OutofStock";
import DetailedNewSupportNotification from "../Private/Notification/DetailedNewSupportNotification";
import Expired from "../Private/Notification/Expired";
import Expiring from "../Private/Notification/Expiring";
import BuyConfirmation from "../Private/Market Place/BuyConfirmation";
import SubimtToAdmin from "../Private/Market Place/SubimtToAdmin";
import ProductManagement from "../Private/ProductManagement";
import SupplierManagement from "../Private/SupplierManagment";
import ViewCategories from "../Private/ProductManagement/ViewCategories";
import Stores from "../Private/Stores";
import Orders from "../Private/Orders";
import { getAdminRoles, getUserProfile } from "../../Apis/Auth";
import { getSuppliers } from "../../Apis/Suppliers";
import { getCategories } from "../../Apis/Category";
import { getCurrencies } from "../../Apis/currency";
import { getAuthorities, getRoles } from "../../Apis/role";
import { getBranches } from "../../Apis/BranchManagement";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/Slices/userSlice";

const PrivatePages = () => {
  const { userProfile } = useSelector(selectUser);

  useEffect(() => {
    getUserProfile();
    getAdminRoles();
    getAuthorities();
    getRoles();
  }, []);

  useEffect(() => {
    if (userProfile?.pharmacy) {
      getSuppliers(1, 30);
      getCategories(1, 30);
      getCurrencies(1, 30);
      getBranches(1, 30);
    }
  }, [userProfile]);

  return (
    <RouteContainer>
      <MainBodyContainer>
        <Sidebar />
        <MainBody>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sales" element={<Sales />} />
            <Route
              path="/customer-management"
              element={<CustomerManagement />}
            />
            <Route
              path="/prescription-management"
              element={<PrescriptionManagement />}
            />
            <Route path="/online-order" element={<OnlineOrder />} />
            <Route path="/marketplace" element={<MarketPlace />} />
            <Route path="/marketplace/buy" element={<BuyConfirmation />} />
            <Route
              path="/marketplace/submit-request"
              element={<SubimtToAdmin />}
            />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route
              path="/employee-management"
              element={<EmployeeManagement />}
            />
            <Route path="/branch-management" element={<BranchManagement />} />
            <Route path="/health-management" element={<HealthManagement />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/product-management" element={<ProductManagement />} />
            <Route
              path="/product-management/categories-management"
              element={<ViewCategories />}
            />
            <Route
              path="/supplier-management"
              element={<SupplierManagement />}
            />
            <Route path="/customer-support" element={<CustomerSupport />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/notification/new-orders" element={<NewOrders />} />
            <Route
              path="/notification/support-messages"
              element={<NewSupportMessage />}
            />
            <Route
              path="/notification/detailed-support-message"
              element={<DetailedNewSupportNotification />}
            />
            <Route path="/notification/out-of-stock" element={<OutofStock />} />
            <Route path="/notification/expired" element={<Expired />} />
            <Route path="/notification/expiring" element={<Expiring />} />
            <Route path="/profile" element={<UserProfile />} />
          </Routes>
        </MainBody>
      </MainBodyContainer>
    </RouteContainer>
  );
};

export default PrivatePages;
