import styled from "styled-components";
import { media } from "../../../Screens";

export const SalesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow-x: hidden;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: ${(props) => props.flex};
  transition: all 0.5s ease-in-out;
`;

export const RightContainer = styled.div`
  flex: ${(props) => props.flex};
  display: ${(props) => props.display};
  transition: all 0.5s ease-in-out;
  height: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  width: 100%;

  a {
    font-size: 13px;
    font-weight: 300;
    line-height: 11.74px;
    text-align: left;
    color: ${({ theme }) => theme.blue};
    text-decoration: underline;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 23.48px;
  text-align: left;
`;

export const WaitingListCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;

  ${media.phone} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const MedicinesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  height: 100%;
  padding: 10px;

  ${media.phone} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;

export const SalesInvoiceContainer = styled.div`
  width: 100%;
  min-height: 666px;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  box-shadow: -4px 4px 4px 0px #00000008;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  gap: 30px;
`;

export const SalesInvoiceHeaderContainer = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 20px;
    font-weight: 300;
    line-height: 23.48px;
    text-align: left;
  }

  .id {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.09px;
    color: ${({ theme }) => theme.black};

    sup {
      font-size: 8px;
      font-weight: 400;
      line-height: 9.75px;
      color: ${({ theme }) => theme.purple};
    }
  }
`;

export const DetailsInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .title {
    font-size: 12px;
    font-weight: 300;
    line-height: 14.09px;
  }

  .total_cart_items {
    font-size: 12px;
    font-weight: 300;
    line-height: 14.09px;
    color: ${({ theme }) => theme.purple};
  }
`;

export const DetailsTable = styled.table`
  border: none;
  border-collapse: collapse;
  background-color: #d9d9d936;
  border-radius: 14px;
  width: 100%;
  max-height: 111px;
  overflow-y: auto;
`;

export const DetailsTableRow = styled.tr`
  border: none;
`;

export const DetailsTableHead = styled.th`
  font-size: 6px;
  font-weight: 300;
  line-height: 9.04px;
  padding: 5px;
  text-align: left;
`;

export const DetailsTableData = styled.td`
  font-size: 8px;
  font-weight: 500;
  line-height: 12.39px;
  padding: 5px;
  text-align: left;
`;

export const CartContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 300px;
  overflow-y: auto;
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ItemsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  align-items: flex-end;

  .cancel_button {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.pink};
  width: 90.81px;
  height: 15.62px;
  border-radius: 15.83px;
  padding-left: 10px;
  padding-right: 10px;

  .minus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
    text-align: center;
    color: ${({ theme }) => theme.blue};
    cursor: pointer;
  }

  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.blue};
    border-radius: 50%;
    color: ${({ theme }) => theme.white};
    text-align: center;
    cursor: pointer;
  }

  .count {
    font-size: 10.55px;
    font-weight: 400;
    line-height: 12.39px;
    text-align: left;
    color: #000000a3;
  }
`;

export const ItemsLeftContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  gap: 15px;

  img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68.79px;
    height: 72.26px;
    border-radius: 9.09px;
    background: ${({ theme }) => theme.pink};
  }

  .drug_details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .drug_name {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.44px;
    }

    .naira {
      font-size: 7px;
      color: ${({ theme }) => theme.blue};
    }

    .price {
      font-size: 17px;
      font-weight: 500;
      line-height: 19.96px;
      color: ${({ theme }) => theme.black};
    }

    .per_card {
      font-size: 7px;
      font-weight: 400;
      line-height: 8.22px;
      color: #000000a3;
    }
  }
`;

export const SalesInvoiceFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const FooterItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .summary {
    font-size: 16px;
    font-weight: 500;
    line-height: 16.44px;
  }

  .title {
    font-size: 12px;
    font-weight: 400;
    line-height: 9.39px;
  }

  .value {
    font-size: 12px;
    font-weight: 500;
    line-height: 9.39px;
  }

  .naira {
    font-size: 12px;
    color: ${({ theme }) => theme.blue};
  }
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
