import { toast } from "react-toastify";
import {
  setAddingProduct,
  setDeletingProduct,
  setGettingProducts,
  setProducts,
  setSearchedProducts,
  setSearchingProducts,
  setSelectedProductToEdit,
} from "../Redux/Slices/ProductSlice";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import { setCurrentModal } from "../Redux/Slices/navSlice";

export const addProduct = async (productDetails) => {
  store.dispatch(setAddingProduct(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .post(`v1/pharmacies/${pharmacy?.id}/products`, productDetails, {
      headers: {
        "Content-Type": "multipart/form-data", // Let Axios handle the content-type for file uploads
      },
    })
    .then((res) => {
      const { productPage, productPageSize } = store.getState().products;
      toast.success(res.data.message);
      store.dispatch(setAddingProduct(false));
      getProducts(productPage, productPageSize);
      store.dispatch(setCurrentModal("add_new_product_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingProduct(false));
    });
};

export const editProduct = async (productDetails) => {
  store.dispatch(setAddingProduct(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .patch(`v1/pharmacies/${pharmacy?.id}/products`, productDetails, {
      headers: {
        "Content-Type": "multipart/form-data", // Let Axios handle the content-type for file uploads
      },
    })
    .then((res) => {
      const { productPage, productPageSize } = store.getState().products;
      toast.success(res.data.message);
      store.dispatch(setAddingProduct(false));
      getProducts(productPage, productPageSize);
      store.dispatch(setCurrentModal("edit_product_success"));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setAddingProduct(false));
    });
};

export const getProducts = async (page, pageSize) => {
  store.dispatch(setGettingProducts(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .get(
      `v1/pharmacies/${pharmacy?.id}/products?page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      store.dispatch(setProducts(res.data)); // Assuming res.data.products holds the product data
      store.dispatch(setGettingProducts(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setGettingProducts(false));
    });
};

export const searchProducts = async (page) => {
  store.dispatch(setSearchingProducts(true));
  await api
    .get(`v1/products?page=${page}`)
    .then((res) => {
      store.dispatch(setSearchedProducts(res.data)); // Assuming res.data.products holds the product data
      store.dispatch(setSearchingProducts(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setSearchingProducts(false));
    });
};

export const deleteProduct = async (productId) => {
  store.dispatch(setDeletingProduct(true));
  const { pharmacy } = store?.getState()?.user?.userProfile;

  await api
    .delete(`v1/pharmacies/${pharmacy.id}/products/${productId}`)
    .then((res) => {
      toast.success(res.data.message);
      store.dispatch(setDeletingProduct(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      store.dispatch(setDeletingProduct(false));
    });
};

export const setProductToEditDetails = (product) => {
  store.dispatch(setSelectedProductToEdit(product));
};
