import React, { useState } from "react";
import { PaystackButton } from "react-paystack";

const PaystackPayment = () => {
  const publicKey = "your-paystack-public-key"; // Replace with your actual Paystack public key
  const [email] = useState("ekwealorhillary@gmail.com");
  const [amount] = useState(10000); // Amount in Naira (note: Paystack processes amount in Kobo)

  const handleSuccess = (reference) => {
    // Handle successful payment here
    console.log("Payment successful. Reference:", reference);
  };

  const handleClose = () => {
    // Handle when the user closes the payment modal
    console.log("Payment closed.");
  };

  const componentProps = {
    email,
    amount: amount * 100, // Paystack expects the amount to be in kobo
    publicKey,
    text: "Pay Now",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  return (
    <div>
      <PaystackButton {...componentProps} />
    </div>
  );
};

export default PaystackPayment;
