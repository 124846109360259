import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  getSupplierStateUpdate,
  selectSupplier,
} from "../../../../Redux/Slices/SupplierSlice";
import { addSupplier } from "../../../../Apis/Suppliers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const AddNewSupplierModal = () => {
  const {
    supplierName,
    supplierEmail,
    supplierPhone,
    supplierAddress,
    addingSuppliers,
  } = useSelector(selectSupplier);
  const [error, setError] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCreate = () => {
    if (!supplierName) {
      setError("Supplier name is required");
    } else if (!supplierEmail) {
      setError("Supplier email is required");
    } else if (!supplierPhone) {
      setError("Supplier phone is required");
    } else if (!supplierAddress) {
      setError("Supplier Address is required");
    } else {
      addSupplier({
        name: supplierName,
        email: supplierEmail,
        phoneNumber: supplierPhone,
        address: supplierAddress,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(getSupplierStateUpdate({ name, value }));
  };

  return (
    <CustomModal
      id="add_new_supplier"
      content={
        <Container>
          <ModalTitle>Add New Supplier</ModalTitle>
          <TextInput
            placeholder={"Your name here"}
            label={"Name"}
            name={"supplierName"}
            value={supplierName}
            handleChange={handleChange}
          />
          <TextInput
            placeholder={"example@gmail.ccom"}
            label={"Email"}
            name={"supplierEmail"}
            value={supplierEmail}
            handleChange={handleChange}
          />
          <TextInput
            placeholder={"Phone number here"}
            label={"Phone Number"}
            name={"supplierPhone"}
            value={supplierPhone}
            handleChange={handleChange}
          />
          {/* <TextInput
            placeholder={"Company"}
            label={"Company"}
            name={"supplierCompanyName"}
            value={supplierCompanyName}
            handleChange={handleChange}
          /> */}
          <TextInput
            placeholder={"Address here"}
            label={"Address"}
            name={"supplierAddress"}
            value={supplierAddress}
            handleChange={handleChange}
          />
          <div style={{ color: theme.red }}>{error}</div>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              loading={addingSuppliers}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default AddNewSupplierModal;
