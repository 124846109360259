import React from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import {
  getCustomerStateUpdate,
  selectCustomer,
} from "../../../../Redux/Slices/CustomerSlice";
import { validateEmail, validatePhoneNumber } from "../../../../helpers";
import { editCustomer } from "../../../../Apis/CustomerManagement";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const EditCustomerModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    customerName,
    customerEmail,
    customerAddress,
    customerPhone,
    editingCustomers,
    customerToEdit,
  } = useSelector(selectCustomer);

  const handleCreate = () => {
    if (validatePhoneNumber(customerPhone) && validateEmail(customerEmail)) {
      editCustomer(customerToEdit?.id, {
        name: customerName,
        // email: customerEmail,
        address: customerAddress,
        phoneNumber: customerPhone,
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getCustomerStateUpdate({ name, value }));
  };

  return (
    <CustomModal
      id="edit_customer"
      content={
        <Container>
          <ModalTitle>Add New Customer</ModalTitle>
          <TextInput
            placeholder={"Your name here"}
            label={"Name"}
            name={"customerName"}
            handleChange={handleChange}
            value={customerName}
          />
          {/* <TextInput
            placeholder={"example@gmail.com"}
            label={"Email"}
            name={"customerEmail"}
            handleChange={handleChange}
            value={customerEmail}
          /> */}
          <TextInput
            placeholder={"Phone number here"}
            label={"Phone Number"}
            name={"customerPhone"}
            handleChange={handleChange}
            value={customerPhone}
          />
          <TextInput
            placeholder={"Address here"}
            label={"Address"}
            name={"customerAddress"}
            handleChange={handleChange}
            value={customerAddress}
          />
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              disabled={
                editingCustomers ||
                !customerAddress ||
                !customerEmail ||
                !customerName ||
                !customerPhone
              }
              loading={editingCustomers}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default EditCustomerModal;
