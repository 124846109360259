import React from "react";
import styled from "styled-components";
import Loader from "../Loaders/PulseLoader";

const Button = styled.button`
  /* min-width: 116px; */
  width: auto;
  height: 23px;
  padding: 4.22px 12.66px 4.22px 12.66px;
  border-radius: 50px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 11.54px;
`;

const RoundedButton = ({
  handleClick,
  btnText,
  bgColor,
  textColor,
  disabled,
  loading,
}) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick();
      }}
      bgColor={bgColor}
      textColor={textColor}
      disabled={disabled || loading}
    >
      {loading ? <Loader /> : btnText}
    </Button>
  );
};

export default RoundedButton;
