import React from "react";
import { Date, OrdersSummaryContainer, SingleSummary } from "./style";

const OrdersSummary = () => {
  return (
    <OrdersSummaryContainer>
      <Date>
        <img src="/assets/images/Generals/calender.svg" alt="calender" />
        <span>Today</span>
      </Date>
      <SingleSummary>
        <div className="prop">Total Orders</div>
        <div className="value">45</div>
      </SingleSummary>
      <SingleSummary>
        <div className="prop">Ordered Items Over time</div>
        <div className="value">357</div>
      </SingleSummary>
      <SingleSummary>
        <div className="prop">Returns</div>
        <div className="value">6</div>
      </SingleSummary>
      <SingleSummary>
        <div className="prop">Fulfilled Orders over time</div>
        <div className="value">351</div>
      </SingleSummary>
      <SingleSummary>
        <div className="prop">Delivered orders over time</div>
        <div className="value">347</div>
      </SingleSummary>
    </OrdersSummaryContainer>
  );
};

export default OrdersSummary;
