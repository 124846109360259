import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import SelectOptions from "../../../../components/Inputs/SelectOptions";
import { selectRole } from "../../../../Redux/Slices/roleSlice";
import {
  mapToLabelValueArray,
  validateEmail,
  validatePass,
} from "../../../../helpers";
import {
  getEmployeeStateUpdate,
  selectEmployee,
} from "../../../../Redux/Slices/EmployeeSlice";
import { addEmployee } from "../../../../Apis/EmployeeManagement";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: center;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
`;
const CreateUserModal = () => {
  const { roles } = useSelector(selectRole);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [roleItems, setRoleItems] = useState();
  const {
    employeeFirstName,
    employeeLastName,
    employeeEmail,
    employeeRole,
    employeePassword,
    addingEmployees,
  } = useSelector(selectEmployee);

  const handleCreate = (e) => {
    e.preventDefault();
    if (validateEmail(employeeEmail) && validatePass(employeePassword)) {
      addEmployee({
        firstName: employeeFirstName,
        lastName: employeeLastName,
        email: employeeEmail,
        roleId: employeeRole,
        password: employeePassword,
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getEmployeeStateUpdate({ name, value }));
  };

  useEffect(() => {
    setRoleItems(mapToLabelValueArray(roles?.items, "name", "id"));
  }, [roles]);

  console.log(roles)

  return (
    <CustomModal
      id="add_new_employee"
      content={
        <Container>
          <ModalTitle>Create User Account</ModalTitle>
          <FlexContainer>
            <TextInput
              placeholder={"Your name first name"}
              label={"First Name"}
              name={"employeeFirstName"}
              value={employeeFirstName}
              handleChange={handleChange}
            />
            <TextInput
              placeholder={"Your name last name"}
              label={"Last Name"}
              name={"employeeLastName"}
              value={employeeLastName}
              handleChange={handleChange}
            />
          </FlexContainer>
          <TextInput
            placeholder={"example@gmail.com"}
            label={"Email"}
            name={"employeeEmail"}
            value={employeeEmail}
            handleChange={handleChange}
          />
          <TextInput
            placeholder={"Enter strong password"}
            label={"Password"}
            type={"password"}
            value={employeePassword}
            name={"employeePassword"}
            handleChange={handleChange}
          />
          <SelectContainer>
            <SelectOptions
              placeholder={"Choose User Role"}
              options={roleItems}
              name={"employeeRole"}
              value={employeeRole}
              handleSelect={handleChange}
            />
          </SelectContainer>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
              loading={addingEmployees}
              disabled={
                !employeeFirstName ||
                !employeeLastName ||
                !employeeEmail ||
                !employeePassword ||
                !employeeRole ||
                addingEmployees
              }
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default CreateUserModal;
