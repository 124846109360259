import { styled } from "styled-components";
import { media } from "../../../Screens";

export const HeaderContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1); */
  z-index: 100;
  gap: 20px;
  position: sticky;
  top: 0;
  padding: 20px 0 10px 0;

  ${media.tablet} {
    flex-direction: row;
    align-items: center;
    padding: 30px 0 20px 0;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  ${media.tablet} {
    width: auto;

    .menu {
      display: none;
    }
  }
`;
export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;

  ${media.tablet} {
    align-items: center;
  }
`;

export const Time = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.44px;
  letter-spacing: 0.14em;
  color: ${({ theme }) => theme.black};
`;

export const AvatarContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.grey4};
  align-items: flex-end;
`;

export const UserName = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
`;

export const Role = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border: 1px solid #ffffff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    color: ${({ theme }) => theme.green2};
    bottom: -5px;
    right: -5px;
    z-index: 20;
  }
`;
