import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gettingSuppliers: false,
  editingSuppliers: false,
  deletingSuppliers: false,
  addingSuppliers: false,
  suppliers: null,
  supplierspageSize: 20,
  suppliersPage: 1,

  // Supplier details
  supplierName: "",
  supplierEmail: "",
  supplierPhone: "",
  supplierAddress: "",
  supplierCompanyName: "",

  // Editing supplier details
  supplierToEdit: null,
  supplierId: null,
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    getSupplierStateUpdate: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),

    // Reducer to update gettingSuppliers
    setGettingSuppliers: (state, action) => {
      state.gettingSuppliers = action.payload;
    },

    // Reducer to update editingSuppliers
    setEditingSuppliers: (state, action) => {
      state.editingSuppliers = action.payload;
    },

    // Reducer to update deletingSuppliers
    setDeletingSuppliers: (state, action) => {
      state.deletingSuppliers = action.payload;
    },

    // Reducer to update addingSuppliers
    setAddingSuppliers: (state, action) => {
      state.addingSuppliers = action.payload;
    },

    // Reducer to update suppliers
    setSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },

    // Reducer to update supplierspageSize
    setSupplierspageSize: (state, action) => {
      state.supplierspageSize = action.payload;
    },

    // Reducer to update suppliersPage
    setSuppliersPage: (state, action) => {
      state.suppliersPage = action.payload;
    },

    // Reducer to update supplierToEdit
    setSupplierToEdit: (state, action) => {
      state.supplierToEdit = action.payload;
      state.supplierName = action.payload.name;
      state.supplierEmail = action.payload.email;
      state.supplierPhone = action.payload.phoneNumber;
      state.supplierAddress = action.payload.address;
      state.supplierCompanyName = action.payload.companyName;
    },

    clearSupplier: (state) => {
      // Supplier details
      state.supplierName = "";
      state.supplierEmail = "";
      state.supplierPhone = "";
      state.supplierAddress = "";
      state.supplierCompanyName = "";

      // Editing supplier details
      state.supplierToEdit = null;
      state.supplierId = null;
    },
  },
});

export const {
  getSupplierStateUpdate,
  setGettingSuppliers,
  setEditingSuppliers,
  setDeletingSuppliers,
  setAddingSuppliers,
  setSuppliers,
  setSupplierspageSize,
  setSuppliersPage,
  setSupplierToEdit,
  clearSupplier,
} = supplierSlice.actions;

export const selectSupplier = (state) => state.supplier;

export default supplierSlice.reducer;
