import React from "react";
import { Route, Routes } from "react-router-dom";
// import Signup from "../Auth/signup";
import Login from "../Auth/login";
import ForgotPassword from "../Auth/forgot-password";
import PharmacySetup from "../Auth/signup/PharmacySetup";
import AdminAccount from "../Auth/signup/AdminAccount";
import ProductActivation from "../Auth/signup/ProductActivation";
import SplashScreen from "../Auth/SplashScreen";
import { useSelector } from "react-redux";
import { selectNav } from "../../Redux/Slices/navSlice";

const AuthPages = () => {
  const { progress } = useSelector(selectNav);

  return (
    <Routes>
      <Route
        path="/"
        element={progress === 100 ? <Login /> : <SplashScreen />}
      />
      <Route path="/sign-up" element={<PharmacySetup />} />
      <Route path="/sign-up/admin-account" element={<AdminAccount />} />
      <Route path="/sign-up/activate-product" element={<ProductActivation />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
};

export default AuthPages;
