import React from "react";
import { PageTitle } from "../Online Order/styled";
import OrdersSummary from "./OrdersSummary";
import OrdersTab from "../../../components/Tabs/ordersTab";
import { ordersTabList } from "./utils";
import { HeaderOptions, OrdersSearchSection } from "./style";
import SearchInput from "../../../components/Inputs/SearchInput";

const Orders = () => {
  return (
    <>
      <PageTitle>Orders</PageTitle>
      <OrdersSummary />
      <OrdersTab tabList={ordersTabList} />
      <OrdersSearchSection>
        <SearchInput placeholder={"Search Order"} />
        <HeaderOptions>
          <img src="/assets/images/Generals/grid.svg" alt="grid" />
          <span className="divider"></span>
          <img src="/assets/images/Generals/export.svg" alt="export" />
        </HeaderOptions>
      </OrdersSearchSection>
    </>
  );
};

export default Orders;
