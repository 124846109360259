import React from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TextInput from "../../../../components/Inputs/TextInput";
import styled, { useTheme } from "styled-components";
import FilledButton from "../../../../components/Buttons/FilledButton";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../../Redux/Slices/navSlice";
import SelectOptions from "../../../../components/Inputs/SelectOptions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const ModalTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  text-transform: uppercase;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const EditExpiringProductModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(setCurrentModal("edit_expiring_product_success"));
  };

  return (
    <CustomModal
      id="edit_expiring_product"
      content={
        <Container>
          <ModalTitle>Edit Product</ModalTitle>
          <TextInput
            label={"Invoice Number"}
            placeholder={"Enter Invoice Number"}
          />
          <TextInput label={"Product Batch"} placeholder={"Product batch"} />
          <FlexContainer>
            <TextInput label={"Cost price"} placeholder={"Price"} />
            <TextInput label={"Selling Price"} placeholder={"Price"} />
            <TextInput label={"QTY/Carton"} placeholder={"Price"} />
          </FlexContainer>
          <FlexContainer>
            <SelectOptions placeholder={"Choose"} label={"Supplier"} />
            <SelectOptions placeholder={"Choose"} label={"Category"} />
          </FlexContainer>
          <FlexContainer>
            <TextInput label={"MFT Date"} type={"date"} />
            <TextInput label={"Expiring Date"} type={"date"} />
          </FlexContainer>
          <ButtonContainer>
            <FilledButton
              bgColor={theme.red}
              btnText={"Close"}
              handleClick={() => dispatch(setCurrentModal(""))}
              textColor={theme.white}
            />
            <FilledButton
              bgColor={theme.purple}
              btnText={"Submit"}
              handleClick={handleCreate}
              textColor={theme.white}
            />
          </ButtonContainer>
        </Container>
      }
    />
  );
};

export default EditExpiringProductModal;
