import styled from "styled-components";
import { media } from "../../Screens";

export const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: none;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &::before {
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    content: "";
    border-radius: 816px;
    opacity: 1;
    background: radial-gradient(
      67.22% 50% at 50% 50%,
      rgba(51, 231, 255, 0.15) 0%,
      rgba(245, 228, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    ${media.tablet} {
      width: 816px;
      height: 816px;
    }
  }
  &::after {
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    content: "";
    border-radius: 816px;
    opacity: 0.9;
    background: radial-gradient(
      67.22% 50% at 50% 50%,
      rgba(255, 100, 51, 0.15) 0%,
      rgba(245, 228, 255, 0) 100%
    );
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;

    ${media.tablet} {
      width: 816px;
      height: 816px;
    }
  }
`;

export const MainBodyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background: none;
  padding: 0px 10px 20px 10px;
  overflow-y: auto;

  ${media.tablet} {
    width: 85%;
    padding: 0px 30px 30px 30px;
  }
  ${media.desktop} {
    width: 85%;
    padding: 0px 40px 30px 40px;
  }
`;
