import React from "react";
import {
  LeftContent,
  NewOrdersContainer,
  NewOrdersList,
  NewOrdersTitle,
  NewOrdersContent,
  RightContent,
} from "./styles";
import NewSupportMessageCard from "../../../components/Cards/NewSupportMEssageCard";
import { useNavigate } from "react-router-dom";

const NewSupportMessages = () => {
  const navigate = useNavigate();

  return (
    <NewOrdersContainer>
      <NewOrdersTitle>New Support Messages</NewOrdersTitle>
      <NewOrdersContent>
        <LeftContent className="support">
          <NewOrdersList>
            <NewSupportMessageCard
              leftContent={
                <img
                  src="/assets/images/Notification/image4.svg"
                  alt="sample"
                />
              }
              name={"Esther Howard"}
              message={"You have new support message from Angel...."}
              handleOnClick={() =>
                navigate("/notification/detailed-support-message")
              }
            />
            <NewSupportMessageCard
              leftContent={
                <img
                  src="/assets/images/Notification/image4.svg"
                  alt="sample"
                />
              }
              name={"Esther Howard"}
              message={"You have new support message from Angel...."}
              handleOnClick={() =>
                navigate("/notification/detailed-support-message")
              }
            />
            <NewSupportMessageCard
              leftContent={
                <img
                  src="/assets/images/Notification/image4.svg"
                  alt="sample"
                />
              }
              name={"Esther Howard"}
              message={"You have new support message from Angel...."}
              handleOnClick={() =>
                navigate("/notification/detailed-support-message")
              }
            />
            <NewSupportMessageCard
              leftContent={
                <img
                  src="/assets/images/Notification/image4.svg"
                  alt="sample"
                />
              }
              name={"Esther Howard"}
              message={"You have new support message from Angel...."}
              handleOnClick={() =>
                navigate("/notification/detailed-support-message")
              }
            />
          </NewOrdersList>
        </LeftContent>
        <RightContent className="support">
          <img
            src="/assets/images/Notification/image2.svg"
            alt="NewOrders_banner"
          />
        </RightContent>
      </NewOrdersContent>
    </NewOrdersContainer>
  );
};

export default NewSupportMessages;
