import React from "react";
import AuthRouteContainer from "../../AuthRouteContainer";
import { AuthBannerTitle } from "../style";
import FormCard from "../../../components/Cards/FormCard";
import TextInput from "../../../components/Inputs/TextInput";
import FilledButton from "../../../components/Buttons/FilledButton";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../Redux/Slices/AuthSlice";
import { toast } from "react-toastify";
import { signUpUser } from "../../../Apis/Auth";

const ProductActivation = () => {
  const theme = useTheme();
  const {
    productActivationKey,
    companyName,
    companyPhoneNumber,
    companyEmail,
    companyLogo,
    pharmacistFirstName,
    pharmacistLastName,
    pharmacistPassword,
  } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!productActivationKey) {
      toast.error("Please product activation key is necessary to proceed");
    } else {
      signUpUser(
        companyName,
        companyEmail,
        companyPhoneNumber,
        pharmacistFirstName,
        pharmacistLastName,
        pharmacistPassword,
        companyLogo,
        productActivationKey
      );
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  return (
    <AuthRouteContainer
      leftContent={
        <>
          <img src="/assets/images/Auth/zamda_logo.svg" alt="logo" />
          <AuthBannerTitle>POS (Point of Sales)</AuthBannerTitle>
          <img
            src="/assets/images/Auth/auth_banner.svg"
            alt="logo"
            width="80%"
          />
        </>
      }
      rightContent={
        <FormCard title={"Product Activation"}>
          <span>Company Name: XYZ Pharmacy</span>
          <TextInput
            label={"Product Activation key"}
            placeholder={"Enter activation key"}
            type={"text"}
            value={productActivationKey}
            name={"productActivationKey"}
            required={true}
            handleChange={handleChange}
          />

          <FilledButton
            bgColor={theme.purple}
            btnText={"Activate Now"}
            handleClick={handleSubmit}
            textColor={theme.white}
            customStyle={{ width: "100%" }}
          />
        </FormCard>
      }
    />
  );
};

export default ProductActivation;
