import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Submit request details
  invoiceNumber: "",
  productBatch: "",
  purchaseDate: null,
  invoiceUpload: "",
  submittingRequest: false,

  // Cart
  medicineToBuy: null,

  // Medicines
  gettingMedicinesByCategory: false,
  medicinesByCategory: null,
  medicinesPage: 1,
  medicinepageSize: 20,

  // Medicine details
  medicineDetails: {
    name: "",
    description: "",
    netWeight: "",
    stock: 0,
    price: 0,
    count: 0,
    img: "",
  },

  // Market place
  verifyingMarket: false,
  marketVerified: false,

  // Order success
  successOrderDetails: null,

  // Request
  gettingPurchaseHistory: false,
  purchaseHistory: null,
  purchaseHistoryPage: 1,
  purchaseHistorypageSize: 20,
  cancellingRequest: false,
};

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    getMarketplaceStateUpdate: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },

    // Reducer to update submittingRequest
    setSubmittingRequest: (state, action) => {
      state.submittingRequest = action.payload;
    },

    // Reducer to update successOrderDetails
    setSuccessOrderDetails: (state, action) => {
      state.successOrderDetails = action.payload;
    },

    // Reducer to update gettingMedicinesByCategory
    setGettingMedicinesByCategory: (state, action) => {
      state.gettingMedicinesByCategory = action.payload;
    },

    // Reducer to update medicinesByCategory
    setMedicinesByCategory: (state, action) => {
      state.medicinesByCategory = action.payload;
    },

    // Reducer to update medicinesPage
    setMedicinesPage: (state, action) => {
      state.medicinesPage = action.payload;
    },

    // Reducer to update medicinepageSize
    setMedicinepageSize: (state, action) => {
      state.medicinepageSize = action.payload;
    },

    // Reducer to update verifyingMarket
    setVerifyingMarket: (state, action) => {
      state.verifyingMarket = action.payload;
    },

    // Reducer to update marketVerified
    setMarketVerified: (state, action) => {
      state.marketVerified = action.payload;
    },

    // Reducer to update gettingPurchaseHistory
    setGettingPurchaseHistory: (state, action) => {
      state.gettingPurchaseHistory = action.payload;
    },

    // Reducer to update purchaseHistory
    setPurchaseHistory: (state, action) => {
      state.purchaseHistory = action.payload;
    },

    // Reducer to update purchaseHistoryPage
    setPurchaseHistoryPage: (state, action) => {
      state.purchaseHistoryPage = action.payload;
    },

    // Reducer to update purchaseHistorypageSize
    setPurchaseHistorypageSize: (state, action) => {
      state.purchaseHistorypageSize = action.payload;
    },

    // Reducer to update cancellingRequest
    setCancellingRequest: (state, action) => {
      state.cancellingRequest = action.payload;
    },
  },
});

export const {
  getMarketplaceStateUpdate,
  setSubmittingRequest,
  setSuccessOrderDetails,
  setGettingMedicinesByCategory,
  setMedicinesByCategory,
  setMedicinesPage,
  setMedicinepageSize,
  setVerifyingMarket,
  setMarketVerified,
  setGettingPurchaseHistory,
  setPurchaseHistory,
  setPurchaseHistoryPage,
  setPurchaseHistorypageSize,
  setCancellingRequest,
} = marketplaceSlice.actions;

export const selectMarketplace = (state) => state.marketplace;

export default marketplaceSlice.reducer;
