import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { media } from "../../Screens";

const CardContainer = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(prop) => prop.cardBgColor};
  position: relative;

  ${media.phone}{
    padding: 20px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  gap: 5px;
  /* align-items: center; */
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: ${(prop) => prop.iconBgColor};
  padding: 5px;

  ${media.phone} {
    width: 34px;
    height: 34px;
    padding: 0;
  }
`;

const SummaryPropValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  color: ${(prop) => prop.textColor};

  a {
    color: ${(prop) => prop.textColor};
  }
`;

const Prop = styled.p`
  font-size: 10px;
  font-weight: 300;
  line-height: 11.74px;
  text-align: left;

  ${media.phone} {
    font-size: 14px;
  }
`;

const Value = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 5.74px;
  text-align: left;
`;

const ViewLink = styled(Link)`
  font-size: 12px;
  font-weight: 300;
  line-height: 11.74px;
  text-align: left;
  text-decoration: none;
  color: ${(prop) => prop.textColor};

  &:hover {
    color: ${(prop) => prop.textColor};
    text-decoration: none;
  }
`;

const ViewButton = styled.button`
  font-size: 12px;
  font-weight: 300;
  line-height: 11.74px;
  text-align: left;
  text-decoration: none;
  color: ${(prop) => prop.textColor};
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    color: ${(prop) => prop.textColor};
    text-decoration: none;
  }
`;

const NewItemsSignal = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #bf4130;
  position: absolute;
  top: -6px;
  left: 0;
`;

const ColoredBgCard = ({
  cardBgColor,
  iconBgColor,
  icon,
  prop,
  value,
  link,
  textColor,
  handleViewClick,
  handleOtherClicks,
  otherText,
  newItemsSignal,
}) => {
  return (
    <CardContainer cardBgColor={cardBgColor}>
      <LeftContent>
        <IconContainer iconBgColor={iconBgColor}>{icon}</IconContainer>
        <SummaryPropValueContainer textColor={textColor}>
          <Prop>{prop}</Prop>
          <Value>{value}</Value>
        </SummaryPropValueContainer>
      </LeftContent>
      {link && (
        <ViewLink to={link} textColor={textColor}>
          view
        </ViewLink>
      )}
      {handleViewClick && (
        <ViewButton textColor={textColor} onClick={handleViewClick}>
          view
        </ViewButton>
      )}
      {handleOtherClicks && (
        <ViewButton textColor={textColor} onClick={handleOtherClicks}>
          {otherText}
        </ViewButton>
      )}
      {newItemsSignal && <NewItemsSignal />}
    </CardContainer>
  );
};

export default ColoredBgCard;
