import React from "react";
import EmployeeManagementTable from "./EmployeeManagementTable";
import CreateUserModal from "./modals/CreateUserModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CreateRoleModal from "./modals/CreateRoleModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import EditRoleMessage from "./modals/EditRoleModal";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";
import { useDispatch } from "react-redux";

const EmployeeManagement = () => {
  const dispatch = useDispatch();

  return (
    <>
      <EmployeeManagementTable />
      <CreateUserModal />
      <CreateRoleModal />
      <EditRoleMessage />
      <SuccessMessageModal message="Done" id={"edit_user_success"} />
      <SuccessMessageModal message="Done" id={"create_user_success"} />
      <SuccessMessageModal message="Done" id={"create_new_role_success"} />
      <ConfirmModal
        message="By deleting this user all content will be deleted confirm you want to delete this user"
        confirmOkText={"Delete"}
        id={"delete_user"}
        handleOkClick={() => dispatch(setCurrentModal("delete_user_success"))}
      />
      <CanceledMessageModal
        id={"delete_user_success"}
        message={"Deleted Successfully"}
      />
    </>
  );
};

export default EmployeeManagement;
