import styled from "styled-components";
import { media } from "../../../Screens";

export const UserProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ProfileTitle = styled.div`
  width: 100%;
  height: 77px;
  box-shadow: 4px 4px 20px 0px #0000000d;
  background-color: ${({ theme }) => theme.white};
  font-size: 27.6px;
  font-weight: 300;
  line-height: 32.4px;
  text-align: left;
  gap: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.phone} {
    flex-direction: row;
  }
`;

export const LeftDetails = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.09px;
  letter-spacing: 0.25px;
`;

export const LeftTopDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  height: 330px;
  background: ${({ theme }) => theme.white};
  box-shadow: 4px 4px 20px 0px #0000000d;
  gap: 20px;

  .top {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */

    .avatar {
      width: 94px;
      height: 94px;
    }

    .name {
      font-size: 16px;
      font-weight: 700;
      /* line-height: 19.5px; */
      color: ${({ theme }) => theme.purple};
    }
  }
`;

export const LeftBottomDetails = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 30px;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.white};
  box-shadow: 4px 4px 20px 0px #0000000d;
  gap: 15px;

  .auth_details {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    color: ${({ theme }) => theme.purple};
  }

  ${media.phone} {
    height: 167px;
  }
`;

export const RightDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: 100%;
  min-height: 503px;
  background: ${({ theme }) => theme.white};
  box-shadow: 4px 4px 20px 0px #0000000d;
  flex: 0.3;
`;

export const ProfileFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 460px;
  padding: 16px;
  gap: 30px;
  border-radius: 4px;
  border: 1px;
  border: 1px solid #0000000d;
  background: ${({ theme }) => theme.pink};

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    color: ${({ theme }) => theme.purple};
  }
`;
