import React from "react";
import {
  LeftContent,
  NewOrdersContainer,
  NewOrdersTitle,
  NewOrdersContent,
  RightContent,
} from "./styles";
import NewSupportMessageCard from "../../../components/Cards/NewSupportMEssageCard";

const DetailedNewSupportNotification = () => {
  return (
    <NewOrdersContainer>
      <NewOrdersTitle>Support Message</NewOrdersTitle>
      <NewOrdersContent>
        <LeftContent className="support">
          <NewSupportMessageCard
            leftContent={
              <img src="/assets/images/Notification/image4.svg" alt="sample" />
            }
            name={"Esther Howard"}
            message={
              "Hi, my name is John Deo, I just placed and order for some supplements, I want it delivered . here is my invoice number #123978, How soon can it be delivered? I need it as soon as possible. 07067652431"
            }
          />
        </LeftContent>
        <RightContent className="support">
          <img
            src="/assets/images/Notification/image2.svg"
            alt="NewOrders_banner"
          />
        </RightContent>
      </NewOrdersContent>
    </NewOrdersContainer>
  );
};

export default DetailedNewSupportNotification;
