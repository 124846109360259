import React from "react";
import PrescriptionManagementTable from "./PrescriptionManagementTable";
import UploadPrescriptionModal from "./modal/uploadPrescriptionModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";
import ViewUploadPrescriptionMOdal from "./modal/ViewUploadedPrescriptionModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";

const PrescriptionManagement = () => {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(setCurrentModal("delete_uploaded_prescription_success"));
  };

  return (
    <>
      <PrescriptionManagementTable />
      <ViewUploadPrescriptionMOdal />
      <UploadPrescriptionModal />
      <SuccessMessageModal
        id={"save_uploaded_prescription_success"}
        message={"Done"}
      />
      <CanceledMessageModal
        id={"delete_uploaded_prescription_success"}
        message={"Deleted Successfully"}
      />
      <ConfirmModal
        id={"confirm_delete_uploaded_prescription"}
        confirmOkText={"Delete"}
        message={"confirm you want to delete this upload prescription."}
        handleOkClick={handleConfirm}
      />
    </>
  );
};

export default PrescriptionManagement;
