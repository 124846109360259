import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Label = styled.label`
  font-size: 11.54px;
  font-weight: 500;
  line-height: 15.67px;
  color: ${({ theme }) => theme.white};
`;

const TogglerContainer = styled.div`
  width: 130px;
  height: 40px;
  border-radius: 10px;
  background: ${(props) => (props.enabled ? "#04D3BC" : "#707070")};
  position: relative;

  .slide {
    width: 65px;
    height: 40px;
    border-radius: 10px;
    background: #fff;
    position: absolute;
    top: 0;
    left: ${(props) => (props.enabled ? "65px" : "0")};
  }
`;

const CustomToggle = ({ label }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Container>
      <Label>{label}</Label>
      <TogglerContainer enabled={toggle} onClick={() => setToggle(!toggle)}>
        <span className="slide"></span>
      </TogglerContainer>
    </Container>
  );
};

export default CustomToggle;
