import styled from "styled-components";
import { media } from "../../../Screens";

export const OnlineOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 20px 0px #0000000d;
  padding: 15px;
  border-radius: 8px;
`;

export const PageTitle = styled.div`
  font-size: 18.6px;
  font-weight: 300;
  line-height: 32.4px;
  text-align: left;
  color: ${({ theme }) => theme.black};
  margin-bottom: 20px;

  ${media.phone} {
    font-size: 27.6px;
  }
`;

export const OrderSummaryCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 15px;
  margin-bottom: 20px;

  ${media.phone} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${media.desktop} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    cursor: pointer;
  }
`;
