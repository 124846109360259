import React from "react";
import { MarketPlaceHeaderButtons } from "./style";
import RoundedButton from "../../../components/Buttons/RoundedButton";
import { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../Redux/Slices/navSlice";

const MarketPlaceHeader = ({ setActionType }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <MarketPlaceHeaderButtons>
      <RoundedButton
        btnText={"Buy"}
        bgColor={theme.green2}
        textColor={theme.white}
        handleClick={() => setActionType("buy")}
      />
      <RoundedButton
        btnText={"Sell"}
        bgColor={theme.red}
        textColor={theme.white}
        handleClick={() => setActionType("sell")}
      />
      <RoundedButton
        btnText={"Submit Request"}
        bgColor={theme.blue}
        textColor={theme.white}
        handleClick={() => dispatch(setCurrentModal("submit_request"))}
      />
    </MarketPlaceHeaderButtons>
  );
};

export default MarketPlaceHeader;
