import moment from "moment";
import {
  AvatarContainer,
  HeaderContainer,
  LeftContent,
  Profile,
  ProfileImage,
  RightContent,
  Role,
  Time,
  UserName,
} from "./style";
import { useEffect, useState } from "react";
import SalesSearchBar from "../../../pages/Private/Sales/SalesSearchBar";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../Redux/Slices/navSlice";

const Header = () => {
  const [timer, setTimer] = useState(moment().format("LTS"));
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setInterval(() => {
      setTimer(moment().format("LTS"));
    }, 1000);
  }, []);

  return (
    <HeaderContainer>
      <LeftContent>
        <Link to="/">
          <img src="/assets/images/zamda_logo.svg" alt="zamda_logo" />
        </Link>
        {pathname === "/sales" && <SalesSearchBar />}
        <img
          src="/assets/images/NavIcons/hamburger.svg"
          alt="menu"
          className="menu"
          onClick={() => dispatch(toggleSidebar())}
        />
      </LeftContent>
      <RightContent>
        <Time>{timer}</Time>
        <Link to="/notification">
          <img src="/assets/images/notification.svg" alt="notification" />
        </Link>
        <AvatarContainer>
          <Profile>
            <UserName>Prince Dennis</UserName>
            <Role>Admin</Role>
          </Profile>
        </AvatarContainer>
        <Link to="/profile">
          <ProfileImage src="/assets/images/avatar.svg" alt="avatar_profile" />
        </Link>
      </RightContent>
    </HeaderContainer>
  );
};

export default Header;
