import React from "react";
import styled from "styled-components";
import CustomToggle from "../../../components/Inputs/CustomToggle";

const Container = styled.div`
  width: 284px;
  height: 683px;
  border-radius: 10px;
  background: ${({ theme }) => theme.blue};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  color: ${({ theme }) => theme.white};
`;

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ManageNotification = ({ setShowSetting }) => {
  return (
    <Container>
      <HeaderContainer>
        <Title>Manage Notifications</Title>
        <img
          src="/assets/images/Generals/white_cancel.svg"
          alt=""
          onClick={() => setShowSetting(false)}
        />
      </HeaderContainer>
      <Settings>
        <CustomToggle label={"Expiring"} />
        <CustomToggle label={"Expired"} />
        <CustomToggle label={"New Order"} />
        <CustomToggle label={"Completed Order"} />
        <CustomToggle label={"Support"} />
        <CustomToggle label={"Out Of Stock"} />
      </Settings>
    </Container>
  );
};

export default ManageNotification;
