import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalId: "",
  showSidebar: false,
  closeSidebar: false,
  progress: 0,
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setCurrentModal: (state, action) => {
      state.modalId = action.payload;
    },
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    setLoadingProgress: (state, action) => {
      state.progress = action.payload;
    },
    CloseSidebarOnSelect: (state) => {
      state.showSidebar = false;
    },
  },
});

export const {
  setCurrentModal,
  toggleSidebar,
  CloseSidebarOnSelect,
  setLoadingProgress,
} = navSlice.actions;

export const selectNav = (state) => state.nav;

export default navSlice.reducer;
