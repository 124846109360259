import { styled } from "styled-components";
import { Nav } from "rsuite";
import { media } from "../../../Screens";

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  left: ${(props) => (props.showSidebar ? `0` : `-100%`)};
  width: ${(props) => (props.showSidebar ? `70%` : `0`)};
  background: none;
  padding: 20px 0;
  align-items: flex-start;
  position: absolute;
  top: 0;
  background-color: ${({theme}) => theme.white};
  backdrop-filter: blur(25px);
  transition: width 0.5s ease-in-out;
  padding-left: 20px;
  z-index: 101;
  box-shadow: 9px 17px 8.8px 0px #0000000a;

  ${media.tablet} {
    width: ${(props) => (props.showSidebar ? `30%` : `0`)};
    left: 0;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.2);
  }

  ${media.tablet} {
    width: 15%;
    position: relative;
    background: transparent;
    padding-left: 0;
  }

  .rs-dropdown .rs-dropdown-toggle {
    padding-left: 0;
  }

  .rs-sidenav-collapse-in .rs-dropdown-item,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    padding-left: 0;
  }

  .rs-sidenav-nav .rs-sidenav-item,
  .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-sidenav-nav .rs-dropdown-item,
  .rs-sidenav-nav .rs-dropdown-item-toggle .rs-sidenav {
    padding-left: 0;
    background-color: transparent;
    color: ${({theme}) => theme.purple};
    text-decoration: none;
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
  }

  .rs-sidenav-default.rs-sidenav-collapse-in
    .rs-sidenav-item.rs-sidenav-item-active {
    background-color: #f2f4f8;
    border-radius: 8px;
    /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1); */
    color: ${({theme}) => theme.purple};
    padding: 12px;

    a {
      color: ${({theme}) => theme.purple};
      text-decoration: none;
    }

    svg {
      color: ${({theme}) => theme.purple};
    }
  }

  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu-item-focus,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-active {
    background-color: var(--clr-white-100);
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    color: var(--clr-primary-900);
    padding: 12px;

    a {
      color: ${({theme}) => theme.purple};
      text-decoration: none;
    }

    svg {
      color: ${({theme}) => theme.purple};
    }
  }

  ${media.tablet} {
    padding-left: 25px;
  }
`;

export const NavItem = styled(Nav.Item)`
  font-size: 12px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;

  svg {
    font-size: 18px;
    font-weight: 400;
    color: ${({theme}) => theme.purple};
  }

  a {
    color: ${({theme}) => theme.purple};
    text-transform: capitalize;
    text-decoration: none;
  }
`;

export const NavMenu = styled(Nav.Menu)`
  background: none;
  padding-left: none;
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 0px;
  gap: 22px;

  span {
    font-size: var(--fs-500);
    font-weight: var(fw-regular);
    line-height: normal;
    color: var(--clr-black);
  }

  svg {
    font-size: var(--fs-700);
    color: var(--clr-black);
  }
`;

export const NavContainer = styled(Nav)`
  display: flex;
  flex-direction: column;
  /* gap: 30px; */
`;
