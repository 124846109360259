import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import moment from "moment";

export const columns = [
  { label: "Purchase Date", field: "purchase_date" },
  { label: "Admin Name", field: "admin_name" },
  { label: "Pharmacist", field: "pharmacist" },
  { label: "ID", field: "order_id" },
  { label: "Amount (₦)", field: "amount" },
];

const PurchaseHistoryTable = () => {
  const rows = [
    {
      order_id: "453298652",
      admin_name: "Ekwealor hillary",
      pharmacist: "New Heaven",
      amount: "10000",
      purchase_date: moment().format("Do MMM YYYY"),
    },
    {
      order_id: "453298652",
      admin_name: "Ekwealor hillary",
      pharmacist: "New Heaven",
      amount: "10000",
      purchase_date: moment().format("Do MMM YYYY"),
    },
    {
      order_id: "453298652",
      admin_name: "Ekwealor hillary",
      pharmacist: "New Heaven",
      amount: "10000",
      purchase_date: moment().format("Do MMM YYYY"),
    },
  ];

  const data = {
    columns,
    rows,
  };

  return (
    <CustomTable
      data={data}
      noRecord={false}
      title={"Purchase History"}
      showHeader={true}
      showPagination={true}
    />
  );
};

export default PurchaseHistoryTable;
