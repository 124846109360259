import React, { useState } from "react";
import MarketPlaceHeader from "./MarketPlaceHeader";
import {
  MarketPlaceMedicinesContainer,
  MarketPlaceTitle,
  TabsContainer,
} from "./style";
import MedicinesTab from "../../../components/Tabs/MedicinesTab";
import { medicineTabList, medicinesList } from "../Sales/utils";
import MedicineCard from "../../../components/Cards/MedicineCard";
import CustomPagination from "../../../components/Table/CustomPagination";
import SubmitRequestModal from "./modals/SubmitRequestModal";
import SuccessMessageModal from "../../../components/Modals/SuccesMessageModal";
import { useNavigate } from "react-router-dom";
import BuyOrderPlacedModal from "./modals/BuyOrderPlaced";
import CanceledMessageModal from "../../../components/Modals/CancelledMessage";

const MarketPlace = () => {
  const [actionType, setActionType] = useState("buy");
  const navigate = useNavigate();

  return (
    <>
      <MarketPlaceHeader setActionType={setActionType} />
      <MarketPlaceTitle>Medicines</MarketPlaceTitle>
      <TabsContainer>
        <MedicinesTab tabList={medicineTabList} />
      </TabsContainer>
      <MarketPlaceMedicinesContainer>
        {medicinesList?.map((item) => (
          <>
            {actionType === "buy" && (
              <MedicineCard
                price={item?.price}
                netWeight={item?.netWeight}
                stock={item?.stock}
                count={item?.count}
                image={item?.image}
                drugName={item?.drugName}
                drugDesc={item?.drugDesc}
                handleBuy={() => {
                  navigate("/marketplace/buy");
                }}
              />
            )}
            {actionType === "sell" && (
              <MedicineCard
                price={item?.price}
                netWeight={item?.netWeight}
                stock={item?.stock}
                count={item?.count}
                image={item?.image}
                drugName={item?.drugName}
                drugDesc={item?.drugDesc}
                handleSell={() => {}}
              />
            )}
          </>
        ))}
      </MarketPlaceMedicinesContainer>
      <CustomPagination />
      <SubmitRequestModal />
      <SuccessMessageModal
        id={"submit_request_success"}
        message={"Purchase request submitted successfully"}
      />
      <SuccessMessageModal id={"buy_thank_you_message"} message={"Thank You"} />
      <BuyOrderPlacedModal />
      <SuccessMessageModal id={"confirm_receipt_success"} message={"Thanks"} />
      <CanceledMessageModal
        id={"submit_request_cancelled"}
        message={"Purchase request cancelled successfully"}
      />
    </>
  );
};

export default MarketPlace;
