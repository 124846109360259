import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // login
  loginEmail: "",
  loginPassword: "",
  loggingInUser: false,
  loggedInUser: null,

  // reset password
  resetPasswordEmail: "",
  newPassword: "",
  confirmNewPassword: "",
  otp: "",
  resettingPassword: false,
  sendingOtp: false,

  // signup
  companyName: "",
  companyPhoneNumber: "",
  companyEmail: "",
  companyLocation: "",
  companyLogo: null,
  pharmacistFirstName: "",
  pharmacistLastName: "",
  pharmacistFullName: "",
  pharmacistPhoneNumber: "",
  pharmacistEmail: "",
  pharmacistUserName: "",
  pharmacistPassword: "",
  pharmacistConfirmPassword: "",
  productActivationKey: "",
  signingUp: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggingInUser: (state, action) => {
      state.loggingInUser = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    restoreUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setSendingOtp: (state, action) => {
      state.sendingOtp = action.payload;
    },
    setResettingPassword: (state, action) => {
      state.resettingPassword = action.payload;
    },
    setSigningUp: (state, action) => {
      state.signingUp = action.payload;
    },
    getAuthFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  getAuthFormDetails,
  setLoggedInUser,
  setLoggingInUser,
  setResettingPassword,
  setSendingOtp,
  setSigningUp,
  restoreUser,
} = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
