import React from "react";

const BankTransferPayment = () => {
  return (
    <div>
      <h4>Bank Transfer</h4>
      <p>
        Please use the following bank account details to complete your payment:
      </p>
      <ul>
        <li>Bank Name: Example Bank</li>
        <li>Account Number: 1234567890</li>
        <li>Account Name: ABC Limited</li>
      </ul>
      <p>
        Once the transfer is complete, please send the transaction receipt to
        support@example.com.
      </p>
    </div>
  );
};

export default BankTransferPayment;
